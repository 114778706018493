import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { GetAnalysisParams } from '@app/data/http/quote-params.dto';
import { FullAnalysisResponse, mapFullAnalysis } from '@app/data/http/quote.dto';
import { handleUnauthorized } from '@app/utils/http-utils';

export async function getTechnicalAnalysis(params?: GetAnalysisParams): Promise<FullAnalysisResponse> {
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    language: params.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.id}`;

  try {
    const { data, status } = await axios.get(url, { headers: defaultHeaders });
    if (status === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    handleUnauthorized(error);
    return null;
  }
}

export function useTechnicalAnalysis(params: GetAnalysisParams, onSuccess?, onError?) {
  return useQuery(['getTechnicalAnalysis', params], () => getTechnicalAnalysis(params), {
    onSuccess,
    onError,
    select: data => {
      return mapFullAnalysis(data);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
}
