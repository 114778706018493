import React, { useRef, useState } from 'react';
import { AnalysisResume, ProductDimensionOrder } from '@app/models/analysis.model';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Label } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';
import { ReferenceField } from '../../../../create-order-modal.style';
import { IsaReferencePopover } from '../isa-reference-popover/isa-reference-popover.component';

interface IsaReferenceFieldProps {
  item: AnalysisResume;
  productDimensionOrder: ProductDimensionOrder;
}

export const IsaReferenceField: React.FC<IsaReferenceFieldProps> = ({ item, productDimensionOrder }) => {
  const [showPopover, setShowpopover] = useState(false);
  const ref = useRef(null);

  return (
    <FlexColumn
      hAlign='center'
      vAlign='center'
      fullWidth
      onMouseOver={() => setShowpopover(true)}
      onMouseOut={() => setShowpopover(false)}
    >
      <FlexRow hAlign='center' vAlign='center'>
        <ReferenceField ref={ref}>
          <Label weight={FontWeight.Normal} fullWidth size={FontSize.Small} mr={Spacing.XSmall}>
            {productDimensionOrder.referenceIsa}
          </Label>
          <FaIcon.FlashWarning size='1x' color={Color.GrayXDark} />
        </ReferenceField>
        {showPopover && <IsaReferencePopover item={item} />}
      </FlexRow>
    </FlexColumn>
  );
};
