import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { FullAnalysisData } from '@app/models/quote.model';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { validateTokens } from '@app/modules/components/notification-center/store/thunk/validate-and-get-notification.thunk';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { FlashMessageTargetName } from '@app/providers/flash-message.store';
import { Button } from '@atomic/atm.button';
import { CheckboxField } from '@atomic/atm.checkbox';
import { H2 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Form, FormData, FormField } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { TechnicalAnalysisContext } from '../technical-analysis.context';

interface DownloadPdfModalProps {
  analysis: FullAnalysisData;
  loading: boolean;
  opened: boolean;
  onClose: () => void;
  onSubmit: (data: string[]) => void;
}

export interface PlantFormData {
  pindamonhangaba: string[];
  mogidascruzes: string[];
  charqueadas: string[];
  gerdauresumoc: string[];
  gerdauresumom: string[];
  gerdauresumop: string[];
}

export const DownloadPdfModal: React.FC<DownloadPdfModalProps> = props => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { setHasTokenChecked } = React.useContext(TechnicalAnalysisContext);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = TechnicalAnalysisStrings[userInfo.language].components.downloadPdf;
  const [selectedPlant, setSelectedPlant] = React.useState([]);
  const handleClose = () => {
    if (!props.loading) {
      props.onClose();
      setSelectedPlant([]);
    }
  };

  const handleSubmit = (form: FormData<PlantFormData>) => {
    dispatch(validateTokens()).then(() => {
      const data = form.data;
      if (
        !data.charqueadas &&
        !data.mogidascruzes &&
        !data.pindamonhangaba &&
        !data.gerdauresumoc &&
        !data.gerdauresumom &&
        !data.gerdauresumop
      ) {
        show('alert', strings.errors.selectAtLeastOne);
      } else {
        props.onSubmit(selectedPlant);
      }
      setSelectedPlant([]);
      setHasTokenChecked(true);
    });
  };

  const checkDownloadPermission = (internalStatus: string, isReviewed: boolean, isRequested: boolean) => {
    const statusNotRequestedNotReviewed = [
      'INTERNAL_QUERY',
      'INTERNAL_QUERY_SELLER',
      'VALIDATION_RTC',
      'PENDING',
      'CANCELED',
      'WAITING_PRICE',
      'AVAILABLE_PRICE',
    ];
    const statusRequested = [
      'UNDER_ANALYSIS_EPP',
      'UNDER_ANALYSIS_PED',
      'UNDER_ANALYSIS_RTC',
      'UNDER_ANALYSIS_GQ',
      'UNDER_ANALYSIS',
      'DATA_VALIDATION_RTC',
      'REJECTED',
    ];
    const statusReviewed = [
      'WAITING_CLIENT_REVIEW',
      'WAITING_CLIENT_REVIEW_CLIENT',
      'IN_VALIDATION_EPP',
      'IN_VALIDATION_PED',
      'IN_VALIDATION_GQ',
      'IN_VALIDATION',
      'FINALIZED',
      'FINALIZED_WITH_DEROGATION',
      'NO_CUSTOMER_RESPONSE',
    ];

    if (statusNotRequestedNotReviewed.includes(internalStatus)) {
      return true;
    } else if (statusRequested.includes(internalStatus) && isRequested) {
      return true;
    } else if (statusReviewed.includes(internalStatus)) {
      if (isReviewed) {
        return true;
      } else {
        return isRequested ? true : false;
      }
    } else {
      return false;
    }
  };

  const removeChars = (value: string) => {
    return value
      .replace(/\s/g, '')
      .replace('-', '')
      .replace('(', '')
      .replace(')', '');
  };

  const handleSelectedPlant = (plant, label, option) => {
    if (!option) {
      const filteredPlant = selectedPlant.filter(p => {
        if (label.includes('Resumo')) {
          return p !== 'resumoGerdau';
        } else {
          return p !== plant;
        }
      });
      setSelectedPlant(filteredPlant);
    } else {
      setSelectedPlant(prevValue => [...prevValue, label.includes('Gerdau - Resumo') ? 'resumoGerdau' : plant]);
    }
  };

  return (
    <Modal opened={props.opened} small onClose={handleClose}>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <Form onSubmit={handleSubmit}>
              <H2>{strings.title}</H2>

              {props.analysis &&
                props.analysis.plants.map(plant => (
                  <FormField key={plant.label} name={removeChars(plant.label).toLocaleLowerCase()}>
                    <CheckboxField
                      onValueChange={(_, checked) => handleSelectedPlant(plant.key, plant.label, checked)}
                      disabled={
                        !checkDownloadPermission(
                          props.analysis.internalStatus?.key,
                          plant.review?.isReviewed,
                          plant.review?.isRequested,
                        )
                      }
                      id={removeChars(plant.label).toLocaleLowerCase()}
                    >
                      {plant.label}
                    </CheckboxField>
                  </FormField>
                ))}

              <Hbox hAlign='flex-end'>
                <Hbox.Item noGrow>
                  <Button kind='link' disabled={props.loading} onClick={props.onClose}>
                    {strings.cancel}
                  </Button>
                </Hbox.Item>
                <Hbox.Separator />
                <Hbox.Item noGrow>
                  <Button type='submit' kind='primary' loading={props.loading}>
                    {strings.generatePdf}
                  </Button>
                </Hbox.Item>
              </Hbox>
              <VSeparator />
            </Form>
          </Col>
        </Row>
      </Grid>
    </Modal>
  );
};
