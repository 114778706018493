import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpointCrm, IsaHttpRequestBuilder } from '@app/data/http';
import { CrmNotesResponse, GetCrmParams, mapNotesData } from '@app/data/http/crm.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export async function getNotes(params?: GetCrmParams): Promise<CrmNotesResponse> {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    language: user.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };
  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpointCrm.CRM}/${params.id}${
    endpointCrm.NOTES
  }`;

  const { data, status } = await axios.get(url, { headers: defaultHeaders });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
}

export function useGetNotes(params: GetCrmParams, onError?) {
  return useQuery(['getNotes', params], () => getNotes(params), {
    onError,
    enabled: params.enabled,
    select: data => {
      return mapNotesData(data.data);
    },
    refetchOnWindowFocus: false,
  });
}
