import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import * as Scroll from 'react-scroll';
import { LogAnalytics } from '@app/core/analytics';
import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { EnhancedError } from '@app/core/hook/request.model';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { QuoteDataSource } from '@app/data/http';
import {
  EditAnalysisParams,
  EditConversationData,
  ExtraDimensionData,
  TableRowData,
} from '@app/data/http/quote-params.dto';
import { AppPath } from '@app/modules/app/route-constants';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { IsaLoading } from '@app/modules/components/isa-loading-component';
import { validateTokens } from '@app/modules/components/notification-center/store/thunk/validate-and-get-notification.thunk';
import { FlashMessageTargetName } from '@app/providers';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { Button } from '@atomic/atm.button';
import { ChatField } from '@atomic/atm.chatfield';
import { Dropzone } from '@atomic/atm.dropzone';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Frame } from '@atomic/atm.frame';
import { TagCheckboxField, TagCheckboxGroup } from '@atomic/atm.tag-checkbox';
import { TagRadioField } from '@atomic/atm.tag-radio';
import { BodyChat, BodySecondaryChat } from '@atomic/atm.typography/typography.component.style';
import { Hbox } from '@atomic/obj.box';
import { FixedFooter } from '@atomic/obj.fixed-footer';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { UploadNormLoading } from '../components/uploadNormLoading';
import { ChatMessage } from './chat-message.component';
import { ChatResult } from './chat-result.component';
import { InitialActions, QuestionTypes, useChatContext } from './chat.page.container';
import { ChatStrings } from './chat.string';
import { useChatCreate } from './hooks/useChatCreate';
import { useCreateAnalysis } from './hooks/useCreateAnalysis';
import { ModalAttributes } from './modal-atributtes.component';
import { CompositionContext, ModalComposition } from './modal-composition.component';
import { ModalDiameter } from './modal-diameter.component';
import { JominyContext, ModalJominy } from './modal-jominy.component';
import { MacroataqueData, ModalMacroataque } from './modal-macroataque.component';
import { MechanicalPropertiesData, ModalMechanicalProperties } from './modal-mechanical.component';
import { MicroinclusaoData, ModalMicroinclusao } from './modal-microinclusao.component';

const getInitials = (name: string) => {
  return name
    .split(' ')
    .map(n => n.substring(0, 1))
    .join('');
};

type ModalData = JominyContext | CompositionContext | MacroataqueData | MicroinclusaoData | MechanicalPropertiesData;

export const ChatPage: React.FC = () => {
  const { userInfo, azureToken, token } = useSelector((state: RootState) => state.auth);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const history = useHistory();
  const chatContext = useChatContext();
  const chafFnRef = React.useRef(null);

  const dispatch = useDispatch<AppThunkDispatch>();

  const [chatCreateV2, setChatCreateV2] = React.useState<boolean>(false);

  const strings = ChatStrings[userInfo.language];

  const { action, analysisId } = useParams<{ action: string; analysisId: string }>();

  const handleAnalysisCreationSuccess = (res: { id: number }) => {
    LogAnalytics.submit({
      tipo: 'CriarCotacao',
      time: chatContext.timeQuote,
      analysisId: res.id,
    });
    history.push(`${AppPath.QUOTE.ANALYSIS}/${res.id}`);
  };

  const handleAnalysisCreationFailure = (err: EnhancedError) => {
    LogAnalytics.error({
      tipo: 'ErroCriarCotacao',
      message: err.message,
    });
    show('alert', strings.alertTry);
  };

  React.useEffect(() => {
    if (action === InitialActions.Edit) {
      show('warning', strings.alertEdit);
    } else if (action === InitialActions.Duplicate) {
      chatContext.updateContext({ isDuplicated: true });
    }
  }, [action]);

  const editAnalysis = useLazyRequest<EditAnalysisParams, any>(
    QuoteDataSource.editAnalysis,
    handleAnalysisCreationSuccess,
    handleAnalysisCreationFailure,
  );

  const handleChatCreatedSucces = response => {
    setChatCreateV2(response.chatCreateV2);
  };

  useChatCreate(
    { language: userInfo.language, authorizationToken: azureToken, accessToken: token },
    handleChatCreatedSucces,
  );

  const { isFetching: loadingCreateAnalysis, refetch: createAnalysis } = useCreateAnalysis(
    {
      conversationId: chatContext.context.conversation_id,
      chatCreateV2,
      language: userInfo.language,
      authorizationToken: azureToken,
      accessToken: token,
    },
    handleAnalysisCreationSuccess,
    handleAnalysisCreationFailure,
  );

  const handleOption = (label: string) => (id: any) => {
    chatContext.setAnswer(id, label);
  };

  const handleMultiOptionSubmit = (options: FormData<{ values: string[] }>) => {
    if (Object.keys(options.error).length === 0) {
      chatContext.setAnswer(options.data.values.join(', '));
    } else {
      show('alert', strings.alertSelect);
    }
  };

  const handleFinishChat = () => {
    dispatch(validateTokens()).then(() => {
      if (action === InitialActions.Edit) {
        editAnalysis.performRequest({
          language: userInfo.language,
          analysisId,
          conversationId: chatContext.context.conversation_id,
          authorizationToken: azureToken,
          accessToken: token,
        });
      } else {
        createAnalysis();
      }
    });
  };

  React.useEffect(() => {
    chatContext.start(analysisId, action);
  }, []);

  React.useEffect(() => {
    Scroll.animateScroll.scrollToBottom({ containerId: 'container-id' });
    if (chatContext.questions) {
      const question = chatContext.questions[0];

      // set chat field focus if chat expects a textual answer
      if (question?.questionType === 'text') {
        chafFnRef.current();
      }
    }
  }, [chatContext.questions]);

  React.useEffect(() => {
    if (chatContext.forceRedirect) {
      handleFinishChat();
    }
  }, [chatContext.forceRedirect]);

  const handleUpload = (file: File[]) => {
    chatContext.upload(file);
  };

  const handleFocusFn = (fn: any) => {
    chafFnRef.current = fn;
  };

  const handleModalData = (data: ModalData, hasModifiedChemicalComposition?: boolean) => {
    chatContext.updateGlobalContext({ ...data, tableClosed: true, hasModifiedChemicalComposition });
  };

  const handleCreateTable = (data: TableRowData[] | any, key: string) => {
    chatContext.createTable(data, key);
  };

  const handleModalExtraDimensions = (data: ExtraDimensionData[], key: string) => {
    chatContext.createExtraDimension(data, key);
  };

  const handleRemoveContextAttribute = (key: string) => {
    chatContext.removeAttribute(key);
  };

  const handleCloseModal = () => {
    chatContext.updateGlobalContext({ openTableType: '', tableClosed: true });
  };

  const handleEditAttribute = (data: EditConversationData) => {
    dispatch(validateTokens()).then(() => {
      chatContext.editAttribute({
        attributeKey: data.attributeKey,
        value: data.value,
        type: data.type,
        unit: data.unit,
        authorizationToken: azureToken,
        accessToken: token,
      });
    });
  };

  const handleModalAttributeOption = (id: string, label: string, newAttribute: boolean) => {
    if (!newAttribute) {
      chatContext.setAnswer(id, label);
    } else {
      chatContext.setNewAttribute(id, label, chatContext.context.conversation_id);
    }
  };

  const openTableType = chatContext.context?.openTableType || '';
  const initialized = chatContext.attributes.length > 0 || !!chatContext.context.STEEL_NAME;
  const displayFileForm = chatContext.context?.displayFileForm;
  const forma = chatContext.context?.FORMA;
  const product = chatContext.context?.PRODUTO ? chatContext.context?.PRODUTO[0] : '';
  const mainDiameterSide = chatContext.context?.DIAMETER ? chatContext.context?.DIAMETER : chatContext.context?.SIDE;
  const mainThickness = chatContext.context?.THICKNESS;
  const mainWidth = chatContext.context?.WIDTH;
  const mainConsumo = chatContext.context?.CONSUMO;
  const mainComprimentoMin = chatContext.context?.COMPRIMENTOMIN;
  const mainComprimentoMax = chatContext.context?.COMPRIMENTOMAX;

  return (
    <LoadingState
      loading={loadingCreateAnalysis || editAnalysis.loading || chatContext.chatLoading || chatContext.uploadLoading}
      data
      enableActivityIndicator={false}
    >
      {!chatContext.uploadLoading ? (
        <LoadingState.CustomLoading>
          <IsaLoading message={chatContext.chatLoading ? strings.loading.initialize : strings.loading.finish} />
        </LoadingState.CustomLoading>
      ) : (
        <LoadingState.CustomLoading>
          <UploadNormLoading message={strings.loading.analyzing} />
        </LoadingState.CustomLoading>
      )}

      <Hbox>
        <Hbox.Item>
          <Scroll.Element
            style={{ height: 'calc(100vh - 160px)', overflow: 'scroll', padding: '16px' }}
            id='container-id'
          >
            {chatContext?.questions &&
              chatContext?.questions.length > 0 &&
              chatContext.questions.map((question, index) => (
                <React.Fragment key={index}>
                  {question && question.questionType !== QuestionTypes.Pause && (
                    <ChatMessage type='isa'>
                      <BodyChat
                        hasDescription={!!question?.description}
                        dangerouslySetInnerHTML={{ __html: question.question }}
                      />
                      {!!question?.description && (
                        <BodySecondaryChat dangerouslySetInnerHTML={{ __html: question.description }} />
                      )}
                    </ChatMessage>
                  )}
                  {index === chatContext.questions.length - 1 && displayFileForm && (
                    <Dropzone onDropAccepted={handleUpload} />
                  )}
                  {question && question?.answer && (
                    <ChatMessage type='user' initials={getInitials(userInfo.name)}>
                      <BodyChat>{question.answer}</BodyChat>
                    </ChatMessage>
                  )}
                  {question && !question.answer && question.questionType === QuestionTypes.Option && (
                    <>
                      <VSeparator />
                      {question?.options && question.options.length > 0 ? (
                        <Form.Field name='myGroup' initialValue={null}>
                          {question.options.map((option, ind) => (
                            <TagRadioField
                              key={option.label + ind}
                              id={option.value}
                              type={option.value?.toLowerCase()}
                              onClick={handleOption(option.label)}
                            >
                              {option.label}
                            </TagRadioField>
                          ))}
                        </Form.Field>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                  {question && !question.answer && question.questionType === QuestionTypes.MultiSelection && (
                    <Form onSubmit={handleMultiOptionSubmit}>
                      <VSeparator />
                      {question.options.length > 0 ? (
                        <Hbox vAlign='center' hAlign='center'>
                          <Hbox.Item vAlign='center'>
                            <TagCheckboxGroup>
                              <Form.Field
                                name='values'
                                initialValue={null}
                                hideErrorCaption
                                validators={[Validators.Required()]}
                              >
                                {question.options.map((option, ind) => (
                                  <TagCheckboxField key={option + ind} id={option.value}>
                                    {option.label}
                                  </TagCheckboxField>
                                ))}
                              </Form.Field>
                            </TagCheckboxGroup>
                          </Hbox.Item>
                          <Hbox.Separator small />
                          <Hbox.Item noGrow>
                            <Button radius='large' size='small' type='submit'>
                              <FaIcon.Plane size='sm' />
                            </Button>
                          </Hbox.Item>
                        </Hbox>
                      ) : (
                        ''
                      )}
                    </Form>
                  )}
                </React.Fragment>
              ))}
            {chatContext.loading && (
              <ChatMessage type='isa'>
                <ActivityIndicator type='sync' />
              </ChatMessage>
            )}
            <Scroll.Element name='chat' />
          </Scroll.Element>
          <FixedFooter>
            <Frame padded noShadow>
              <ChatField
                onMessageSend={chatContext.setAnswer}
                onSetFocusFn={handleFocusFn}
                initialized={initialized}
                loading={loadingCreateAnalysis || editAnalysis.loading || chatContext.loading}
              />
            </Frame>
          </FixedFooter>
        </Hbox.Item>
        <Hbox.Item noGrow>
          <ChatResult
            context={chatContext.context}
            questions={chatContext.questions}
            loading={chatContext.loading}
            systemAttributes={chatContext.systemAttributes}
            initialized={initialized}
            onRemoveContextAttribute={handleRemoveContextAttribute}
            onSubmit={handleFinishChat}
            onAnalysisReset={() => chatContext.resetConversation()}
            attributes={chatContext.attributes}
            onEditAttribute={handleEditAttribute}
          />
        </Hbox.Item>
      </Hbox>
      <ModalAttributes
        openTableType={openTableType}
        onSubmit={handleModalAttributeOption}
        data={chatContext.context}
        onClose={handleCloseModal}
      />
      <ModalComposition
        openTableType={openTableType}
        onSubmit={handleCreateTable}
        data={chatContext.context}
        onClose={handleCloseModal}
      />
      <ModalDiameter
        openTableType={openTableType}
        onSubmit={handleModalExtraDimensions}
        data={chatContext.context}
        onClose={handleCloseModal}
        forma={forma}
        product={product}
        mainDiameterSide={mainDiameterSide}
        mainThickness={mainThickness}
        mainWidth={mainWidth}
        mainConsumo={mainConsumo}
        mainComprimentoMin={mainComprimentoMin}
        mainComprimentoMax={mainComprimentoMax}
      />
      <ModalJominy openTableType={openTableType} onSubmit={handleCreateTable} onClose={handleCloseModal} />
      <ModalMacroataque
        openTableType={openTableType}
        onSubmit={handleCreateTable}
        data={chatContext.context}
        onClose={handleCloseModal}
      />
      <ModalMechanicalProperties
        openTableType={openTableType}
        onSubmit={handleModalData}
        data={chatContext.context}
        onClose={handleCloseModal}
      />
      <ModalMicroinclusao
        openTableType={openTableType}
        onSubmit={handleCreateTable}
        data={chatContext.context}
        onClose={handleCloseModal}
      />
    </LoadingState>
  );
};
