export interface IOrderInputStrings {
  title: string;
  phases: {
    Order: string;
    DataAndAmout: string;
    Multiple: string;
    Revision: string;
  };
  options: {
    title: string;
    uploadSpreadsheet: {
      title: string;
      subtitle: string;
    };
    itensSelection: {
      title: string;
      subtitle: string;
    };
    uploadPdfEmail: {
      title: string;
      subtitle: string;
    };
  };
  selectItens: {
    title: string;
    uploadOptionTitle: string;
    uploadIAReadOptionTitle: string;
    configureWorksheet: string;
    worksheetModel: string;
    stepOne: {
      title: string;
      orderIssuer: string;
      orderIssuerPlaceHolder: string;
      orderReceiver: string;
      orderReceiverPlaceHolder: string;
      orderNumber: string;
      orderNumberPlaceholder: string;
      descriptionSearchByText: string;
      descriptionSearchByPN: string;
      selectedMaterials: string;
      catalogRequiredIssuerMessage: string;
    };
    stepTwo: {
      title: string;
      codeProductText: string;
      materialDescriptionText: string;
      codeMaterialGerdauCatalog: string;
      searchPlaceholderCode: string;
      searchPlaceholderText: string;
      info: string;
      materialCatalogText: string;
      materialFormLine1: string;
      materialFormLine2: string;
      materialFormLine3: string;
      selectedMaterialsTitle: string;
      addButton: string;
    };
    stepThree: {
      title: string;
      titleIARead: string;
      infoDescription: string;
      infoDate: string;
      infoUnit: string;
      infoQtyLabel: string;
      infoTon: string;
      infoKG: string;
      catalogToolTip: string;
    };
  };
  onboarding: {
    title: string;
    description: string;
    confirm: string;
  };
  uploadSpreadSheet: {
    templateButton: string;
    deleteModal: {
      title: string;
      cancel: string;
      remove: string;
    };
    backStepConfirmModal: {
      title: string;
      IAOrderTitle: string;
      cancel: string;
      remove: string;
    };
    multipleFilesAlert: string;
    fileFormatAlert: string;
    fileSizeAlert: string;
    readFileAlert: string;
  };
  uploadPdfEmail: {
    iaTag: string;
    optionDescription: string;
    readOrderLoader: string;
    readOrderLoaderWarning: string;
  };
  dateAndAmount: {
    alertM2messagePart1: string;
    alertM2messagePart2: string;
    alertMultipleMessage: string;
    addMoreItens: string;
    spreadSheetInfoError: string;
    iaReaderInfoError: string;
    editButton: string;
    deleteButton: string;
    tableColumns: {
      orderNumber: string;
      orderItem: string;
      materialDescription: string;
      amount: string;
      multiple: string;
      unity: string;
      date: string;
    };
    codProdClient: string;
    amountMessageTooltip: string;
    dateMessageTooltip: string;
    validation: {
      KGinterval: string;
      KGdetails: string;
      Tinterval: string;
      Tdetails: string;
      orderNumberMessage: string;
      orderNumberDetails: string;
      minKG: string;
      minKGDetail: string;
      minTon: string;
      minTonDetail: string;
    };
    modalActions: {
      deleteTitle: string;
      confirmDelete: string;
      confirmDeletePlural: string;
      cancelButton: string;
      confirmButton: string;
    };
  };
  multiple: {
    modal: {
      title: string;
      message1: string;
      message2: string;
      button: string;
    };
    alertMultiple: string;
  };
  resumeTable: {
    orderNumber: string;
    orderItem: string;
    materialDescription: string;
    amount: string;
    multiple: string;
    unit: string;
    expectedDate: string;
    codProdClientLabel: string;
  };
  success: {
    successMessage: string;
    secondaryMessageLine1: string;
    secondaryMessageLine2: string;
    downloadPdfButton: string;
    newOrderButton: string;
    feedbackMessage: string;
  };
  baseboard: {
    itemAmount: string;
    total: string;
    adjustedTotal: string;
    backButton: string;
    advanceButton: string;
    qtyValidationButton: string;
    goToRevisionButton: string;
    confirmButton: string;
  };
  iaReadWarning: {
    modalTitle: string;
    title: string;
    messageStart: string;
    messageEnd: string;
    confirmButton: string;
  };
}

interface IOrderInputLanguages {
  pt: IOrderInputStrings;
  es: IOrderInputStrings;
  in: IOrderInputStrings;
}

const pt: IOrderInputStrings = {
  title: 'Implantar pedido',
  phases: {
    Order: 'Pedido',
    DataAndAmout: 'Data e quantidade',
    Multiple: 'Validação das quantidades',
    Revision: 'Revisão',
  },
  options: {
    title: 'Selecione as opções',
    uploadSpreadsheet: {
      title: 'Solicite um pedido via planilha',
      subtitle: 'Faça o upload de um arquivo com os itens desejados para o seu pedido',
    },
    itensSelection: {
      title: 'Solicite um pedido via seleção de itens',
      subtitle: 'Selecione e adicione os items desejados para o seu pedido',
    },
    uploadPdfEmail: {
      title: 'Solicite um pedido via pdf ou email',
      subtitle: 'Faça o upload de um arquivo .pdf ou .msg com os itens desejados para o seu pedido',
    },
  },
  selectItens: {
    title: 'Implantar via seleção de itens',
    uploadOptionTitle: 'Implantar via planilha',
    uploadIAReadOptionTitle: 'Implantar via pdf ou email',
    configureWorksheet: 'Configurar planilha',
    worksheetModel: 'Modelo de planilha',
    stepOne: {
      title: '1. Preencha os campos a seguir',
      orderIssuer: 'Emissor do pedido',
      orderIssuerPlaceHolder: 'Selecione o emissor do pedido',
      orderReceiver: 'Recebedor do pedido',
      orderReceiverPlaceHolder: 'Selecione o recebedor do pedido',
      orderNumber: 'Número do pedido',
      orderNumberPlaceholder: 'Número do pedido',
      descriptionSearchByText:
        ' Descrição do material (Descrição do material / Comprimento Mín - Máx / Comprimento Múltiplo / Norma Aço)',
      descriptionSearchByPN:
        ' Código do material - Descrição do material (Descrição do material / Comprimento Mín - Máx / Comprimento Múltiplo / Norma Aço)',
      selectedMaterials: 'Materiais Selecionados:',
      catalogRequiredIssuerMessage: 'Selecione o emissor do pedido!',
    },
    stepTwo: {
      title: '2. Escolha qual nomenclatura de material você deseja utilizar',
      codeProductText: 'Cód. prod. cliente (Part number)',
      materialDescriptionText: 'Descrição do material',
      codeMaterialGerdauCatalog: 'Cód. Material Gerdau (Catálogo)',
      searchPlaceholderCode: 'Busque pelo cód. prod. cliente',
      searchPlaceholderText: 'Busque pela descrição do material',
      info:
        'Adicione os materiais que deseja ao carrinho pesquisando pelo Cód. Prod. cliente (part number) ou descrição do material.',
      materialCatalogText: 'Baixar catálogo de materiais',
      materialFormLine1: 'Adicione os materiais que deseja ao carrinho',
      materialFormLine2: 'pesquisando pelo Cód. Prod. Cliente (Part Number) ou',
      materialFormLine3: 'decrição do material.',
      selectedMaterialsTitle: 'Materiais Selecionados:',
      addButton: 'Adicionar',
    },
    stepThree: {
      title: '3. Upload de planilha',
      titleIARead: '3. Faça o upload do arquivo .pdf ou .msg',
      infoDescription: 'Forma de preenchimento dos campos da planilha: ',
      infoDate: 'Data: DD/MM/AAAA',
      infoUnit: 'Unidade de medida: T ou KG',
      infoQtyLabel: 'Quantidade: ',
      infoTon: '• Tonelada (T): entre 1 a 999',
      infoKG: '• Quilo (KG): entre 1.000 a 999.999',
      catalogToolTip:
        'É uma planilha contendo todos os materiais faturados nos últimos 3 anos, para que você identifique qual é o código do material que deseja solicitar no pedido.',
    },
  },
  onboarding: {
    title: 'Implante um pedido via Gerdau Mais',
    description:
      'No menu superior, clique em “Implantação“ para começar. Você tem disponível a opção de implantar por item através da descrição do material ou Cód. prod. cliente (Part number)',
    confirm: 'Entendido',
  },
  uploadSpreadSheet: {
    templateButton: 'Modelo de planilha',
    deleteModal: {
      title: 'Tem certeza que deseja remover o arquivo?',
      cancel: 'Cancelar',
      remove: 'Remover arquivo',
    },
    backStepConfirmModal: {
      title: 'Ao voltar, as informações da planilha serão revalidadas e não ficarão salvas. Deseja mesmo voltar?',
      IAOrderTitle:
        'Ao voltar, as informações dos arquivos serão revalidadas e não ficarão salvas. Deseja mesmo voltar?',
      cancel: 'Cancelar',
      remove: 'Sim, voltar',
    },
    multipleFilesAlert: 'Falha ao fazer upload do arquivo. Não é possível fazer upload de mais de um arquivo.',
    fileFormatAlert: 'Falha ao fazer upload do arquivo. Formato não suportado.',
    fileSizeAlert: 'Falha ao fazer upload do arquivo. A soma do tamanho dos arquivos não pode ser maior que 200mb.',
    readFileAlert: 'Falha ao fazer upload do arquivo. Não foi possível ler o arquivo',
  },
  uploadPdfEmail: {
    iaTag: 'Leitura com IA',
    optionDescription: 'Revise as informações extraídas do documento.',
    readOrderLoader: 'Por favor aguarde enquanto fazemos a leitura do seu documento',
    readOrderLoaderWarning: 'Obs: pode demorar de acordo com o tamanho do arquivo ou nº de itens do pedido.',
  },
  dateAndAmount: {
    validation: {
      KGinterval: 'KG: 1.000 a 999.999',
      KGdetails: 'Quantidade Quilo(KG) está fora do range 1.000 a 999.999',
      Tinterval: 'T: 1 a 999',
      Tdetails: 'Quantidade Tonelada(T) está fora do range 1 a 999',
      orderNumberMessage: 'Obrigatório',
      orderNumberDetails: 'NumberOrder Campo obrigatório',
      minKG: 'Mínimo = 10.000KG',
      minKGDetail: 'Quantidade mínima de 10.000 quilogramas(KG)',
      minTon: 'Mínimo = 10t',
      minTonDetail: 'Quantidade mínima de 10 toneladas(T)',
    },
    alertM2messagePart1:
      'Datas destacadas em amarelo farão com que o pedido entre em análise pois não estão de acordo com o',
    alertM2messagePart2: ' calendário M+2',
    alertMultipleMessage:
      'Atenção! As quantidades solicitadas deverão ser ajustadas de acordo com o peso múltiplo cadastrado para cada material. Você ainda poderá reajustar para mais ou para menos antes de confirmar o envio do pedido. ',
    addMoreItens: 'Adicionar mais itens ao pedido',
    spreadSheetInfoError:
      'Não foi possível reconhecer algumas informações da sua planilha. Por favor, verifique e corrija os campos sinalizados.',
    iaReaderInfoError:
      'Não foi possível reconhecer algumas informações do(s) arquivo(s). Por favor, verifique e corrija os campos sinalizados.',
    editButton: 'Editar itens selecionados',
    deleteButton: 'Deletar itens selecionados',
    tableColumns: {
      orderNumber: 'Nº do pedido',
      orderItem: 'Item do pedido (opcional)',
      materialDescription: 'Descrição do material',
      amount: 'Quantidade',
      multiple: 'Quantidade ajustada',
      unity: 'Unidade',
      date: 'Data desejada',
    },
    codProdClient: 'Cod. prod cliente:',
    amountMessageTooltip:
      'Atenção! As quantidades solicitadas deverão ser ajustadas de acordo com o peso múltiplo cadastrado para o material. Você ainda poderá reajustar para mais ou para menos antes de confirmar o envio do pedido. ',
    dateMessageTooltip:
      'Atenção! Datas destacadas em amarelo farão com que o pedido entre em análise pois não estão de acordo com o calendário M+2',

    modalActions: {
      deleteTitle: 'Excluir',
      confirmDelete: 'Deseja realmente deletar o material do pedido?',
      confirmDeletePlural: 'Deseja realmente deletar os materiais selecionados do pedido?',
      cancelButton: 'Cancelar',
      confirmButton: 'Deletar',
    },
  },
  multiple: {
    modal: {
      title: 'Atenção!',
      message1:
        'Caro cliente, nesta tela iremos sugerir uma quantidade ajustada de acordo com a quantidade que foi solicitada para alguns itens do seu pedido. Você ainda pode reajustar para mais ou para menos antes de confirmar o pedido.',
      message2: 'Por favor, aguarde alguns instantes enquanto carregamos as quantidades que serão sugeridas.',
      button: 'Ok, entendido',
    },
    alertMultiple:
      'Os valores foram ajustados para os sugeridos abaixo, você ainda pode colocar um valor pra cima ou pra baixo de acordo com a quantidade.',
  },
  resumeTable: {
    orderNumber: 'Nº do pedido',
    orderItem: 'Item do pedido',
    materialDescription: 'Descrição do material',
    amount: 'Quantidade',
    unit: 'Unidade',
    multiple: 'Quantidade ajustada',
    expectedDate: 'Data desejada',
    codProdClientLabel: 'Cód. prod. cliente:',
  },
  success: {
    successMessage: 'Sua solicitação de implantação de pedidos foi finalizada com sucesso.',
    secondaryMessageLine1:
      'Nossa equipe já recebeu sua solicitação e vai providenciar a implantação do seu pedido. Em seguida,',
    secondaryMessageLine2: 'você poderá consultar seu pedido implantado na carteira.',
    downloadPdfButton: 'Baixar PDF com detalhes do pedido',
    newOrderButton: 'Implantar novo pedido',
    feedbackMessage:
      'De 1 a 5, o quão você está satisfeito com a funcionalidade de implantação de pedido no Gerdau Mais?',
  },
  baseboard: {
    itemAmount: 'Quantidade de itens',
    total: 'Qtd. total solicitada (t)',
    adjustedTotal: 'Qtd. total ajustada (t)',
    backButton: 'Voltar',
    advanceButton: 'Avançar',
    qtyValidationButton: 'Validação das quantidades',
    goToRevisionButton: 'Ir para revisão do pedido',
    confirmButton: 'Confirmar pedido',
  },
  iaReadWarning: {
    modalTitle: 'Atenção!',
    title: 'Recomendação de revisão',
    messageStart:
      'A Inteligência Artificial faz a leitura do arquivo de forma rápida e confiável, mas também está sujeita a erros e limitações.',
    messageEnd: 'Recomendamos que você revise e verifique os dados que foram extraídos do seu arquivo.',
    confirmButton: 'Ok, entendido',
  },
};

export const OrderInputStrings: IOrderInputLanguages = {
  pt,
  es: pt,
  in: pt,
};
