import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { Chat, ChatResponse, UnfinishedChatsResponse } from '@app/models';
import { ChatAnalysisParams } from '@app/models/analysis.model';
import { FullAnalysisData, SimiliarsMaterialsModalData } from '@app/models/quote.model';
import { AuthStorageService } from '../storage/auth.storage';
import { mapEditConversationParams } from './chat.dto';
import { CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from './constants';
import { IsaHttpRequestBuilder } from './isa-http-request.builder';
import {
  CreateAnalysisParams,
  CreateTableData,
  DeleteConversationParams,
  DownloadAnalysis,
  EditAnalysisParams,
  EditConversationData,
  GetAnalysisParams,
  PostConversationParams,
  PostNluParams,
  MakeVisibleAnalysisParams,
  CreateExtraDimensionData,
} from './quote-params.dto';
import {
  chatResponseToChat,
  chatResponseToChatUnfinished,
  FullAnalysisResponse,
  mapFullAnalysis,
  mapSimiliarMaterials,
  mapUnfinishedChatsList,
  SimilarsMaterialResponse,
} from './quote.dto';

const endpoint = {
  CONVERSATION_ISA: '/assistant',
  ATTRIBUTES_ISA: '/assistant/attributes',
  NEW_ATTRIBUTES_ISA: '/assistant/attributes/others',
  UNFINISHED_CHAT: '/assistant/chats/unfinished',
  UNFINISHED_LAST_CHAT: '/assistant/chats/unfinished/last',
  NORMA_ISA: '/nlu',
  ANALYSIS: '/analyses',
  ANALYSIS_ASSETS: '/assets',
  EXPORT: '/exports/pdf',
  QUOTE: '/cotacao',
  CLIENTS: '/clients',
  PDF: '/pdf',
  SIMILAR_MATERIAL: '/similar-materials',
};

const user = Container.get(AuthStorageService).getUser();
const defaultHeaders = {
  client_id: CXP_KEY_CLIENT_ID,
  client_secret: CXP_KEY_CLIENT_SECRET,
  'Content-Type': 'application/json',
  Accept: '*/*',
};

export const QuoteDataSource = {
  postConversation: (data: PostConversationParams): Promise<Chat> => {
    const params = {
      query: { text: data.answer },
      context: data.currentContext,
      api: {
        vendor: {
          billing: 'v2',
        },
      },
    };

    return Container.get(HttpRequestBuilder)
      .post(endpoint.CONVERSATION_ISA)
      .headers({
        ...defaultHeaders,
        language: data.language,
        'sso-access': user.ssoAccess,
        Authorization: 'Bearer ' + data.authorizationToken,
        'Access-Token': data.accessToken,
      })
      .data(params)
      .interceptors({
        afterSuccess: async (res: ChatResponse): Promise<Chat> => chatResponseToChat(res),
      })
      .execute();
  },

  postConversationNewAttribute: (data: PostConversationParams): any => {
    const params = {
      label: data.label.replaceAll('/', '-'),
      value: data.value,
      conversation_id: data.conversationId,
    };

    return Container.get(HttpRequestBuilder)
      .post(endpoint.NEW_ATTRIBUTES_ISA)
      .headers({
        ...defaultHeaders,
        language: data.language,
        'sso-access': user.ssoAccess,
        Authorization: 'Bearer ' + data.authorizationToken,
        'Access-Token': data.accessToken,
      })
      .data(params)
      .interceptors({
        afterSuccess: async (res: ChatResponse): Promise<Chat> => chatResponseToChat(res),
      })
      .execute();
  },

  deleteConversation: (data: DeleteConversationParams): Promise<Chat> => {
    const params = {
      conversation_id: data.conversationId,
    };

    return Container.get(HttpRequestBuilder)
      .delete(`${endpoint.ATTRIBUTES_ISA}/${data.attributeKey}`)
      .headers({
        ...defaultHeaders,
        language: data.language,
        'sso-access': user.ssoAccess,
        Authorization: 'Bearer ' + data.authorizationToken,
        'Access-Token': data.accessToken,
      })
      .data(params)
      .interceptors({
        afterSuccess: async (res: ChatResponse): Promise<Chat> => chatResponseToChat(res),
      })
      .execute();
  },

  resetConversation: (id: string): Promise<Chat> => {
    const params = {
      conversation_id: id,
    };

    return Container.get(HttpRequestBuilder)
      .delete(endpoint.ATTRIBUTES_ISA)
      .headers({ ...defaultHeaders, 'sso-access': user.ssoAccess })
      .data(params)
      .interceptors({
        afterSuccess: async (res: ChatResponse): Promise<Chat> => chatResponseToChat(res),
      })
      .execute();
  },

  editConversation: (data: EditConversationData): Promise<Chat> => {
    const params = mapEditConversationParams(data);

    return Container.get(HttpRequestBuilder)
      .patch(`${endpoint.ATTRIBUTES_ISA}/${data.attributeKey}`)
      .headers({
        ...defaultHeaders,
        language: data.language,
        'sso-access': user.ssoAccess,
        Authorization: 'Bearer ' + data.authorizationToken,
        'Access-Token': data.accessToken,
      })
      .data(params)
      .interceptors({
        afterSuccess: async (res: ChatResponse): Promise<Chat> => chatResponseToChat(res),
      })
      .execute();
  },

  postCreateTable: (data: CreateTableData): Promise<Chat> => {
    const params = { conversation_id: data.conversation_id, value: data.value };

    return Container.get(HttpRequestBuilder)
      .post(`${endpoint.ATTRIBUTES_ISA}/${data.key}`)
      .headers({
        ...defaultHeaders,
        language: data.language,
        'sso-access': user.ssoAccess,
        Authorization: 'Bearer ' + data.authorizationToken,
        'Access-Token': data.accessToken,
      })
      .data(params)
      .interceptors({
        afterSuccess: async (res: ChatResponse): Promise<Chat> => chatResponseToChat(res),
      })
      .execute();
  },

  postCreateExtraDimension: (data: CreateExtraDimensionData): Promise<Chat> => {
    const params = {
      query: { text: '' },
      context: data.currentContext,
    };

    return Container.get(HttpRequestBuilder)
      .post(endpoint.CONVERSATION_ISA)
      .headers({
        ...defaultHeaders,
        language: data.language,
        'sso-access': user.ssoAccess,
        Authorization: 'Bearer ' + data.authorizationToken,
        'Access-Token': data.accessToken,
      })
      .data(params)
      .interceptors({
        afterSuccess: async (res: ChatResponse): Promise<Chat> => chatResponseToChat(res),
      })
      .execute();
  },

  uploadProject: async (params: PostNluParams): Promise<ChatResponse> => {
    const headers = { ...defaultHeaders, 'Allow-GPT': true, language: params.language, 'sso-access': user.ssoAccess };

    const form = new FormData();
    form.append('pdf', params.file);
    form.append('conversation_id', params.conversationId);
    return Container.get(HttpRequestBuilder)
      .post(endpoint.NORMA_ISA)
      .headers(headers)
      .data(form)
      .interceptors({
        afterSuccess: async (res: ChatResponse): Promise<Chat> => chatResponseToChat(res),
      })
      .execute();
  },

  getClientList: (): Promise<string[]> => {
    return Container.get(HttpRequestBuilder)
      .get(endpoint.CLIENTS)
      .headers({ ...defaultHeaders, 'sso-access': user.ssoAccess })
      .execute();
  },

  createAnalysis: (params: CreateAnalysisParams): Promise<{ id: number }> => {
    return Container.get(HttpRequestBuilder)
      .post(endpoint.ANALYSIS)
      .headers({ ...defaultHeaders, 'sso-access': user.ssoAccess })
      .data({
        conversation_id: params.conversationId,
      })
      .interceptors({
        afterSuccess: async (res: any): Promise<{ id: number }> => {
          return { id: res.id };
        },
      })
      .execute();
  },

  getAnalysis: (params: GetAnalysisParams): Promise<FullAnalysisData> => {
    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.ANALYSIS}/${params.id}`)
      .headers({
        ...defaultHeaders,
        'sso-access': user.ssoAccess,
        Authorization: 'Bearer ' + params.authorizationToken,
        'Access-Token': params.accessToken,
      })
      .interceptors({
        afterSuccess: async (res: FullAnalysisResponse): Promise<FullAnalysisData> => mapFullAnalysis(res),
      })
      .execute();
  },

  getSimilarsMaterials: (params: GetAnalysisParams): Promise<SimiliarsMaterialsModalData> => {
    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.ANALYSIS}/${params.id}${endpoint.SIMILAR_MATERIAL}`)
      .headers({ ...defaultHeaders, 'sso-access': user.ssoAccess })
      .interceptors({
        afterSuccess: async (res: SimilarsMaterialResponse): Promise<SimiliarsMaterialsModalData> =>
          mapSimiliarMaterials(res),
      })
      .execute();
  },

  getChatAnalysis: (params: ChatAnalysisParams): Promise<Chat> => {
    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.CONVERSATION_ISA}/${params.conversationId}`)
      .params({
        type: params.type,
      })
      .headers({
        ...defaultHeaders,
        language: params.language,
        'sso-access': user.ssoAccess,
        Authorization: 'Bearer ' + params.authorizationToken,
        'Access-Token': params.accessToken,
      })
      .interceptors({
        afterSuccess: async (res: ChatResponse): Promise<Chat> => chatResponseToChat(res),
      })
      .execute();
  },

  editAnalysis: (params: EditAnalysisParams): Promise<any> => {
    return Container.get(HttpRequestBuilder)
      .put(`${endpoint.ANALYSIS}/${params.analysisId}`)
      .headers({
        ...defaultHeaders,
        language: params.language,
        'sso-access': user.ssoAccess,
        Authorization: 'Bearer ' + params.authorizationToken,
        'Access-Token': params.accessToken,
      })
      .data({
        conversation_id: params.conversationId,
      })
      .interceptors({
        afterSuccess: async (res: any): Promise<{ id: number }> => {
          return { id: res.id };
        },
      })
      .execute();
  },

  downloadAnalysis: async (params: DownloadAnalysis): Promise<any> => {
    const hasParams = params.plants?.length > 0 ? '?' : '';
    let plants = [];
    let queryPlants = '';
    if (params?.plants) {
      plants = params.plants?.map(item => `plants[]=${item}`);
      queryPlants = plants.join('&');
    }

    const header = {
      ...Container.get(IsaHttpRequestBuilder).config.headers,
      language: params.language,
      Authorization: 'Bearer ' + params.authorizationToken,
      'Access-Token': params.accessToken,
    };

    return fetch(
      `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
        endpoint.EXPORT
      }${hasParams}${queryPlants}`,
      {
        headers: header,
      },
    ).then(value => value.blob());
  },

  getUnfinishedLastChatsList: (): Promise<UnfinishedChatsResponse[]> => {
    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.UNFINISHED_LAST_CHAT}`)
      .headers({ ...defaultHeaders, 'sso-access': user.ssoAccess })
      .interceptors({
        afterSuccess: async (res: UnfinishedChatsResponse[]): Promise<UnfinishedChatsResponse[]> =>
          mapUnfinishedChatsList(res),
      })
      .execute();
  },

  getUnfinishedChatsList: (params): Promise<UnfinishedChatsResponse[]> => {
    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.UNFINISHED_CHAT}`)
      .headers({
        ...defaultHeaders,
        language: params.language,
        'sso-access': user.ssoAccess,
        Authorization: 'Bearer ' + params.authorizationToken,
        'Access-Token': params.accessToken,
      })
      .interceptors({
        afterSuccess: async (res: UnfinishedChatsResponse[]): Promise<UnfinishedChatsResponse[]> =>
          mapUnfinishedChatsList(res),
      })
      .execute();
  },

  getUnfinishedAnalysis: (params: ChatAnalysisParams): Promise<Chat> => {
    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.UNFINISHED_CHAT}/${params.conversationId}`)
      .headers({
        ...defaultHeaders,
        language: params.language,
        'sso-access': user.ssoAccess,
        Authorization: 'Bearer ' + params.authorizationToken,
        'Access-Token': params.accessToken,
      })
      .interceptors({
        afterSuccess: async (res: ChatResponse): Promise<Chat> => chatResponseToChatUnfinished(res),
      })
      .execute();
  },

  deleteUnfinishedAnalysis: (params: ChatAnalysisParams): Promise<Chat> => {
    return Container.get(HttpRequestBuilder)
      .delete(`${endpoint.UNFINISHED_CHAT}/${params.conversationId}`)
      .headers({ ...defaultHeaders, 'sso-access': user.ssoAccess })
      .execute();
  },

  makeVisibleUnvisibleAnalysis: (params: MakeVisibleAnalysisParams): Promise<any> => {
    return Container.get(HttpRequestBuilder)
      .put(`${endpoint.ANALYSIS}`)
      .headers({ ...defaultHeaders, language: params.language, 'sso-access': user.ssoAccess })
      .data({
        ids: params.ids,
        clientVisible: params.clientVisible,
      })
      .execute();
  },
};
