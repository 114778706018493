import { format } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { AnalysisResume } from '@app/models/analysis.model';
import { OrderFormStrings } from '@app/modules/quote/quote.string';
import { Body } from '@atomic/atm.typography';
import { FlexColumn } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import {
  IsaReferencePopoverArrowStyled,
  IsaReferencePopoverBoxStyled,
  IsaReferencePopoverWrapper,
} from './isa-reference-popover.style';

interface IsaReferencePopoverProps {
  item: AnalysisResume;
}

export const IsaReferencePopover: React.FC<IsaReferencePopoverProps> = ({ item }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const Strings = OrderFormStrings[userInfo.language];

  if (!item) {
    return null;
  }

  return (
    <IsaReferencePopoverWrapper>
      <IsaReferencePopoverBoxStyled>
        <FlexColumn>
          <Body color={Color.White} bold={700}>
            {Strings.tooltip.cfCreator}
          </Body>
        </FlexColumn>
        <FlexColumn>
          <Body color={Color.White}>{`${item.userCreator?.firstName} ${item.userCreator?.lastName}`}</Body>
        </FlexColumn>
        <FlexColumn mt={Spacing.Small}>
          <Body color={Color.White} bold={700}>
            {Strings.tooltip.client}
          </Body>
        </FlexColumn>
        <FlexColumn>
          <Body color={Color.White}>{item.client?.name}</Body>
        </FlexColumn>
        <FlexColumn mt={Spacing.Small}>
          <Body color={Color.White} bold={700}>
            {Strings.tooltip.status}
          </Body>
        </FlexColumn>
        <FlexColumn>
          <Body color={Color.White}>{item.status?.external?.label}</Body>
        </FlexColumn>
        <FlexColumn mt={Spacing.Small}>
          <Body color={Color.White} bold={700}>
            {Strings.tooltip.searchType}
          </Body>
        </FlexColumn>
        <FlexColumn>
          <Body color={Color.White}>{item.flow?.label}</Body>
        </FlexColumn>
        <FlexColumn mt={Spacing.Small}>
          <Body color={Color.White} bold={700}>
            {Strings.tooltip.startDate}
          </Body>
        </FlexColumn>
        <FlexColumn>
          <Body color={Color.White}>{`${format(item.createdDate, 'dd/MM/yyyy')} às ${format(
            item.createdDate,
            'HH:ii',
          )}`}</Body>
        </FlexColumn>
        <IsaReferencePopoverArrowStyled xAxis={100} alignBottom={true} />
      </IsaReferencePopoverBoxStyled>
    </IsaReferencePopoverWrapper>
  );
};
