import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { InversedAttributeStatus } from '@app/models/quote.model';
import { ChatStrings } from '@app/modules/chat/chat.string';
import { statusInfo } from '@app/modules/quote/quote.contants';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { Badge } from '@atomic/atm.badge';
import { TextField } from '@atomic/atm.text-field';
import { Body, H3 } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Hbox, Separator } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { QuoteStrings } from '../../quote.string';
import { AttributeSpecificProps } from './technical-analysis-attribute.component';
import { TechnicalAnalysisAttributeContext } from './technical-analysis-attribute.context';

export const Microinclusion: React.FC<AttributeSpecificProps> = props => {
  const { isEditing, editedStatus, onRowSuggestionChanged } = React.useContext(TechnicalAnalysisAttributeContext);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const attribute = { ...props.attribute };
  const strings = TechnicalAnalysisStrings[userInfo.language].attributes.attributeCell;
  const attributeDerogateBadges = TechnicalAnalysisStrings[props.language].attributeDerogateBadges;
  const columnsLabel = ChatStrings[userInfo.language].modalMicroinclusao;
  const statusBadge = QuoteStrings[userInfo.language].constants.statusAttributes;
  const canEditDerogate = attribute.derogate?.permissions?.isEditable;

  const convertDecimal = value => {
    if (value?.toString().includes(',') || value?.toString().includes('.')) {
      return value.toString().replace('.', ',');
    } else {
      if (value === 'derogate') {
        return '-';
      } else {
        return parseFloat(value)
          .toFixed(1)
          .toString()
          .replace('.', ',');
      }
    }
  };

  const convertDerrogateValue = (derrogateValue, derrogateProvValue, oldValue) => {
    if (derrogateValue && derrogateValue !== undefined) {
      return convertDecimal(derrogateValue);
    } else if (derrogateProvValue && derrogateProvValue !== undefined && derrogateProvValue !== 'derogate') {
      return convertDecimal(derrogateProvValue);
    } else {
      if (oldValue !== 'derogate') {
        return convertDecimal(oldValue);
      } else {
        return '-';
      }
    }
  };

  const checkDerrogatedValue = (value: any, index: number, status: string, oldValue: number) => {
    onRowSuggestionChanged(value, index, +value.replace(',', '.') <= oldValue ? 'accepted' : status);
  };

  return (
    <>
      <Table collapse>
        <TR key='header' bordered>
          <TD color={Color.Secondary} bordered width={20}>
            <Body bold={600}>{attribute.attributeValue.columns[1].label}</Body>
          </TD>
          {attribute.attributeValue.rows.map(
            (item, index) =>
              index <= 3 && (
                <TD key={'h-' + index} bordered>
                  <Body bold={600} centralized>
                    {item[0]?.value}
                  </Body>
                </TD>
              ),
          )}
        </TR>
        <TR key='fino' bordered>
          <TD color={Color.Secondary} bordered>
            <Body bold={600}>{columnsLabel.thin.toUpperCase()}</Body>
          </TD>
          {attribute.attributeValue.rows.map(
            (item, index) =>
              index <= 3 && (
                <TD key={'suggestion' + index} bordered>
                  <Body centralized>{convertDecimal(item[2]?.value)}</Body>
                </TD>
              ),
          )}
        </TR>
        <TR key='grosso' bordered>
          <TD color={Color.Secondary} bordered>
            <Body bold={600}>{columnsLabel.thick.toUpperCase()}</Body>
          </TD>
          {attribute.attributeValue.rows.map(
            (item, index2) =>
              index2 > 3 && (
                <TD key={'suggestion' + (index2 + 4)} bordered>
                  <Body centralized>{convertDecimal(item[2]?.value)}</Body>
                </TD>
              ),
          )}
        </TR>
      </Table>
      {attribute.status === InversedAttributeStatus.derogate || editedStatus === InversedAttributeStatus.derogate ? (
        <>
          <Separator />
          <Hbox.Item noGrow>
            <H3>{strings.derogationTitle}</H3>
          </Hbox.Item>
          <Hbox>
            {!canEditDerogate &&
              !!TechnicalAnalysisStrings[props.language].attributeDerogateBadges[
                attribute.derogate?.suggestionStatus
              ] && (
                <Hbox.Item noGrow>
                  <Badge color={attributeDerogateBadges[attribute.derogate?.suggestionStatus]?.color}>
                    {attributeDerogateBadges[attribute.derogate?.suggestionStatus]?.label}
                  </Badge>
                </Hbox.Item>
              )}
          </Hbox>

          <Separator small />
          <Table collapse>
            <TR key='d-header' bordered>
              <TD color={Color.Secondary} bordered width={20}>
                <Body bold={600}>{attribute.attributeValue.columns[1].label}</Body>
              </TD>
              {attribute.attributeValue.rows.map(
                (item, index) =>
                  index <= 3 && (
                    <TD key={'d-' + index} bordered>
                      <Body bold={600} centralized>
                        {item[0]?.value}
                      </Body>
                    </TD>
                  ),
              )}
            </TR>

            <TR key='d-fino' bordered>
              <TD color={Color.Secondary} bordered>
                <Body bold={600}>{columnsLabel.thin.toUpperCase()}</Body>
              </TD>
              {attribute.attributeValue.rows.map(
                (item, index) =>
                  index <= 3 && (
                    <TD key={'d-f-' + index} bordered>
                      {!isEditing || editedStatus !== InversedAttributeStatus.derogate ? (
                        <>
                          <Body
                            centralized
                            color={item[4]?.value === InversedAttributeStatus.derogate ? Color.Alert : null}
                          >
                            {convertDerrogateValue(item[6]?.value, item[5]?.value, item[2]?.value)}
                          </Body>
                          <Separator small />
                          {item[4]?.value ? (
                            <Badge
                              color={statusInfo[item[4]?.columnRef === 'status' ? item[4]?.value : 'derogate'].badge}
                            >
                              {statusBadge[item[4]?.columnRef === 'status' ? item[4]?.value : 'derogate']}
                            </Badge>
                          ) : null}

                          <Separator small />
                        </>
                      ) : (
                        <TextField
                          initialValue={convertDerrogateValue(item[6]?.value, item[5]?.value, item[2]?.value)}
                          onValueChange={value => checkDerrogatedValue(value, index, editedStatus, item[2]?.value)}
                        />
                      )}
                    </TD>
                  ),
              )}
            </TR>

            <TR key='d-grosso' bordered>
              <TD color={Color.Secondary} bordered>
                <Body bold={600}>{columnsLabel.thick.toUpperCase()}</Body>
              </TD>
              {attribute.attributeValue.rows.map(
                (item, index2) =>
                  index2 > 3 && (
                    <TD key={'d-g-' + index2} bordered>
                      {!isEditing || editedStatus !== InversedAttributeStatus.derogate ? (
                        <>
                          <Body
                            centralized
                            color={item[4]?.value === InversedAttributeStatus.derogate ? Color.Alert : null}
                          >
                            {convertDerrogateValue(item[6]?.value, item[5]?.value, item[2]?.value)}
                          </Body>
                          <Separator small />
                          {item[4]?.value ? (
                            <Badge
                              color={statusInfo[item[4]?.columnRef === 'status' ? item[4]?.value : 'derogate'].badge}
                            >
                              {statusBadge[item[4]?.columnRef === 'status' ? item[4]?.value : 'derogate']}
                            </Badge>
                          ) : null}
                          <Separator small />
                        </>
                      ) : (
                        <TextField
                          initialValue={convertDerrogateValue(item[6]?.value, item[5]?.value, item[2]?.value)}
                          onValueChange={value => checkDerrogatedValue(value, index2, editedStatus, item[2]?.value)}
                        />
                      )}
                    </TD>
                  ),
              )}
            </TR>
          </Table>
        </>
      ) : (
        ''
      )}
    </>
  );
};
