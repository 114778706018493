import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { PostAssetsParams } from '@app/data/http/quote-assets-params.dto';

export const postAssets = async (params?: PostAssetsParams) => {
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    language: params.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  const form = new FormData();

  if (!params.isClientVisible) {
    form.append('client_visible', `${params.isClientVisible ? params.isClientVisible : false}`);
  }
  form.append('file', params.file);

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_ASSETS
  }`;

  await axios.post(url, form, { headers: defaultHeaders });
};

export const usePostAssets = onSuccess => {
  return useMutation(postAssets, {
    onSuccess,
  });
};
