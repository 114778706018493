import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { b64toBlob } from '@app/data/http/document.dto';
import { GetAssetsResponse } from '@app/data/http/quote-assets.dto';

export async function getPriceQuotation(params?: any): Promise<GetAssetsResponse[]> {
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    language: params.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.QUOTE}/${params.quoteId}${
    endpoint.PDF
  }`;

  const { data, status } = await axios.get(url, { headers: defaultHeaders });

  if (status === 200) {
    const blob = b64toBlob(data[0].pdfBase64, 'application/pdf');
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `Preço-${params.quoteId}.pdf`;
    link.click();
  }
  return null;
}

export const useDownloadPriceQuotation = () => {
  return useMutation(getPriceQuotation, {});
};
