import * as React from 'react';
import { Cell } from '@atomic/atm.cell';
import { H4 } from '@atomic/atm.typography';
import { ClientList } from '../form/form.context';
import { QuoteModalClientFilterStyled } from './quote.components.style';

interface QuoteClientListProps {
  clientList: ClientList[];
  query: any;
  onClientClick: (client: string) => void;
  showClients: boolean;
  onClose?: () => void;
}

const formatClients = (previousValue: string) => {
  if (previousValue && typeof previousValue === 'string') {
    const clients = previousValue;

    return clients.toUpperCase();
  }
  return previousValue;
};

export const QuoteClientList: React.FC<QuoteClientListProps> = props => {
  const filteredClientList = props.clientList?.filter(item =>
    item.formatList.toUpperCase().includes(formatClients(props.query)),
  );

  return (
    <QuoteModalClientFilterStyled show={props.showClients}>
      {props.clientList &&
        filteredClientList.map(client => (
          <Cell hover key={client.bp} onClick={() => props.onClientClick(client.formatList)}>
            <H4>{client.formatList}</H4>
          </Cell>
        ))}
    </QuoteModalClientFilterStyled>
  );
};
