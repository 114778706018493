import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';

export const deleteImageDerogate = async (params?: any) => {
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    language: params.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_PLANT
  }/${params.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.attributeKey}/${params.image}`;

  await axios.delete(url, { headers: defaultHeaders });
};

export const useDeleteImageDerogate = (onSuccess?, onError?) => {
  const queryClient = useQueryClient();
  return useMutation(deleteImageDerogate, {
    onSuccess,
    onError,
    onMutate: async data => {
      await queryClient.cancelQueries(['getTechnicalAnalysis']);
      const previousData = queryClient.getQueriesData(['getTechnicalAnalysis']);

      queryClient.setQueriesData(['getTechnicalAnalysis'], (oldQueryData: any) => {
        const selectedPlant = oldQueryData.plants.filter(plant => plant.key === data.plantKey);
        const attribute = selectedPlant[0].attributes.filter(attr => attr.key === data.attributeKey);

        attribute[0].derogate.images = attribute[0].derogate.images.filter(img => img !== data.image);

        return {
          ...oldQueryData,
        };
      });
      return {
        previousData,
      };
    },
  });
};
