import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';

interface ParamsEdit {
  id: string;
  file?: any;
  title: string;
  expirationDate: string;
  documentType?: string;
  downloadGroup?: string;
  authorizationToken: string;
  accessToken: string;
  language: string;
}

interface ParamsDelete {
  id: string;
  language: string;
  authorizationToken: string;
  accessToken: string;
}

export const DocumentsDatasource = {
  async editDocument(params?: ParamsEdit) {
    const defaultHeaders = {
      ...Container.get(IsaHttpRequestBuilder).config.headers,
      language: params.language,
      Authorization: 'Bearer ' + params.authorizationToken,
      'Access-Token': params.accessToken,
    };

    const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.DOCUMENTS}/${params.id}`;

    const form = new FormData();

    form.append('title', params.title);
    form.append('documentType', params.documentType);
    form.append('downloadGroup', params.downloadGroup);
    if (params.expirationDate) {
      form.append('expirationDate', params.expirationDate);
    }
    if (params?.file !== undefined) {
      form.append('file', params.file);
    }

    const { data: dataResponse } = await axios.patch(url, form, { headers: defaultHeaders });

    return dataResponse;
  },
  async postDocument(params?: any) {
    const defaultHeaders = {
      ...Container.get(IsaHttpRequestBuilder).config.headers,
      language: params.language,
      Authorization: 'Bearer ' + params.authorizationToken,
      'Access-Token': params.accessToken,
    };

    const form = new FormData();

    form.append('title', params.title);
    form.append('documentType', params.documentType);
    form.append('downloadGroup', params.downloadGroup);
    if (params.expirationDate) {
      form.append('expirationDate', params.expirationDate);
    }
    form.append('file', params.file);

    const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.DOCUMENTS}`;

    const { data: dataResponse } = await axios.post(url, form, { headers: defaultHeaders });

    return dataResponse;
  },
  async deleteDocument(params?: ParamsDelete) {
    const defaultHeaders = {
      ...Container.get(IsaHttpRequestBuilder).config.headers,
      language: params.language,
      Authorization: 'Bearer ' + params.authorizationToken,
      'Access-Token': params.accessToken,
    };

    const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.DOCUMENTS}/${params.id}`;

    const { data: dataResponse } = await axios.delete(url, { headers: defaultHeaders });

    return dataResponse;
  },
  async getGetDocumentList(params) {
    const defaultHeaders = {
      ...Container.get(IsaHttpRequestBuilder).config.headers,
      language: params.language,
      Authorization: 'Bearer ' + params.authorizationToken,
      'Access-Token': params.accessToken,
    };

    const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${
      endpoint.DOCUMENTS
    }?sort=asc&order=title&take=100&skip=0&documentType=${params.documentType}`;

    const { data, status } = await axios.get(url, { headers: defaultHeaders });

    if (status === 200) {
      return data;
    } else {
      return [];
    }
  },
};

export const useEditDocument = (onSuccess?, onError?) => {
  const queryClient = useQueryClient();
  return useMutation(DocumentsDatasource.editDocument, {
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(['getDocumentList']);
    },
  });
};

export const usePostDocument = (onSuccess?, onError?) => {
  const queryClient = useQueryClient();
  return useMutation(DocumentsDatasource.postDocument, {
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(['getDocumentList']);
    },
  });
};

export const useDeleteDocument = (onSuccess?, onError?) => {
  const queryClient = useQueryClient();
  return useMutation(DocumentsDatasource.deleteDocument, {
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(['getDocumentList']);
    },
  });
};

export function useGetDocumentList(params?, onSuccess?, onError?) {
  return useQuery(['getDocumentList', params], () => DocumentsDatasource.getGetDocumentList(params), {
    onSuccess,
    onError,
    refetchOnWindowFocus: false,
    retry: false,
  });
}
