import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { GetAnalysisParams } from '@app/data/http/quote-params.dto';
import { mapSimiliarMaterials, SimilarsMaterialResponse } from '@app/data/http/quote.dto';

export async function getSimilarsMaterials(params?: GetAnalysisParams): Promise<SimilarsMaterialResponse> {
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    'Access-Control-Allow-Origin': 'egerdau.com.br',
    language: params.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.id}${
    endpoint.SIMILAR_MATERIAL
  }`;

  const { data, status } = await axios.get(url, { headers: defaultHeaders });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
}

export function useTechnicalAnalysisSimilarsMaterials(params: GetAnalysisParams, onError?) {
  return useQuery(['getSimilarsMaterials', params], () => getSimilarsMaterials(params), {
    onError,
    select: data => {
      return mapSimiliarMaterials(data);
    },
    enabled: params.enabled,
    refetchOnWindowFocus: false,
    retry: false,
  });
}
