import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { EditSequence } from '@app/data/http/quote-plant-params.dto';

export const editAttributes = async (params?: EditSequence) => {
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    language: params.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  const data = { attributes_sequence: params.attributesSequence };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_PLANT
  }`;

  const { data: dataResponse } = await axios.patch(url, data, { headers: defaultHeaders });

  return dataResponse;
};

export const useEditAttributesSequence = (onSuccess?, onError?) => {
  const queryClient = useQueryClient();
  return useMutation(editAttributes, {
    onSuccess,
    onError,
    onMutate: async () => {
      await queryClient.cancelQueries(['getTechnicalAnalysis']);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['getTechnicalAnalysis']);
    },
  });
};
