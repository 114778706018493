import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { validateTokens } from '@app/modules/components/notification-center/store/thunk/validate-and-get-notification.thunk';
import { TechnicalAnalysisNewAttributeEvents } from '@app/modules/quote/quote.analytics';
import { TechnicalAnalysisContext } from '@app/modules/quote/technical-analysis/technical-analysis.context';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { FlashMessageTargetName } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { CheckboxField } from '@atomic/atm.checkbox/checkbox-field.component';
import { TextAreaField, TextField } from '@atomic/atm.text-field';
import { H2 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { usePostNewAttribute } from '../hooks/usePostNewAttribute';

interface TechnicalAnalysisNewAttributeModalProps {
  opened: boolean;
  plants: any;
  plantKey: string;
  onClose: () => void;
}

interface AttributeFormData {
  label: string;
  value: string;
}

export const TechnicalAnalysisNewAttributeModal: React.FC<TechnicalAnalysisNewAttributeModalProps> = props => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const [replicateAttribute, setReplicateAttribute] = React.useState(true);
  const { analysisId, setHasTokenChecked } = React.useContext(TechnicalAnalysisContext);
  const { userInfo, azureToken, token } = useSelector((state: RootState) => state.auth);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const strings = TechnicalAnalysisStrings[userInfo.language].attributes.newAttribute;
  const isReplicable = props.plants.find(plant => props.plantKey === plant.key).permissions.attributesReplicable;

  const handleSuccess = () => {
    show('success', strings.messages.success);
    props.onClose();
  };

  const handleError = () => {
    LogAnalytics.error({ tipo: TechnicalAnalysisNewAttributeEvents.NewAttributeError });
    show('alert', strings.messages.error);
  };

  const { mutate: postNewAttribute, isLoading } = usePostNewAttribute(handleSuccess, handleError);

  const handleSubmit = (form: FormData<AttributeFormData>) => {
    const hasNoError = Object.keys(form.error).length <= 0;

    if (hasNoError) {
      dispatch(validateTokens()).then(() => {
        setHasTokenChecked(true);

        LogAnalytics.click({ tipo: TechnicalAnalysisNewAttributeEvents.NewAttribute });

        let payload;
        if (replicateAttribute) {
          payload = {
            label: form.data.label.replaceAll('/', '-'),
            value: form.data.value,
            analysisId,
            language: userInfo.language,
            authorizationToken: azureToken,
            accessToken: token,
          };
        } else {
          payload = {
            label: form.data.label.replaceAll('/', '-'),
            value: form.data.value,
            plantKey: props.plantKey,
            analysisId,
            language: userInfo.language,
            authorizationToken: azureToken,
            accessToken: token,
          };
        }

        postNewAttribute(payload);
      });
    }
  };

  const handleClose = () => {
    if (!isLoading) {
      LogAnalytics.click({ tipo: TechnicalAnalysisNewAttributeEvents.CloseNewAttribute });
      props.onClose();
    }
  };

  return (
    <Modal opened={props.opened} onClose={handleClose}>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <VSeparator />

            <H2>{strings.title}</H2>
            <VSeparator />

            <Form onSubmit={handleSubmit}>
              <Form.Field
                name='label'
                label={strings.label.attributeName}
                validators={[Validators.Required(strings.alertMandatory)]}
              >
                <TextField />
              </Form.Field>
              <VSeparator />

              <Form.Field
                name='value'
                label={strings.label.attributeValue}
                validators={[Validators.Required(strings.alertMandatory)]}
              >
                <TextAreaField disableResize />
              </Form.Field>
              <VSeparator />

              <Hbox hAlign='flex-end'>
                {isReplicable && (
                  <Hbox.Item>
                    <CheckboxField
                      id='replicate'
                      onValueChange={(_, checked) => setReplicateAttribute(checked)}
                      checked={replicateAttribute}
                    >
                      {strings.replicateAttribute}
                    </CheckboxField>
                  </Hbox.Item>
                )}
                <Hbox.Item noGrow>
                  <Button loading={isLoading} type='submit' kind='primary'>
                    {strings.add}
                  </Button>
                </Hbox.Item>
              </Hbox>
            </Form>
            <VSeparator />
          </Col>
        </Row>
      </Grid>
    </Modal>
  );
};
