import { QuoteAttributeColumn } from '@app/models/quote.model';

export function chemicalCompositionColumns(cols: QuoteAttributeColumn[], isEditing: boolean) {
  const newcols = cols.filter(column => {
    if (!isEditing && column.key !== 'reason') {
      return column;
    } else if (isEditing && column.key !== 'reason' && column.key !== 'suggestion') {
      return column;
    } else {
      return null;
    }
  });

  return newcols;
}
