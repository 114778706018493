import { UserData } from '@app/models/quote.model';

export interface EditPlant {
  analysisId: number;
  plantKey: string;
  review?: {
    isReviewed?: boolean;
    isRequestable?: boolean;
  };
  selection?: {
    isSelected?: boolean;
  };
  validation?: {
    isValidated?: boolean;
  };
  language?: string;
  authorizationToken: string;
  accessToken: string;
}

export interface GetResponsibles {
  analysisId: number;
  responsible?: UserData;
  authorizationToken: string;
  accessToken: string;
}

export interface EditResponsibles {
  analysisId: number;
  responsible?: UserData;
  action?: string;
  authorizationToken: string;
  accessToken: string;
}

export interface EditBrand {
  branding: string;
  id: number;
}

export const mapUser = (res: UserData[]): UserData[] => {
  return res.map(user => {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      profile: user.profile,
      isAdmin: user.isAdmin,
    };
  });
};
export interface EditSequence {
  analysisId: number;
  attributesSequence: any;
  language?: string;
  authorizationToken: string;
  accessToken: string;
}
