export const handleShowTechnicalAnalysisCreateOrderButton = (props: any, language: string) => {
  let canShow = false;

  const expirationDate =
    props.analysis.priceValidityDate && props.analysis.priceValidityDate.length > 0
      ? new Date(`${props.analysis.priceValidityDate}T03:00:00.000Z`).getTime()
      : null;

  if (
    props.analysis.price &&
    props.analysis.price[0] > 0 &&
    expirationDate &&
    expirationDate >= new Date().getTime() &&
    (props.analysis?.internalStatus?.key === 'FINALIZED' || props.analysis?.externalStatus?.key === 'FINALIZED') &&
    language === 'pt'
  ) {
    canShow = true;
  }

  return canShow;
};

export const handleShowQuoteCreateOrderButton = (props: any, language: string) => {
  let canShow = false;
  const expirationDate = props.item.priceValidityDate ? new Date(props.item.priceValidityDate).getTime() : null;

  if (
    props.item.hasPrice &&
    props.item.status.internal.key === 'FINALIZED' &&
    language === 'pt' &&
    expirationDate &&
    expirationDate >= new Date().getTime()
  ) {
    canShow = true;
  }

  return canShow;
};
