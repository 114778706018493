import { format } from 'date-fns';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { FileFieldWrapper, FileListWrapper } from '@app/modules/complain/quoteComplainModal.style';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { validateTokens } from '@app/modules/components/notification-center/store/thunk/validate-and-get-notification.thunk';
import { FlashMessageTargetName } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { DatePicker } from '@atomic/atm.date-picker';
import { Dropzone } from '@atomic/atm.dropzone';
import { FaIcon } from '@atomic/atm.fa-icon';
import { SelectField } from '@atomic/atm.select';
import { TextField } from '@atomic/atm.text-field';
import { Body, H2 } from '@atomic/atm.typography';
import { TD, TR, Table } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { Form, FormField, Validators } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { useEditDocument, usePostDocument } from '../hooks/document.Datasource';
import { DocumentString } from '../utils/documents.string';
import { DocumentConfirmedModal } from './documentConfirmedModal';

export interface EditedDocument {
  expirationDate: string;
  fileUrl: string;
  id: string;
  name: string;
  title: string;
  type: string;
  documentType: string;
  downloadGroup: string;
}

interface PropsForm {
  documentType?: string;
  setOpenModalDocument: (val: boolean) => void;
  setEditedDocument: (EditedDocument) => void;
  initialDocumentList: any;
  openModalDocument: boolean;
  editedDocument: Partial<EditedDocument>;
}

export const formatDate = (date: string) => {
  const newDate = date.split('/');
  return `${newDate[2]}-${newDate[1]}-${newDate[0]} 00:00:00`;
};

export const DocumentModal = (props: PropsForm) => {
  const { userInfo, azureToken, token } = useSelector((state: RootState) => state.auth);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const [files, setFiles] = React.useState<File[]>([]);
  const [fileAlert, setFileAlert] = React.useState(false);
  const [titleField, setTitleField] = React.useState<string>('');
  const [expirationDate, setExpirationDate] = React.useState<any>();
  const [downloadGroup, setDownloadGroup] = React.useState(null);
  const [showModalConfirmation, setShowModalConfirmation] = React.useState(false);
  const [confirmed, setConfirmed] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState('');
  const [action, setAction] = React.useState('add');
  const dispatch = useDispatch<AppThunkDispatch>();
  const strings = DocumentString[userInfo.language].documents;

  React.useEffect(() => {
    if (props?.editedDocument?.title) {
      setAction('edit');
      setTitleField(props.editedDocument.title);
      setDownloadGroup(props.editedDocument.downloadGroup);
      if (props.editedDocument.expirationDate) {
        setExpirationDate(new Date(formatDate(props.editedDocument.expirationDate)));
      }
    }
  }, [props.editedDocument]);

  const handleClose = () => {
    setAction('add');
    setFileAlert(false);
    setFiles([]);
    setTitleField('');
    setMessageAlert('');
    setAction('add');
    setExpirationDate(null);
    setDownloadGroup(null);
    props.setEditedDocument(null);
    props.setOpenModalDocument(false);
  };

  const { mutate: postDocument, isLoading: loadingPostDocument } = usePostDocument(
    onSuccessPostDocument,
    onErrorPostDocument,
  );

  const { mutate: editDocument, isLoading: loadingEditDocument } = useEditDocument(
    onSuccessEditDocument,
    onErrorEditDocument,
  );

  function onSuccessPostDocument() {
    show('success', strings.alerts.newDocumentSuccess);
    setFiles([]);
    setMessageAlert('');
    setAction('add');
    props.setEditedDocument(null);
    setExpirationDate(null);
    setDownloadGroup(null);
    handleClose();
  }

  function onErrorPostDocument() {
    show('alert', strings.alerts.newDocumentError);
    setFiles([]);
    setMessageAlert('');
    setAction('add');
    setExpirationDate(null);
    setDownloadGroup(null);
    handleClose();
  }

  function onSuccessEditDocument() {
    show('success', strings.alerts.editDocumentSuccess);
    setFiles([]);
    setMessageAlert('');
    setAction('add');
    props.setEditedDocument(null);
    setExpirationDate(null);
    setDownloadGroup(null);
    handleClose();
  }

  function onErrorEditDocument() {
    show('alert', strings.alerts.editDocumentError);
    setFiles([]);
    setMessageAlert('');
    setAction('add');
    setExpirationDate(null);
    setDownloadGroup(null);
    handleClose();
  }

  const isTitleExist = title => {
    let checkval = false;

    props.initialDocumentList.data.forEach(item => {
      if (item.title.toLocaleLowerCase() === title.toLocaleLowerCase()) {
        checkval = true;
      }
    });

    return checkval;
  };

  const sendDocument = async data => {
    if (Object.keys(data.error).length <= 0) {
      dispatch(validateTokens()).then(() => {
        if (props?.editedDocument?.id) {
          editDocument({
            id: props.editedDocument.id,
            file: files ? files[0] : null,
            title: data.data.title,
            expirationDate: expirationDate ? format(expirationDate, 'yyyy-MM-dd') : null,
            documentType: props.documentType,
            downloadGroup: data.data.downloadGroup,
            language: userInfo.language,
            authorizationToken: azureToken,
            accessToken: token,
          });
        } else {
          postDocument({
            file: files[0],
            title: data.data.title,
            expirationDate: expirationDate ? format(expirationDate, 'yyyy-MM-dd') : null,
            documentType: props.documentType,
            downloadGroup: data.data.downloadGroup,
            language: userInfo.language,
            authorizationToken: azureToken,
            accessToken: token,
          });
        }
      });
    }
    setConfirmed(false);
  };

  const handleSubmit = async formFields => {
    const check = isTitleExist(formFields.data.title);

    if (!check) {
      setShowModalConfirmation(false);
      sendDocument(formFields);
    } else {
      if (action === 'add') {
        setMessageAlert('Você está inserindo um documento com um nome já existente. Deseja continuar?');
        setShowModalConfirmation(true);
      } else {
        const newDate = expirationDate ? format(expirationDate, 'dd/MM/yyyy') : null;
        if (props.editedDocument?.expirationDate === newDate && files.length > 0 && props.documentType !== 'catalogs') {
          setMessageAlert('Você está editando o documento sem alterar a data de expiração. Deseja continuar? ');
          setShowModalConfirmation(true);
        } else {
          if (props.editedDocument?.title !== titleField) {
            setMessageAlert('Você está alterando o nome do documento para um nome já existente. Deseja continuar?');
            setShowModalConfirmation(true);
          } else {
            sendDocument(formFields);
            setShowModalConfirmation(false);
          }
        }
      }
      if (confirmed) {
        sendDocument(formFields);
        setShowModalConfirmation(false);
      }
    }
  };

  const handleFiles = (arqs: File[]) => {
    setFiles(arqs);
  };

  const handleRemoveFile = (name: string, size: number) => {
    const filteredFiles = files.filter(file => file.name !== name && file.size !== size);
    setFiles(filteredFiles);
  };

  const handleVerify = value => {
    setConfirmed(false);
    setTitleField(value);
  };

  return (
    <>
      <Modal
        opened={props.openModalDocument}
        onClose={showModalConfirmation ? null : handleClose}
        medium
        preventOverlayClick
      >
        <Grid fluid>
          <H2>
            {props.editedDocument?.title
              ? `${strings.modal.labelEditButton} ${props.editedDocument?.title}`
              : `Novo ${props.documentType === 'documents' ? 'Documento' : 'Catálogo'}`}
          </H2>
          <VSeparator />

          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <FormField
                  initialValue={titleField}
                  label={strings.fields.title}
                  name='title'
                  validators={[Validators.Required('Este campo é obrigatório')]}
                  onValueChange={value => handleVerify(value)}
                >
                  <TextField id='title' maxLength={255} />
                </FormField>
                <VSeparator />
              </Col>
            </Row>

            {props.documentType === 'documents' ? (
              <Row>
                <Col xs={4}>
                  <FormField
                    onValueChange={value => setExpirationDate(value)}
                    label={strings.fields.expirationDate}
                    name='expirationDate'
                    value={expirationDate}
                  >
                    <DatePicker
                      startDate={expirationDate}
                      onValueChange={setExpirationDate}
                      language='pt-br'
                      popperPlacement='top-start'
                    />
                  </FormField>
                  <VSeparator />
                </Col>
                <Col xs={8}>
                  <FormField
                    onValueChange={value => setDownloadGroup(value)}
                    initialValue={downloadGroup}
                    label={strings.fields.downloadGroup}
                    name='downloadGroup'
                    validators={[Validators.Required('Este campo é obrigatório')]}
                  >
                    <SelectField>
                      <option value=''>Selecione...</option>
                      <option value='ISO'>ISO</option>
                      <option value='Licença'>Licença</option>
                      <option value='Catálogo de Produtos'>Catálogo de Produtos</option>
                      <option value='Outros'>Outros</option>
                    </SelectField>
                  </FormField>
                  <VSeparator />
                </Col>
              </Row>
            ) : (
              <></>
            )}

            <Row>
              <Col xs={12}>
                <FileFieldWrapper fileAlert={fileAlert}>
                  <FormField
                    label='Arquivo *'
                    name='arquivo'
                    validators={
                      props.editedDocument?.title || files.length > 0
                        ? []
                        : [Validators.Required('Este campo é obrigatório')]
                    }
                  >
                    <Dropzone
                      onDropAccepted={handleFiles}
                      multiple={false}
                      limitAcceptance='.pdf'
                      label={props.editedDocument?.title !== '' ? strings.fields.editFile : strings.fields.newFile}
                    />
                  </FormField>
                  {files.length > 0 ? (
                    <FileListWrapper>
                      <Table>
                        <TR bordered key={`${files[0].name}-${files[0].size}`}>
                          <TD>
                            <Body>{files[0].name}</Body>
                          </TD>
                          <TD>
                            <Button
                              size='small'
                              kind='link'
                              onClick={() => handleRemoveFile(files[0].name, files[0].size)}
                              title='Remover da lista'
                            >
                              <FaIcon.Close size='1x' color={Color.Alert} />
                            </Button>
                          </TD>
                        </TR>
                      </Table>
                    </FileListWrapper>
                  ) : null}
                </FileFieldWrapper>
              </Col>
            </Row>

            <Hbox hAlign='flex-start'>
              <Body>* Campos obrigatórios</Body>
            </Hbox>
            <Hbox hAlign='flex-end'>
              <Hbox.Separator />

              <Hbox.Item noGrow>
                <VSeparator />
                <VSeparator />
                <VSeparator />
                <VSeparator />
                <VSeparator />
                <Button
                  disabled={showModalConfirmation}
                  type='submit'
                  loading={loadingPostDocument || loadingEditDocument}
                >
                  Enviar
                </Button>
              </Hbox.Item>
            </Hbox>
            <VSeparator />
          </Form>

          <VSeparator />
        </Grid>
      </Modal>

      <DocumentConfirmedModal
        setConfirmed={setConfirmed}
        showModalConfirmation={showModalConfirmation}
        setShowModalConfirmation={setShowModalConfirmation}
        messageAlert={messageAlert}
      />
    </>
  );
};
