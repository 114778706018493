import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { validateTokens } from '@app/modules/components/notification-center/store/thunk/validate-and-get-notification.thunk';
import { TechnicalAnalysisNewAttributeEvents } from '@app/modules/quote/quote.analytics';
import { TechnicalAnalysisContext } from '@app/modules/quote/technical-analysis/technical-analysis.context';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { FlashMessageTargetName } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { SelectField } from '@atomic/atm.select';
import { TagCheckboxField, TagCheckboxGroup } from '@atomic/atm.tag-checkbox';
import { TagRadioField } from '@atomic/atm.tag-radio';
import { TextField } from '@atomic/atm.text-field';
import { Body, H2 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { useEditAttributeValue } from '../hooks/useEditAttributeValue';
import { attributesWithOptions } from './utils/attributes.constants';
import { customerList } from './utils/customerList';
import { finalApplicationList } from './utils/finalApplicationList';

interface TechnicalAnalysisEditAttributeModalProps {
  opened: boolean;
  onClose: () => void;
  attribute: any;
}
interface AttributeFormData {
  label: string;
  value: string;
  values: any;
}
interface MultipleOptionsProps {
  attribute: any;
  defaultValue: string;
  strings: any;
}

export const MultipleOptions = (props: MultipleOptionsProps) => {
  const [showTextField, setShowTextField] = React.useState(null);

  const handleChange = val => {
    if (val === 'other' || val.includes('other')) {
      setShowTextField(true);
    } else if (val === 'back') {
      setShowTextField(false);
    }
  };

  const options =
    props.attribute.key === 'MARKET'
      ? props.attribute?.allowed
      : props.attribute.key === 'CUSTOMER'
      ? customerList
      : finalApplicationList;

  return showTextField ? (
    <Form.Field
      label={`${props.strings.label} ${props.attribute?.label}`}
      name='values'
      initialValue=''
      validators={[Validators.Required(props.strings.mandatoryField)]}
      onValueChange={e => handleChange(e)}
    >
      <TextField placeholder={props.strings.otherField} />
      <Separator small />
      <TagRadioField key='back' id='back'>
        {props.strings.back}
      </TagRadioField>
    </Form.Field>
  ) : props.attribute.key === 'MARKET' ? (
    <Form.Field
      label={`${props.strings.label} ${props.attribute?.label}`}
      name='values'
      validators={[Validators.Required(props.strings.mandatoryField)]}
      onValueChange={e => handleChange(e)}
    >
      <SelectField>
        <option value=''>{props.strings.blankOption}</option>
        {options.map(el => (
          <option value={el.key ? el.key.trim() : el.label} key={el.key ? el.key : el.label}>
            {el.label ? el.label.trim() : el}
          </option>
        ))}
      </SelectField>
    </Form.Field>
  ) : props.attribute.key === 'FINAL_APPLICATION' ? (
    <Form.Field
      label={`${props.strings.label} ${props.attribute?.label}`}
      name='values'
      validators={[Validators.Required(props.strings.mandatoryField)]}
      onValueChange={e => handleChange(e)}
    >
      <SelectField>
        <option value=''>{props.strings.blankOption}</option>
        {options.map(el =>
          el.options ? (
            <optgroup label={el.label}>
              {el.options.map(op => (
                <option value={`${el.label} - ${op.label.trim()}`} key={op.label}>
                  {`${el.label} - ${op.label.trim()}`}
                </option>
              ))}
            </optgroup>
          ) : (
            <option value={el.label} key={el.label}>
              {el.label.trim()}
            </option>
          ),
        )}
        <option value='other' key='other-f'>
          {props.strings.otherOption}
        </option>
      </SelectField>
    </Form.Field>
  ) : (
    <>
      <Body bold={600} color={Color.GrayXDark}>{`${props.strings.label} ${props.attribute?.label}`}</Body>
      <Separator small />
      <TagCheckboxGroup>
        <Form.Field
          name='values'
          validators={[Validators.Required(props.strings.mandatoryField)]}
          onValueChange={e => handleChange(e)}
        >
          {options.map((option, ind) => (
            <TagCheckboxField
              key={option + ind}
              id={option}
              initialChecked={props.defaultValue.toLocaleUpperCase().includes(option.label ? option.label : option)}
            >
              {option.label ? option.label : option}
            </TagCheckboxField>
          ))}
          <TagCheckboxField key='outro-c' id='other'>
            {props.strings.otherOption}
          </TagCheckboxField>
        </Form.Field>
      </TagCheckboxGroup>
    </>
  );
};

export const TechnicalAnalysisEditAttributeModal: React.FC<TechnicalAnalysisEditAttributeModalProps> = props => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { analysisId, setHasTokenChecked } = React.useContext(TechnicalAnalysisContext);
  const { userInfo, azureToken, token } = useSelector((state: RootState) => state.auth);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const strings = TechnicalAnalysisStrings[userInfo.language].attributes.editAttribute;

  const handleSuccess = () => {
    show('success', strings.editSuccess);
    props.onClose();
  };

  const handleError = () => {
    show('alert', strings.messages.error);
  };

  const { mutate: editAttribute, isLoading } = useEditAttributeValue(handleSuccess, handleError);

  const handleSubmit = (form: FormData<AttributeFormData>) => {
    const hasNoError = !(Object.keys(form.error).length > 0);
    if (hasNoError) {
      dispatch(validateTokens()).then(() => {
        setHasTokenChecked(true);
        LogAnalytics.click({ tipo: TechnicalAnalysisNewAttributeEvents.EditAttribute });

        let valueForm;
        if (props.attribute?.key === 'MARKET') {
          valueForm = props.attribute.allowed.find(at => at.key === form.data.values);
        } else if (props.attribute?.key === 'AMOUNT') {
          valueForm = form.data.label;
        } else if (props.attribute?.key === 'CUSTOMER') {
          valueForm = {
            key: typeof form.data.values === 'string' ? form.data.values : form.data.values.join(', '),
            label: typeof form.data.values === 'string' ? form.data.values : form.data.values.join(', '),
          };
        } else if (props.attribute?.key === 'FINAL_APPLICATION') {
          valueForm = { key: form.data.values, label: form.data.values };
        } else {
          valueForm = { key: form.data.label, label: form.data.label };
        }

        const payload = {
          key: props.attribute.key,
          value: valueForm,
          analysisId,
          language: userInfo.language,
          authorizationToken: azureToken,
          accessToken: token,
        };

        editAttribute(payload);
      });
    }
  };

  const handleClose = () => {
    if (!isLoading) {
      props.onClose();
    }
  };

  const defaultValue = props.attribute?.attributeValue?.label
    ? props.attribute?.attributeValue?.label
    : props.attribute?.attributeValue;

  return (
    <Modal opened={props.opened} onClose={handleClose} small>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <H2>{strings.title}</H2>
            <VSeparator />
            <Form onSubmit={handleSubmit}>
              {attributesWithOptions.includes(props.attribute?.key) ? (
                <MultipleOptions strings={strings} attribute={props.attribute} defaultValue={defaultValue} />
              ) : (
                <Form.Field
                  name='label'
                  label={`${strings.label} ${props.attribute?.label}`}
                  initialValue={defaultValue}
                  validators={[Validators.Required(strings.mandatoryField)]}
                >
                  <TextField />
                </Form.Field>
              )}

              <VSeparator />

              <Hbox hAlign='flex-end'>
                <Hbox.Item noGrow>
                  <Button loading={isLoading} type='submit' kind='primary'>
                    {strings.save}
                  </Button>
                </Hbox.Item>
              </Hbox>
            </Form>
            <VSeparator />
          </Col>
        </Row>
      </Grid>
    </Modal>
  );
};
