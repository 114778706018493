/* eslint-disable complexity */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { FullAnalysisData, Stages } from '@app/models/quote.model';
import { quoteIsInternalUserSelector } from '@app/modules/auth/store/auth.selectores';
import { validateTokens } from '@app/modules/components/notification-center/store/thunk/validate-and-get-notification.thunk';
import { useLocalStorageState } from '@app/utils/local-storage.hooks';
import { Badge } from '@atomic/atm.badge';
import { Button } from '@atomic/atm.button';
import { CustomizedTooltip } from '@atomic/atm.customized-tooltip';
import { Divisor } from '@atomic/atm.divisor';
import { FaIcon } from '@atomic/atm.fa-icon';
import { InternalBadge } from '@atomic/atm.internal-badge/internal-badge.component';
import { H1, H1Sub } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { useEventListener } from '@atomic/obj.hooks/use-event-listener.hook';
import { LoadingState } from '@atomic/obj.loading-state';
import { AnalysisAssetsAdviceModal } from './components/analysisAssetsAdviceModal';
import { useDownloadAsset } from './hooks/useDownloadAsset';
import { useDownloadPriceQuotation } from './hooks/useDownloadPriceQuotation';
import { DuplicateQuotationButton } from './options/duplicateQuotationButton';
import { EditQuotationButton } from './options/editQuotationButton';
import { GeneratePdfButton } from './options/generatePdfButton';
import { HistoryButton } from './options/historyButton';
import { LogButton } from './options/logButton';
import { NewQuotationButton } from './options/newQuotationButton';
import { SendToBaggButton } from './options/sendToBaggButton';
import { SkuButton } from './options/skuButton';
import { TechnicalAnalysisStatusList } from './status';
import { TechnicalAnalysisContext } from './technical-analysis.context';
import { TechnicalAnalysisStrings } from './technical-analysis.string';
import { NewProductEppWrapper, TechnicalAnalysisOptionsBoxStyled } from './technical-analysis.style';

interface TechnicalAnalysisHeaderProps {
  analysis: FullAnalysisData;
  analysisId: number;
  totalAssets: number;
  totalSimilarMaterial: number;
  blockProcess: boolean;
  onStatusClick: (stage: Stages) => void;
  onAssetsClick: () => void;
  onRefetchClick: () => void;
  onClientSubmit: (key: string, action: string) => void;
  onAnalysisEditClick: () => void;
  onAnalysisDuplicateClick: () => void;
  onSimilarMaterialsModal: () => void;
  onHandleSendToBagg: () => void;
  tooltipApqpStatus: boolean;
  actionsLoading: boolean;
}

const SIMILAR_MATERIALS_TOOLTIP = 'similarMaterialsTooltip';

export const TechnicalAnalysisHeader: React.FC<TechnicalAnalysisHeaderProps> = props => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { loadingStatusList, newAnalysis } = React.useContext(TechnicalAnalysisContext);
  const { userInfo, azureToken, token } = useSelector((state: RootState) => state.auth);
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const strings = TechnicalAnalysisStrings[userInfo.language];
  const stringsTooltipApqp = TechnicalAnalysisStrings[userInfo.language].tooltipApqp;
  const [tooltipVisible, setTooltipVisible] = useLocalStorageState(SIMILAR_MATERIALS_TOOLTIP, true);
  const [tooltipApqpVisible, setTooltipApqpVisible] = React.useState<boolean>(props.tooltipApqpStatus);
  const [assetsAdviceModalOpened, setAssetsAdviceModalOpened] = React.useState<boolean>(false);
  const menuOptionsRef = React.useRef(null);
  const [showOptionsList, setShowOptionsList] = React.useState(false);

  React.useEffect(() => {
    setTooltipApqpVisible(props.tooltipApqpStatus);
    if (props.analysis?.specification?.length > 0 || props.totalAssets > 0) {
      setAssetsAdviceModalOpened(true);
    }
  }, [props.tooltipApqpStatus]);

  React.useEffect(() => {
    if (props.analysis?.specification?.length > 0 || props.totalAssets > 0) {
      setAssetsAdviceModalOpened(true);
    }
  }, [props.totalAssets]);

  const { mutate: handleDownload, isLoading: getAssetLoading } = useDownloadAsset();

  const { mutate: handleDownloadPrice } = useDownloadPriceQuotation();

  const handleAssetsAdviceModalClose = () => {
    setAssetsAdviceModalOpened(false);
  };

  const handleMouseDownOptions = (event: MouseEvent) => {
    if (showOptionsList && menuOptionsRef && !menuOptionsRef.current.contains(event.target)) {
      setShowOptionsList(false);
    }
  };
  useEventListener('mousedown', handleMouseDownOptions);

  const handleOpenOptions = () => {
    setShowOptionsList(true);
  };

  const handleSendToBaggCall = () => {
    setShowOptionsList(false);
    props.onHandleSendToBagg();
  };

  //const loadingStatusButton = loadingEditPlant || loadingStatusList || props.actionsLoading;

  return (
    <Hbox vAlign='center'>
      <Hbox.Item>
        <Hbox vAlign='flex-end'>
          <H1>{newAnalysis?.type ? newAnalysis.type : strings.header.title}</H1>
          {props?.analysis?.newProductEpp && quoteIsInternalUser && (
            <NewProductEppWrapper>
              <Badge color='purple'>{strings.header.newProductEpp}</Badge>
            </NewProductEppWrapper>
          )}
        </Hbox>

        <H1Sub>{newAnalysis?.title}</H1Sub>
      </Hbox.Item>

      <Hbox vAlign='center' hAlign='flex-end' colWrap>
        {quoteIsInternalUser && (
          <>
            <Hbox.Item noGrow vAlign='center' mb>
              <CustomizedTooltip
                title={strings.header.tooltip.content.title}
                description={strings.header.tooltip.content.description}
                visible={tooltipVisible}
                onClick={() => setTooltipVisible(false)}
                onClose={() => setTooltipVisible(false)}
                customHeight={40}
                hasOffset
              >
                <Button
                  kind='secondary'
                  title={strings.header.tooltip.similar}
                  onClick={props.onSimilarMaterialsModal}
                  disabled={props.blockProcess}
                >
                  <Hbox>
                    <Hbox.Item noGrow vAlign='center'>
                      <FaIcon.SlidersH />
                    </Hbox.Item>
                    <Hbox.Separator />
                    <Hbox.Item nowrap>
                      {strings.header.tooltip.similar} ({props.totalSimilarMaterial})
                    </Hbox.Item>
                  </Hbox>
                </Button>
              </CustomizedTooltip>
            </Hbox.Item>
            <Hbox.Separator />
          </>
        )}

        <GeneratePdfButton
          analysis={props.analysis}
          onEditClick={props.onAnalysisEditClick}
          onDuplicateClick={props.onAnalysisDuplicateClick}
          disabled={props.blockProcess}
        />

        {props.analysis?.specification?.length > 0 && (
          <Hbox mb>
            <Hbox.Separator />
            <Hbox.Item noGrow vAlign='center'>
              <InternalBadge notification='1'>
                <Button
                  title={strings.header.tooltip.norm}
                  kind='secondary'
                  onClick={() =>
                    dispatch(validateTokens()).then(() => {
                      handleDownload({
                        filename: props.analysis.specification[0].name,
                        url: props.analysis.specification[0].url,
                        type: props.analysis.specification[0].type,
                        language: userInfo.language,
                        authorizationToken: azureToken,
                        accessToken: token,
                      });
                    })
                  }
                  disabled={props.blockProcess || getAssetLoading}
                >
                  <Hbox>
                    <Hbox.Item noGrow vAlign='center'>
                      <FaIcon.PDF />
                    </Hbox.Item>
                    <Hbox.Separator />
                    <Hbox.Item nowrap>{strings.header.tooltip.norm}</Hbox.Item>
                  </Hbox>
                </Button>
              </InternalBadge>
            </Hbox.Item>
          </Hbox>
        )}
        {quoteIsInternalUser && props.analysis?.internalStatus.key === 'VALIDATION_SELLER' && (
          <AnalysisAssetsAdviceModal onModalClose={handleAssetsAdviceModalClose} opened={assetsAdviceModalOpened} />
        )}

        <Hbox mb>
          <Hbox.Separator />
          <Hbox.Item noGrow vAlign='center'>
            <CustomizedTooltip
              title='Anexos'
              description={stringsTooltipApqp.description}
              visible={tooltipApqpVisible}
              onClick={() => setTooltipApqpVisible(false)}
              onClose={() => setTooltipApqpVisible(false)}
              customHeight={70}
              hasOffset
            >
              <InternalBadge notification={`${props.totalAssets}`}>
                <Button
                  title={strings.header.tooltip.assets}
                  kind='secondary'
                  onClick={props.onAssetsClick}
                  disabled={props.blockProcess}
                >
                  <Hbox>
                    <Hbox.Item noGrow vAlign='center'>
                      <FaIcon.Attachment size='1x' />
                    </Hbox.Item>
                    <Hbox.Separator />
                    <Hbox.Item nowrap>{strings.header.tooltip.assets}</Hbox.Item>
                  </Hbox>
                </Button>
              </InternalBadge>
            </CustomizedTooltip>
          </Hbox.Item>
        </Hbox>

        {!!props.analysis?.price && props.analysis?.price[0] !== null && (
          <Hbox mb>
            <Hbox.Separator />
            <Button
              kind='secondary'
              title={strings.header.tooltip.download}
              onClick={() =>
                dispatch(validateTokens()).then(() => {
                  handleDownloadPrice({
                    quoteId: `${props.analysisId}`,
                    language: userInfo.language,
                    authorizationToken: azureToken,
                    accessToken: token,
                  });
                })
              }
              disabled={props.blockProcess}
            >
              <Hbox>
                <Hbox.Item noGrow vAlign='center'>
                  <FaIcon.Custom icon='download' />
                </Hbox.Item>
                <Hbox.Separator />
                <Hbox.Item nowrap>{strings.header.tooltip.download}</Hbox.Item>
              </Hbox>
            </Button>
          </Hbox>
        )}

        {!quoteIsInternalUser &&
        props.analysis?.externalStatus?.key !== 'CANCELED' &&
        (props.analysis?.cancelRequestAllowed ||
          !props.analysis?.cancelRequestReason ||
          props.analysis?.externalStatus?.key === 'PENDING' ||
          props.analysis?.externalStatus?.key === 'CLIENT_EDITION') ? (
          <Hbox mb>
            <Hbox.Separator />
            <Button
              kind='secondary'
              title={strings.header.tooltip.cancelQuotation}
              onClick={() => props.onClientSubmit('CANCELED', 'canceledByClient')}
              loading={props.actionsLoading && props.analysis?.cancelRequestAllowed}
              disabled={props.blockProcess}
            >
              <Hbox>
                <Hbox.Item noGrow vAlign='center'>
                  <FaIcon.Close />
                </Hbox.Item>
                <Hbox.Separator />
                <Hbox.Item>{strings.status.cancelModal.btnHeader}</Hbox.Item>
              </Hbox>
            </Button>
          </Hbox>
        ) : (
          <></>
        )}

        <Hbox.Separator />

        <Hbox.Item noGrow vAlign='center' mb>
          <Button kind='secondary' onClick={handleOpenOptions} disabled={props.blockProcess}>
            {strings.components.about.optionsButton} <Hbox.Separator />
            <FaIcon.ChevronDown size='1x' />
          </Button>
          {showOptionsList ? (
            <TechnicalAnalysisOptionsBoxStyled show={showOptionsList} ref={menuOptionsRef}>
              <SkuButton analysis={props.analysis} analysisId={props.analysisId} />
              <HistoryButton analysis={props.analysis} setShowOptionsList={setShowOptionsList} />
              <LogButton analysisId={props.analysisId} />
              <Divisor color={Color.Gray} />
              <DuplicateQuotationButton
                analysis={props.analysis}
                onAnalysisDuplicateClick={props.onAnalysisDuplicateClick}
              />
              <EditQuotationButton analysis={props.analysis} onAnalysisEditClick={props.onAnalysisEditClick} />
              <NewQuotationButton />
              <SendToBaggButton analysis={props.analysis} onSendToBaggCall={handleSendToBaggCall} />
            </TechnicalAnalysisOptionsBoxStyled>
          ) : null}
        </Hbox.Item>

        <Hbox.Separator />
        <Hbox.Item noGrow vAlign='center' mb>
          <LoadingState
            loading={
              (props.actionsLoading && !props.analysis?.cancelRequestAllowed) || props.blockProcess || loadingStatusList
            }
          >
            <TechnicalAnalysisStatusList
              data={props.analysis}
              onStatusClick={props.onStatusClick}
              onClientSubmit={props.onClientSubmit}
              totalSimilarMaterial={props.totalSimilarMaterial}
            />
          </LoadingState>
        </Hbox.Item>
      </Hbox>
    </Hbox>
  );
};
