import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { DescriptiveAnalysisData } from '@app/models/quote.model';
import { Badge } from '@atomic/atm.badge';
import { Body, H3 } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Color } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { QuoteStrings } from '../../quote.string';
import { TechnicalAnalysisStrings } from '../technical-analysis.string';
import { DescriptiveAnalysisTableStyled, DescriptiveAnalysisTextStyled } from './descriptiveAnalysis.component.style';

interface DescriptiveAnalysisProps {
  data: DescriptiveAnalysisData[];
}

export const DescriptiveAnalysisChemicalComposition: React.FC<DescriptiveAnalysisProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const statusBadge = QuoteStrings[userInfo.language].constants.statusAttributes;
  const strings = TechnicalAnalysisStrings[userInfo.language].attributes.attributeCell.descriptiveAnalysis;

  const elementRows = props.data.filter(item => !item.accepted);

  return (
    elementRows &&
    elementRows.length > 0 && (
      <>
        <VSeparator />
        <H3>{strings.title}</H3>
        <VSeparator small />

        <DescriptiveAnalysisTableStyled>
          <Table collapse>
            <TR bordered sticky>
              <TD color={Color.Secondary}>
                <Body>{strings.element}</Body>
              </TD>
              <TD color={Color.Secondary}>
                <Body>{strings.amplitude}</Body>
              </TD>
              <TD color={Color.Secondary}>
                <Body>{strings.residualMin}</Body>
              </TD>
              <TD color={Color.Secondary}>
                <Body>{strings.residualMax}</Body>
              </TD>
            </TR>
            {elementRows?.length > 0 &&
              elementRows.map((row, index3) => (
                <TR key={`tr-${index3}`} bordered sticky>
                  <TD color={Color.White}>
                    <Body>{row.element}</Body>
                  </TD>
                  {row.descriptiveAnalysis?.elementNotFound !== null &&
                  !row.descriptiveAnalysis?.elementNotFound?.accepted ? (
                    <TD color={Color.White} colSpan={3}>
                      <Body>
                        <DescriptiveAnalysisTextStyled>
                          <Badge color='warning'>{statusBadge.derogate}</Badge>
                          {row.descriptiveAnalysis.elementNotFound.derogate.reason}
                        </DescriptiveAnalysisTextStyled>
                      </Body>
                    </TD>
                  ) : row.descriptiveAnalysis?.informative && !row.descriptiveAnalysis?.informative?.accepted ? (
                    <TD color={Color.White} colSpan={3}>
                      <Body>
                        <DescriptiveAnalysisTextStyled>
                          <Badge color='warning'>{statusBadge.derogate}</Badge>
                          {row.descriptiveAnalysis.informative.derogate.reason}
                        </DescriptiveAnalysisTextStyled>
                      </Body>
                    </TD>
                  ) : row.descriptiveAnalysis?.limit && !row.descriptiveAnalysis?.limit?.accepted ? (
                    <TD color={Color.White} colSpan={3}>
                      <Body>
                        <DescriptiveAnalysisTextStyled>
                          <Badge color='warning'>{statusBadge.derogate}</Badge>
                          {row.descriptiveAnalysis.limit.derogate.reason}
                        </DescriptiveAnalysisTextStyled>
                      </Body>
                    </TD>
                  ) : (
                    <>
                      <TD color={Color.White}>
                        {row.descriptiveAnalysis?.amplitude &&
                          (row.descriptiveAnalysis?.amplitude?.accepted ? (
                            <Body>
                              <Badge color='success'>{statusBadge.accepted}</Badge>
                            </Body>
                          ) : (
                            <Body>
                              <DescriptiveAnalysisTextStyled>
                                <Badge color='warning'>{statusBadge.derogate}</Badge>
                                {row.descriptiveAnalysis.amplitude.derogate.reason}
                                <br />
                                <strong>{strings.suggestion}</strong>{' '}
                                {row.descriptiveAnalysis.amplitude.derogate.suggestion.join()}
                              </DescriptiveAnalysisTextStyled>
                            </Body>
                          ))}
                      </TD>
                      <TD color={Color.White}>
                        {row.descriptiveAnalysis?.residualMin &&
                          (row.descriptiveAnalysis?.residualMin?.accepted ? (
                            <Body>
                              <Badge color='success'>{statusBadge.accepted}</Badge>
                            </Body>
                          ) : (
                            <Body>
                              <DescriptiveAnalysisTextStyled>
                                <Badge color='warning'>{statusBadge.derogate}</Badge>
                                {row.descriptiveAnalysis.residualMin.derogate.reason}
                                <br />
                                <strong>{strings.suggestion}</strong>{' '}
                                {row.descriptiveAnalysis.residualMin.derogate.suggestion.join()}
                              </DescriptiveAnalysisTextStyled>
                            </Body>
                          ))}
                      </TD>
                      <TD color={Color.White}>
                        {row.descriptiveAnalysis?.residualMax &&
                          (row.descriptiveAnalysis?.residualMax?.accepted ? (
                            <Body>
                              <Badge color='success'>{statusBadge.accepted}</Badge>
                            </Body>
                          ) : (
                            <Body>
                              <DescriptiveAnalysisTextStyled>
                                <Badge color='warning'>{statusBadge.derogate}</Badge>
                                {row.descriptiveAnalysis.residualMax.derogate.reason}
                                <br />
                                <strong>{strings.suggestion}</strong>{' '}
                                {row.descriptiveAnalysis.residualMax.derogate.suggestion.join()}
                              </DescriptiveAnalysisTextStyled>
                            </Body>
                          ))}
                      </TD>
                    </>
                  )}
                </TR>
              ))}
          </Table>
        </DescriptiveAnalysisTableStyled>

        <VSeparator />
      </>
    )
  );
};
