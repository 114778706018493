/* eslint-disable complexity */
import * as React from 'react';
import { Allowed } from '@app/data/http/quote.dto';
import { FullAnalysisData, InversedAttributeStatus, QuoteAttributes } from '@app/models/quote.model';
import { SimpleToolTip } from '@app/modules/components/SimpleToolTip';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { Badge } from '@atomic/atm.badge';
import { Button } from '@atomic/atm.button';
import { Cell } from '@atomic/atm.cell';
import { Divisor } from '@atomic/atm.divisor';
import { Dropzone } from '@atomic/atm.dropzone';
import { FaIcon } from '@atomic/atm.fa-icon';
import { TagRadioField } from '@atomic/atm.tag-radio';
import { TextField } from '@atomic/atm.text-field';
import { Body, H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { Col, VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { AttributeFormSuggestion } from './attribute-cell.form-suggestion';
import {
  AddImageContainer,
  AttributeCellFormWrapperStyled,
  DropzoneWrapperStyled,
  UploadedImages,
} from './attribute-cell.form.style';
import { PreviewImage } from './attribute-preview-image';

interface AttributeCellFormProps {
  analysis: FullAnalysisData;
  title: string;
  editedStatus: string;
  isEditing: boolean;
  onCancelClick: () => void;
  onSubmit: (data: AttributeCellFormData) => void;
  attribute: QuoteAttributes;
  status: string;
  onAcceptanceClick: (value: string) => void;
  images: any[];
  provImages: any[];
  derogateOptionsList: Allowed[];
  language: string;
  derogateImagesLoading?: boolean;
  handleImages: (files: File[]) => void;
  handleDeleteImage: (file: File | number | string) => void;
  handleDeleteProvImage: (file: File | number | string) => void;
  openZoom: (value: number) => void;
  tooltipNewDerogateImages: boolean;
  setTooltipNewDerogateImages: (value: boolean) => void;
  setEditedStatus: (value: string) => void;
}

export interface AttributeCellFormData {
  images?: File[];
  reason: string;
  suggestion: string;
  min: number;
  max: number;
  handlePost: (files: File[]) => void;
}

export const AttributeCellForm: React.FC<AttributeCellFormProps> = props => {
  const strings = TechnicalAnalysisStrings[props.language].attributes.attributeCell;
  const attributeDerogateBadges = TechnicalAnalysisStrings[props.language].attributeDerogateBadges;
  const canEditDerogate = props.attribute?.derogate?.permissions?.isEditable;

  const suggestionStatus = props.attribute?.derogate?.suggestionStatus;

  const handleSubmit = (form: FormData<AttributeCellFormData>) => {
    if (!(Object.keys(form.error).length > 0)) {
      props.onSubmit(form.data);
    }
  };

  const handleZoom = value => {
    props.openZoom(value);
  };

  const handleAccept = (value: string) => {
    props.setEditedStatus('');
    props.onAcceptanceClick(value);
  };

  const formatReason = (value: string) => {
    return (
      <Hbox.Item>
        <Body fullWidth={true}>
          <strong>{value.split('||')[0]}</strong>
        </Body>
        <Body fullWidth={true}>{value.split('||')[1]}</Body>
      </Hbox.Item>
    );
  };

  return (
    <LoadingState loading={props.derogateImagesLoading}>
      {props.isEditing ? (
        <Form onSubmit={handleSubmit}>
          <AttributeCellFormWrapperStyled>
            {props.editedStatus === InversedAttributeStatus.derogate && (
              <>
                <Hbox.Item>
                  <Form.Field
                    validators={[Validators.Required(strings.form.validator.alertMandatory)]}
                    label={strings.form.reason}
                    name='reason'
                    initialValue={props.attribute?.derogate?.reason}
                  >
                    <TextField />
                  </Form.Field>
                  <VSeparator />
                  {props?.attribute && (
                    <AttributeFormSuggestion
                      attribute={props.attribute}
                      title={props.title}
                      derogateOptionsList={props.derogateOptionsList}
                      language={props.language}
                    />
                  )}

                  <VSeparator />
                </Hbox.Item>
                <Hbox.Item>
                  <UploadedImages>
                    {props.provImages?.length > 0 || props.images.length > 0 ? (
                      <>
                        {props.images.length > 0 &&
                          props.images.map((img, index) => (
                            <PreviewImage
                              key={`img-${index}`}
                              position={index}
                              idImg={img.id}
                              language={props.language}
                              img={img.base64}
                              handleDeleteImage={props.handleDeleteImage}
                              handleZoom={handleZoom}
                              readOnly={!props.attribute?.permissions.isEditable}
                            />
                          ))}
                        {props.provImages.length > 0 &&
                          props.provImages.map((img, index) => (
                            <PreviewImage
                              key={`imgPrev-${index}`}
                              position={index}
                              language={props.language}
                              img={img}
                              handleDeleteImage={props.handleDeleteProvImage}
                              handleZoom={handleZoom}
                            />
                          ))}
                        {props.provImages.length + props.images?.length < 3 && (
                          <AddImageContainer title={strings.image.addImage}>
                            <DropzoneWrapperStyled>
                              <Dropzone
                                label={strings.image.uploadClickButton}
                                onDropAccepted={props.handleImages}
                                limitAcceptance='image/*'
                                multiple={false}
                              />
                            </DropzoneWrapperStyled>
                            <Button>
                              <FaIcon.Plus size='1x' />
                            </Button>
                          </AddImageContainer>
                        )}
                      </>
                    ) : (
                      <DropzoneWrapperStyled>
                        <Dropzone
                          label={strings.image.uploadClickButton}
                          onDropAccepted={props.handleImages}
                          limitAcceptance='image/*'
                          multiple={true}
                        />
                      </DropzoneWrapperStyled>
                    )}
                  </UploadedImages>
                </Hbox.Item>
              </>
            )}
          </AttributeCellFormWrapperStyled>
          <VSeparator />
          <Hbox hAlign='flex-end'>
            <Hbox.Item noGrow>
              <Button onClick={props.onCancelClick} kind='alertSecondary'>
                {strings.cancel}
              </Button>
            </Hbox.Item>
            <Hbox.Separator />

            <Hbox.Item noGrow>
              <SimpleToolTip
                visible={props.tooltipNewDerogateImages}
                description={'Você precisa salvar a derroga para enviar as imagens novas.'}
                align='right'
                alignArrow='right'
                customVerticalAlign={50}
                onClose={() => props.setTooltipNewDerogateImages(false)}
                btnLabel='Fechar'
                onClick={() => props.setTooltipNewDerogateImages(false)}
              />
              <Button type='submit' kind='secondary'>
                {strings.save}
              </Button>
            </Hbox.Item>
          </Hbox>
          <VSeparator />

          <Divisor />
          <VSeparator />
        </Form>
      ) : (
        props.status === InversedAttributeStatus.derogate && (
          <Form onSubmit={handleSubmit}>
            <Cell>
              <Hbox noGrow>
                <Col xs={props.provImages?.length > 0 || props.images.length > 0 ? 6 : 12}>
                  <Hbox>
                    <Hbox.Item noGrow>
                      <H3>{strings.derogationTitle}</H3>
                    </Hbox.Item>
                    <Hbox.Separator />
                    {!!TechnicalAnalysisStrings[props.language].attributeDerogateBadges[
                      props.attribute?.derogate?.suggestionStatus
                    ] && (
                      <Hbox.Item noGrow>
                        <Badge color={attributeDerogateBadges[props.attribute?.derogate?.suggestionStatus]?.color}>
                          {attributeDerogateBadges[props.attribute?.derogate?.suggestionStatus]?.label}
                        </Badge>
                      </Hbox.Item>
                    )}
                  </Hbox>
                  <VSeparator />

                  {formatReason(strings.derogationReason(props.attribute?.derogate?.reason))}

                  <VSeparator />

                  {formatReason(
                    strings.derogationSuggestion(
                      props.title,
                      props.attribute?.derogate?.suggestion.text
                        ? props.attribute?.derogate?.suggestion.text
                        : props.attribute?.derogate?.suggestion,
                    ),
                  )}

                  <VSeparator />
                </Col>
                <Col xs={6}>
                  <UploadedImages>
                    {props.images.length > 0 &&
                      props.images.map((img, index) => (
                        <PreviewImage
                          key={`img-${index}`}
                          position={index}
                          idImg={img.id}
                          language={props.language}
                          img={img.base64}
                          handleDeleteImage={props.handleDeleteImage}
                          handleZoom={handleZoom}
                          readOnly={true}
                        />
                      ))}
                    {props.provImages?.length > 0 &&
                      props.provImages.map((img, index) => (
                        <PreviewImage
                          key={`imgPrev-${index}`}
                          position={index}
                          language={props.language}
                          img={img}
                          handleDeleteImage={props.handleDeleteProvImage}
                          handleZoom={handleZoom}
                          readOnly={true}
                        />
                      ))}
                  </UploadedImages>
                </Col>
              </Hbox>
            </Cell>
            <VSeparator />

            {canEditDerogate && (
              <>
                <Hbox hAlign='flex-end'>
                  <Form.Field name='status' initialValue={suggestionStatus}>
                    <TagRadioField
                      onClick={() => handleAccept('rejected')}
                      type={suggestionStatus === 'rejected' ? 'alert' : null}
                      id={'rejected'}
                      disabled={suggestionStatus === 'rejected'}
                    >
                      {suggestionStatus === 'rejected' ? strings.rejected : strings.reject}
                    </TagRadioField>
                    <TagRadioField
                      onClick={() => handleAccept('accepted')}
                      type={suggestionStatus === 'accepted' ? 'accepted' : null}
                      id={'accepted'}
                      disabled={suggestionStatus === 'accepted'}
                    >
                      {suggestionStatus === 'accepted' ? strings.accepted : strings.accept}
                    </TagRadioField>
                  </Form.Field>
                </Hbox>
                <VSeparator />
              </>
            )}

            <Divisor />
            <VSeparator />
          </Form>
        )
      )}
    </LoadingState>
  );
};
