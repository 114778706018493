import { GridReadyEvent } from 'ag-grid-community';
import React, { MutableRefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { FlashWrapperStatic } from '@app/modules/components/flash-wrapper-static/flash-wrapper-static.component';
import { GridOpenMaritimeRowFilter } from '@app/modules/order/grid-filters/grid-open-maritime-row-filter.component';
import { OrderTabGrid } from '@app/modules/order/order-tab-grid.component';
import { Grid } from '@atomic/obj.grid/grid.component';
import { OrderTabsActionsME } from '../../order-tabs-actions-ME.component';
import { OrderMEStatusMarRenderer } from './cell-renderer/order-status/order-me-status-mar-renderer.component';
import { tabName } from './order-tab-maritime-open.constants';
import { filterSelector } from './order-tab-maritime-open.selectors';
import { performRequest, setFilter, setFilteredRows } from './order-tab-maritime-open.store';
import { IOrderTabOpenMaritimeStrings, Strings } from './order-tab-maritime-open.string';

interface OrderTabOpenMaritimeComponentProps {
  gridEvent: MutableRefObject<GridReadyEvent | null>;
}

export const OrderTabOpenMaritimeComponent: React.FC<OrderTabOpenMaritimeComponentProps> = ({ gridEvent }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IOrderTabOpenMaritimeStrings = Strings[userInfo.language];
  const filter = useSelector(filterSelector);
  const { data, loading, error } = useSelector((state: RootState) => state.orderTabOpenMaritime);
  const dispatch = useDispatch<AppThunkDispatch>();

  return (
    <>
      <FlashWrapperStatic opened={!!error} type='alert' content={strings.error} />
      <Grid fluid>
        <OrderTabsActionsME
          tabName={tabName}
          refresh={() => dispatch(performRequest())}
          gridEvent={gridEvent}
          filename={strings.filename}
        >
          <GridOpenMaritimeRowFilter value={filter} onTagChanged={value => dispatch(setFilter(value))} />
        </OrderTabsActionsME>
        <OrderTabGrid
          gridEvent={gridEvent}
          performRequest={performRequest}
          setFilteredRows={setFilteredRows}
          strings={strings}
          data={data}
          loading={loading}
          error={error}
          tabName={tabName}
          frameworkComponents={{
            orderMEStatusMarRenderer: OrderMEStatusMarRenderer,
          }}
        />
      </Grid>
    </>
  );
};
