import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { endpoint } from '@app/data/http';
import { CreateOrderParams } from '@app/models/analysis.model';

export const postOrder = async (params: CreateOrderParams) => {
  const defaultHeaders = {
    ...Container.get(HttpRequestBuilder).config.headers,
    language: params.language,
    'Access-Token': params.accessToken,
    Authorization: `Bearer ${params.authorizationToken}`,
  };

  const createOrderParamsDTO = {
    customerName: params.customerName,
    customerEmail: params.customerEmail,
    corpGroup: params.corpGroup,
    companyName: params.companyName,
    requestOrigin: params.requestOrigin,
    orderID: params.orderID,
    codeMaterial: params.codeMaterial,
    customerId: params.customerId,
    customerCnpj: params.customerCnpj,
    implantation: params.implantation,
  };

  const body = createOrderParamsDTO;
  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.CREATE_ORDER}`;

  await axios.post(url, body, { headers: defaultHeaders });
};

export const usePostOrder = (onSuccess, onError) => {
  return useMutation(postOrder, {
    onSuccess,
    onError,
  });
};
