import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpointCrm, IsaHttpRequestBuilder } from '@app/data/http';
import { CrmPlantsResponse, GetCrmParams, mapPlantsData } from '@app/data/http/crm.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export async function getPlants(params?: GetCrmParams): Promise<CrmPlantsResponse> {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    language: user.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };
  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpointCrm.CRM}/${params.id}${
    endpointCrm.PLANTS
  }`;

  const { data, status } = await axios.get(url, { headers: defaultHeaders });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
}

export function useGetPlants(params: GetCrmParams, onError?) {
  return useQuery(['getPlants', params], () => getPlants(params), {
    onError,
    enabled: params.enabled,
    select: data => {
      return mapPlantsData(data.data);
    },
    refetchOnWindowFocus: false,
  });
}
