import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { GetAvailableStatusParams } from '@app/data/http/quote-status-params.dto';
import { AvailableStatusResponse, mapCurrentStatus } from '@app/data/http/quote-status.dto';

export async function getAvailableStatus(params?: GetAvailableStatusParams): Promise<AvailableStatusResponse> {
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    language: params.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_STATUS
  }`;

  const { data, status } = await axios.get(url, { headers: defaultHeaders });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
}
export function useCheckProcessingStatus(params: GetAvailableStatusParams, onSuccess?, onError?) {
  return useQuery(['getAvailableStatus', params], () => getAvailableStatus(params), {
    enabled: params.currentStatus === 'PROCESSING',
    onSuccess,
    onError,
    select: data => {
      return mapCurrentStatus(data);
    },
    refetchInterval: 10000,
    refetchOnWindowFocus: false,
  });
}
