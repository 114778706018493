import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';

export const deleteAttributes = async (params?: any) => {
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    language: params.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.data.analysisId}${
    endpoint.ANALYSIS_PLANT
  }/${params.data.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.data.attributeKey}`;

  const { data: dataResponse } = await axios.delete(url, { headers: defaultHeaders });

  return dataResponse;
};

export const useDeleteAttributes = (onSuccess?, onError?) => {
  const queryClient = useQueryClient();
  return useMutation(deleteAttributes, {
    onSuccess,
    onError,
    onMutate: async () => {
      await queryClient.cancelQueries(['getTechnicalAnalysis']);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['getTechnicalAnalysis']);
    },
  });
};
