import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { AvailableStatus, StatusUserList, UserListAllData } from '@app/models/quote.model';
import { AuthStorageService } from '../storage/auth.storage';
import { CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from './constants';
import {
  GetAvailableStatusParams,
  GetUsersByProfileParams,
  PatchAvailableStatusParams,
  PatchCancelQuotationParams,
  PatchSkuParams,
} from './quote-status-params.dto';
import {
  AvailableStatusResponse,
  GetUsersByProfileResponse,
  mapAvailableStatus,
  mapUserList,
  mapUserListGerdauAllData,
} from './quote-status.dto';

const endpoint = {
  ANALYSIS: '/analyses',
  ANALYSIS_STATUS: '/status',
  ANALYSIS_CANCEL: '/status/cancel',
  USERS: '/users',
  SKU: '/sku',
};

const user = Container.get(AuthStorageService).getUser();
const defaultHeaders = {
  client_id: CXP_KEY_CLIENT_ID,
  client_secret: CXP_KEY_CLIENT_SECRET,
  'Content-Type': 'application/json',
  Accept: '*/*',
  'Access-Control-Allow-Origin': 'egerdau.com.br',
};

export const QuoteStatusDataSource = {
  getAvailableStatus: (params: GetAvailableStatusParams): Promise<AvailableStatus> => {
    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.ANALYSIS}/${params.analysisId}${endpoint.ANALYSIS_STATUS}`)
      .headers({
        ...defaultHeaders,
        language: params.language,
        'sso-access': user.ssoAccess,
        Authorization: 'Bearer ' + params.authorizationToken,
        'Access-Token': params.accessToken,
      })
      .interceptors({
        afterSuccess: async (res: AvailableStatusResponse): Promise<AvailableStatus> => mapAvailableStatus(res),
      })
      .execute();
  },

  patchStatus: (params: PatchAvailableStatusParams): Promise<void> => {
    let notification;

    if (!params.notification?.partNumber) {
      notification = params.notification
        ? {
            emails: params?.current?.external?.key === 'UNDER_ANALYSIS' ? [] : params.notification.emails,
            skus: params.notification.skus,
            observation: params.notification.observation,
          }
        : { emails: [] };
    } else {
      notification = params.notification && {
        emails: params?.current?.external?.key === 'UNDER_ANALYSIS' ? [] : params.notification.emails,
        skus: params.notification.skus,
        part_number: params.notification.partNumber,
        observation: params.notification.observation,
      };
    }
    const current = params.current;
    const extraInfos = params.extraInfos;
    const analysisType = params.analysisType;

    const data = { current, notification, extraInfos, analysisType };

    if (!params.extraInfos) {
      delete data.extraInfos;
    }

    if (!params.analysisType) {
      delete data.analysisType;
    }

    return Container.get(HttpRequestBuilder)
      .patch(`${endpoint.ANALYSIS}/${params.analysisId}${endpoint.ANALYSIS_STATUS}`)
      .headers({
        ...defaultHeaders,
        language: params.language,
        'sso-access': user.ssoAccess,
        Authorization: 'Bearer ' + params.authorizationToken,
        'Access-Token': params.accessToken,
      })
      .data(data)
      .interceptors({
        afterError: async (res: any): Promise<void> => res,
      })
      .execute();
  },

  patchSkus: (params: PatchSkuParams): Promise<void> => {
    const skus = params.skus;

    return Container.get(HttpRequestBuilder)
      .patch(`${endpoint.ANALYSIS}/${params.analysisId}${endpoint.ANALYSIS_STATUS}${endpoint.SKU}`)
      .headers({
        ...defaultHeaders,
        language: params.language,
        'sso-access': user.ssoAccess,
        Authorization: 'Bearer ' + params.authorizationToken,
        'Access-Token': params.accessToken,
      })
      .data({ skus })
      .interceptors({
        afterError: async (res: any): Promise<void> => res,
      })
      .execute();
  },

  patchCancelQuotation: (params: PatchCancelQuotationParams): Promise<void> => {
    const data = { reason: params.reason };

    return Container.get(HttpRequestBuilder)
      .patch(`${endpoint.ANALYSIS}/${params.analysisId}${endpoint.ANALYSIS_CANCEL}`)
      .headers({
        ...defaultHeaders,
        language: params.language,
        'sso-access': user.ssoAccess,
        Authorization: 'Bearer ' + params.authorizationToken,
        'Access-Token': params.accessToken,
      })
      .data(data)
      .interceptors({
        afterSuccess: async (res: any): Promise<void> => res.status,
        afterError: async (res: any): Promise<void> => res,
      })
      .execute();
  },

  getUsersByProfile: (params: GetUsersByProfileParams): Promise<StatusUserList> => {
    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.USERS}`)
      .headers({
        ...defaultHeaders,
        language: params.language,
        'sso-access': user.ssoAccess,
        Authorization: 'Bearer ' + params.authorizationToken,
        'Access-Token': params.accessToken,
      })
      .data({ profile: params.profile })
      .interceptors({
        afterSuccess: async (res: GetUsersByProfileResponse[]): Promise<StatusUserList> => mapUserList(res),
      })
      .execute();
  },

  getUsersByProfileAllData: (params: GetUsersByProfileParams): Promise<UserListAllData> => {
    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.USERS}`)
      .headers({
        ...defaultHeaders,
        language: params.language,
        'sso-access': user.ssoAccess,
        Authorization: 'Bearer ' + params.authorizationToken,
        'Access-Token': params.accessToken,
      })
      .data({ profile: params.profile })
      .interceptors({
        afterSuccess: async (res: GetUsersByProfileResponse[]): Promise<UserListAllData> =>
          mapUserListGerdauAllData(res),
      })
      .execute();
  },
};
