import { format } from 'date-fns';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { CommentsFullData, QuoteAttributes } from '@app/models/quote.model';
import { quoteIsInternalUserSelector } from '@app/modules/auth/store/auth.selectores';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { validateTokens } from '@app/modules/components/notification-center/store/thunk/validate-and-get-notification.thunk';
import { TechnicalAnalysisCommentsEvents } from '@app/modules/quote/quote.analytics';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { FlashMessageTargetName } from '@app/providers';
import { InternalBadge } from '@atomic/atm.internal-badge/internal-badge.component';
import { Tab } from '@atomic/mol.tab';
import { Hbox, Separator } from '@atomic/obj.box';
import { Keys } from '@atomic/obj.constants';
import { LoadingState } from '@atomic/obj.loading-state';
import { useDeleteComment } from '../hooks/useDeleteComment';
import { useEditComment } from '../hooks/useEditComment';
import { usePostComment } from '../hooks/usePostComment';
import { TechnicalAnalysisContext } from '../technical-analysis.context';
import { CommentBadgeWrapper } from '../technical-analysis.style';
import { TechnicalAnalysisExternalComments } from './technical-analysis-external-comments.component';
import { TechnicalAnalysisInternalComments } from './technical-analysis-internal-comment.component';

interface TechnicalAnalysisCommentsProps {
  canAddComments: boolean;
  canReplicateComments: boolean;
  comments: CommentsFullData;
  plantKey: string;
  attributeKey: string;
  readOnly?: boolean;
  recurrentProduct?: boolean;
  internal?: boolean;
  tempComment: CommentsFullData;
  setTempComment: (comments: any) => void;
  setComments: (comments: CommentsFullData) => void;
  onClose?: () => void;
  tempCommentExternal: any;
  tempCommentInternal: any;
  setTempCommentExternal: any;
  setTempCommentInternal: any;
  resume?: boolean;
  attribute?: QuoteAttributes;
  loading: boolean;
}

export const getInitials = (name: string) => {
  const nameSplited = name.split(' ');
  if (nameSplited.length > 1) {
    return nameSplited.map(n => n[0]).join('');
  } else {
    return nameSplited[0].charAt(0);
  }
};

export enum Internal {
  'externalComments' = 'externalComments',
  'internalComments' = 'internalComments',
}

export const TechnicalAnalysisComments: React.FC<TechnicalAnalysisCommentsProps> = props => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { userInfo, azureToken, token } = useSelector((state: RootState) => state.auth);
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const { analysisId, analysis, getAnalysis, setHasTokenChecked } = React.useContext(TechnicalAnalysisContext);
  const [currentComment, setCurrentComment] = React.useState('');
  const [replicateComment, setReplicateComment] = React.useState(props.canReplicateComments);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const [internal, setInternal] = React.useState<boolean>(false);

  const strings = TechnicalAnalysisStrings[userInfo.language].comment;

  const PLANT_INDEX = analysis?.plants?.findIndex(plant => plant.key === props.plantKey);
  const ATTRIBUTE_INDEX = analysis?.plants[PLANT_INDEX]?.attributes?.findIndex(
    attribute => attribute?.key === props.attributeKey,
  );
  const analysisContext = analysis?.plants[PLANT_INDEX]?.attributes[ATTRIBUTE_INDEX];

  const findCommentIndex = (internalComment: boolean, key: string) => {
    return analysisContext.comments[!internalComment ? Internal.externalComments : Internal.internalComments].findIndex(
      item => item.key === key,
    );
  };

  const handleSuccessComment = (action: string) => {
    show('success', strings.success[action]);
  };

  const handleErrorComment = (action: string) => {
    dispatch(validateTokens()).then(() => {
      setHasTokenChecked(true);
      show('alert', strings.errors[action]);
      getAnalysis({ id: analysisId, authorizationToken: azureToken, accessToken: token });
    });
  };

  const { mutate: postComment, isLoading: postLoading } = usePostComment(
    () => handleSuccessComment('post'),
    () => handleErrorComment('post'),
  );
  const { mutate: editComments, isLoading: editLoading } = useEditComment(
    () => handleSuccessComment('edit'),
    () => handleErrorComment('edit'),
  );
  const { mutate: deleteComment, isLoading: deleteLoading } = useDeleteComment(
    () => handleSuccessComment('delete'),
    () => handleErrorComment('delete'),
  );

  const blockCommentButtons = postLoading || deleteLoading || editLoading || analysisContext?.loading;

  const handlePostComment = () => {
    dispatch(validateTokens()).then(() => {
      setHasTokenChecked(true);
      if (currentComment.length > 0) {
        LogAnalytics.submit({ tipo: TechnicalAnalysisCommentsEvents.ExternalComment });

        let firstname;
        let lastName;

        const nameAndSurname = userInfo.name.split(' ');
        if (nameAndSurname.length > 1) {
          firstname = nameAndSurname[0];
          lastName = nameAndSurname[1];
        } else {
          firstname = nameAndSurname[0];
          lastName = '';
        }

        const newCommentProv = {
          client_visible: !internal,
          creation: {
            createdBy: {
              email: userInfo.email,
              firstName: firstname,
              lastName: lastName,
            },
            createdDate: `${format(new Date(), 'yyyy-MM-dd')}T${format(new Date(), 'HH:mm')}`,
            origin: 'USER',
            status_stage: quoteIsInternalUser ? analysis.internalStatus : analysis.externalStatus,
          },
          history: [],
          key: window.crypto.getRandomValues(new Uint8Array(8)).join('-'),
          permissions: { isDelible: true, isEditable: true },
          value: currentComment,
          loading: true,
        };

        postComment({
          analysisId,
          plantKey: props.plantKey,
          attributeKey: props.attributeKey,
          PLANT_INDEX,
          ATTRIBUTE_INDEX,
          comment: currentComment,
          isClientVisible: !internal,
          isClient: !quoteIsInternalUser,
          replicateComment,
          newCommentProv,
          language: userInfo.language,
          authorizationToken: azureToken,
          accessToken: token,
        });

        setCurrentComment('');
      }
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === Keys.Enter) {
      handlePostComment();
    }
  };

  const handleDeleteComments = (commentKey: string) => {
    dispatch(validateTokens()).then(() => {
      setHasTokenChecked(true);
      LogAnalytics.click({ tipo: TechnicalAnalysisCommentsEvents.DeleteExternalComment });

      deleteComment({
        analysisId,
        attributeKey: props.attributeKey,
        PLANT_INDEX,
        ATTRIBUTE_INDEX,
        plantKey: props.plantKey,
        commentKey,
        language: userInfo.language,
        authorizationToken: azureToken,
        accessToken: token,
      });
    });
  };

  const handleEditComments = (commentKey: string, editedComment: string) => {
    dispatch(validateTokens()).then(() => {
      setHasTokenChecked(true);
      LogAnalytics.click({ tipo: TechnicalAnalysisCommentsEvents.EditInternalComment });
      analysisContext.comments[!internal ? Internal.externalComments : Internal.internalComments][
        findCommentIndex(internal, commentKey)
      ].value = editedComment;
      analysisContext.comments[!internal ? Internal.externalComments : Internal.internalComments][
        findCommentIndex(internal, commentKey)
      ].loading = true;

      editComments({
        analysisId,
        attributeKey: props.attributeKey,
        PLANT_INDEX,
        ATTRIBUTE_INDEX,
        plantKey: props.plantKey,
        commentKey,
        editedComment,
        language: userInfo.language,
        authorizationToken: azureToken,
        accessToken: token,
      });
    });
  };

  const handleTabChange = tab => {
    setReplicateComment(true);
    if (tab === 0) {
      setInternal(false);
    } else {
      setInternal(true);
    }
  };

  const totalInternalComments =
    props.comments?.internalComments.length > 0
      ? props.comments?.internalComments.length
      : props.attribute.comments.internalComments.length;

  const totalEnternalComments =
    props.comments?.externalComments.length > 0
      ? props.comments?.externalComments.length
      : props.attribute.comments.externalComments.length;

  const blockButton = editLoading || postLoading;

  return (
    <>
      <Tab onIndexChanged={handleTabChange}>
        <Tab.Item key='c-external'>
          <Hbox>
            <Hbox.Item>{strings.tabs.external}</Hbox.Item>
            <CommentBadgeWrapper>
              <InternalBadge notification={`${totalEnternalComments}`}></InternalBadge>
            </CommentBadgeWrapper>
          </Hbox>
        </Tab.Item>
        {quoteIsInternalUser ? (
          <Tab.Item key='c-internal'>
            <Hbox>
              <Hbox.Item>{strings.tabs.internal}</Hbox.Item>
              <CommentBadgeWrapper>
                <InternalBadge notification={`${totalInternalComments}`}></InternalBadge>
              </CommentBadgeWrapper>
            </Hbox>
          </Tab.Item>
        ) : (
          ''
        )}
      </Tab>
      <Separator />
      {internal ? (
        <LoadingState loading={blockCommentButtons || props.loading} enableActivityIndicator={true}>
          <TechnicalAnalysisInternalComments
            comments={props.comments}
            canAddComments={props.canAddComments}
            canReplicateComments={props.canReplicateComments}
            initials={getInitials(userInfo.name)}
            currentComment={currentComment}
            tempComment={props.tempComment}
            attributeKey={props.attributeKey}
            setCurrentComment={setCurrentComment}
            onDeleteComment={handleDeleteComments}
            onEditComment={handleEditComments}
            onKeyDown={handleKeyDown}
            onSubmit={handlePostComment}
            onReplicateClick={setReplicateComment}
            onClose={props.onClose}
            loading={deleteLoading || props.loading}
            blockCommentButtons={blockCommentButtons}
            blockButton={blockButton}
          />
        </LoadingState>
      ) : (
        <LoadingState loading={deleteLoading || props.loading} enableActivityIndicator={true}>
          <TechnicalAnalysisExternalComments
            comments={props.comments}
            canAddComments={props.canAddComments}
            canReplicateComments={props.canReplicateComments}
            initials={getInitials(userInfo.name)}
            currentComment={currentComment}
            tempComment={props.tempCommentExternal}
            attributeKey={props.attributeKey}
            setCurrentComment={setCurrentComment}
            onDeleteComment={handleDeleteComments}
            onEditComment={handleEditComments}
            onKeyDown={handleKeyDown}
            onSubmit={handlePostComment}
            onReplicateClick={setReplicateComment}
            loading={deleteLoading || props.loading}
            analysis={analysis}
            blockCommentButtons={blockCommentButtons}
            blockButton={blockButton}
          />
        </LoadingState>
      )}
    </>
  );
};
