import React, { FC } from 'react';
import 'react-image-lightbox/style.css';
import { useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { RootState } from '@app/core/redux/store';
import { QuoteAttributesDataSource } from '@app/data/http';
import { FullAnalysisData, QuoteAttributes } from '@app/models/quote.model';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { FlashMessageTargetName } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { Cell } from '@atomic/atm.cell';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Frame } from '@atomic/atm.frame';
import { TextAreaField } from '@atomic/atm.text-field';
import { Body, H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { Form, Validators } from '@atomic/obj.form';
import { Col, Row, VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { TechnicalAnalysusAttributeEvents } from '../../quote.analytics';
import { TechnicalAnalysisContext } from '../technical-analysis.context';
import { AttributeCellFormWrapperStyled } from './attribute-cell.form.style';

interface AttributeCellProps {
  analysis: FullAnalysisData;
  title: string;
  status: string;
  onGerdauCommentsClick: () => void;
  plantKey: string;
  attribute: QuoteAttributes;
  readOnly: boolean;
  recurrentProduct?: boolean;
  editedAttributes?: string[];
  baseURL?: string | ArrayBuffer;
  derogate: any;
  setLoadingTestMethod?: (value: string[]) => void;
  loadingTestMethod?: string[];
}

export const AttributeTestMethod: FC<AttributeCellProps> = props => {
  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const { getPartialAnalysis, analysisId, analysis } = React.useContext(TechnicalAnalysisContext);
  const [showForm, setShowForm] = React.useState<boolean>(false);
  const [currentTestMethod, setCurrentTestMethod] = React.useState<string>('');
  const { userInfo, azureToken, token } = useSelector((state: RootState) => state.auth);
  const strings = TechnicalAnalysisStrings[userInfo.language].attributes.testMethod;

  const PLANT_INDEX = props.analysis.plants.findIndex(plant => plant.key === props.plantKey);
  const ATTRIBUTE_INDEX = props.analysis.plants[PLANT_INDEX].attributes.findIndex(
    attribute => attribute?.key === props.attribute?.key,
  );

  React.useEffect(() => {
    setShowForm(false);
  }, [props.attribute]);

  const handleSuccess = () => {
    props.setLoadingTestMethod(
      props.loadingTestMethod.splice(props.loadingTestMethod.indexOf(`${props.attribute.label}`), 1),
    );
  };

  const handleFailure = () => {
    LogAnalytics.error({ tipo: TechnicalAnalysusAttributeEvents.EditAttributeError });
    show('alert', strings.errorMessages.genericError);
    getPartialAnalysis({ id: analysisId, authorizationToken: azureToken, accessToken: token });
  };

  const { performRequest: editRequest, error: editError } = useLazyRequest(
    QuoteAttributesDataSource.editTestMethod,
    handleSuccess,
    handleFailure,
  );

  const handleCancelClick = () => {
    setShowForm(false);
  };

  const visible = !props.readOnly && !props.recurrentProduct;

  const handleChangeTestMethod = (value: string) => {
    setCurrentTestMethod(value);
  };

  const handleSubmit = () => {
    LogAnalytics.click({ tipo: TechnicalAnalysusAttributeEvents.ChangeTestMethod });
    analysis.plants[PLANT_INDEX].attributes[ATTRIBUTE_INDEX].testMethod = currentTestMethod;
    editRequest({
      data: {
        analysisId,
        plantKey: props.plantKey,
        attributeKey: props.attribute?.key,
        status: props.status,
        derogate: props.derogate,
        testMethod: currentTestMethod,
      },
      authorizationToken: azureToken,
      accessToken: token,
    });
    props.setLoadingTestMethod([...props.loadingTestMethod, props.attribute?.label]);
    setShowForm(false);
  };

  return (
    <AttributeCellFormWrapperStyled>
      <LoadingState
        loading={props.loadingTestMethod.includes(props.attribute.label)}
        data={!!props.title}
        error={!!editError}
      >
        <Frame>
          <Cell>
            <Row>
              <Col xs={9}>
                <Hbox>
                  <Hbox.Item noGrow>
                    <H3>{strings.title}</H3>
                  </Hbox.Item>
                  <Hbox.Separator />
                </Hbox>
              </Col>
              <Col xs={3}>
                <Hbox hAlign='flex-end'>
                  {props.attribute.permissions.isEditable && !showForm && visible && (
                    <Hbox.Item noGrow>
                      <FaIcon.Custom
                        onClick={() => setShowForm(true)}
                        clickable='true'
                        size='1x'
                        icon='pen'
                        color={Color.Primary}
                      />
                    </Hbox.Item>
                  )}
                </Hbox>
              </Col>
            </Row>
            <VSeparator />
            <Row>
              <Col xs={12}>
                {!showForm ? (
                  <Body>{props.attribute.testMethod}</Body>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <Hbox.Item>
                      <Form.Field
                        initialValue={props.attribute.testMethod}
                        name='testMethod'
                        onValueChange={value => handleChangeTestMethod(value)}
                        validators={[Validators.Required(strings.alertMandatory)]}
                      >
                        <TextAreaField placeholder={strings.label} disableResize />
                      </Form.Field>
                      <VSeparator />
                    </Hbox.Item>
                    <Hbox hAlign='flex-end'>
                      <Hbox.Item noGrow>
                        <Button onClick={handleCancelClick} kind='secondary'>
                          {strings.cancelButton}
                        </Button>
                      </Hbox.Item>
                      <Hbox.Separator />
                      <Hbox.Item noGrow>
                        <Button type='submit' kind='primary'>
                          {strings.submitButton}
                        </Button>
                      </Hbox.Item>
                    </Hbox>
                  </Form>
                )}
              </Col>
            </Row>
          </Cell>
        </Frame>
      </LoadingState>
    </AttributeCellFormWrapperStyled>
  );
};
