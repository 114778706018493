import React from 'react';
import { useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { RootState } from '@app/core/redux/store';
import { QuoteDataSource } from '@app/data/http';
import { DownloadAnalysis } from '@app/data/http/quote-params.dto';
import { FullAnalysisData } from '@app/models/quote.model';
import { quoteIsInternalUserSelector } from '@app/modules/auth/store/auth.selectores';
import { TechnicalAnalysisAboutEvents } from '@app/modules/quote/quote.analytics';
import { TechnicalAnalysisContext } from '@app/modules/quote/technical-analysis/technical-analysis.context';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Hbox } from '@atomic/obj.box';
import { useEventListener } from '@atomic/obj.hooks/use-event-listener.hook';
import { LoadingState } from '@atomic/obj.loading-state';
import { DownloadPdfModal } from '../components/download-pdf-modal.component';

interface GeneratePdfButtonPdfProps {
  analysis: FullAnalysisData;
  disabled: boolean;
  onEditClick: () => void;
  onDuplicateClick: () => void;
}

export const GeneratePdfButton = (props: GeneratePdfButtonPdfProps) => {
  const menuRef = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const [modalOpened, setModalOpened] = React.useState(false);
  const { analysisId } = React.useContext(TechnicalAnalysisContext);
  const { userInfo, azureToken, token } = useSelector((state: RootState) => state.auth);
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const strings = TechnicalAnalysisStrings[userInfo.language].components.about;

  const handleDownloadSuccess = (response: any) => {
    LogAnalytics.click({ tipo: TechnicalAnalysisAboutEvents.DownloadAnalysis });
    const blob = new Blob([response], { type: 'application/pdf' });
    const link = document.createElement<'a'>('a');
    document.body.appendChild(link);
    link.download = `analysis_${analysisId}.pdf`;
    link.href = URL.createObjectURL(blob);
    link.click();
  };

  const { performRequest, loading } = useLazyRequest<DownloadAnalysis, string>(
    QuoteDataSource.downloadAnalysis,
    handleDownloadSuccess,
  );

  const handleModalSubmit = (data: string[]) => {
    LogAnalytics.click({ tipo: TechnicalAnalysisAboutEvents.DownloadAnalysisGerdau });
    performRequest({
      analysisId,
      plants: data,
      language: userInfo.language,
      authorizationToken: azureToken,
      accessToken: token,
    });
    setModalOpened(false);
  };

  const handleDownloadPdfClick = () => {
    if (quoteIsInternalUser) {
      LogAnalytics.pageView(TechnicalAnalysisAboutEvents.DownloadAnalysis);
      setModalOpened(true);
    } else {
      LogAnalytics.click({ tipo: TechnicalAnalysisAboutEvents.DownloadAnalysisClient });
      performRequest({ analysisId, language: userInfo.language, authorizationToken: azureToken, accessToken: token });
    }
  };

  const handleMouseDown = (event: MouseEvent) => {
    if (show && menuRef && !menuRef.current.contains(event.target)) {
      setShow(false);
    }
  };
  useEventListener('mousedown', handleMouseDown);

  return (
    <Hbox.Item noGrow vAlign='center' mb>
      <LoadingState loading={loading} enableActivityIndicator={true}>
        <Button
          kind='secondary'
          title={strings.generateTitlePdf}
          onClick={handleDownloadPdfClick}
          disabled={props.disabled}
        >
          <Hbox>
            <Hbox.Item noGrow vAlign='center'>
              <FaIcon.Custom icon='file-pdf' />
            </Hbox.Item>
            <Hbox.Separator />
            <Hbox.Item>{strings.generatePdf}</Hbox.Item>
          </Hbox>
        </Button>

        <DownloadPdfModal
          analysis={props.analysis}
          loading={false}
          opened={modalOpened}
          onClose={() => setModalOpened(false)}
          onSubmit={handleModalSubmit}
        />
      </LoadingState>
    </Hbox.Item>
  );
};
