/* eslint-disable complexity */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Scroll from 'react-scroll';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { FullAnalysisData, Plant, QuoteAttributes } from '@app/models/quote.model';
import { SimpleToolTip } from '@app/modules/components/SimpleToolTip';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { validateTokens } from '@app/modules/components/notification-center/store/thunk/validate-and-get-notification.thunk';
import { TechnicalAnalysisTabEvents } from '@app/modules/quote/quote.analytics';
import { FlashMessageTargetName } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Switch } from '@atomic/atm.switch';
import { PreviewMessage } from '@atomic/mol.preview-message';
import { Hbox } from '@atomic/obj.box';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { QuoteFilterLabelStrings } from '../quote.string';
import { TechnicalAnalysisAttribute } from './attributes/technical-analysis-attribute.component';
import { TechnicalAnalysisAttributeProvider } from './attributes/technical-analysis-attribute.context';
import { TechnicalAnalysisEditAttributeModal } from './attributes/technical-analysis-edit-attribute-modal.component';
import { TechnicalAnalysisNewAttributeModal } from './attributes/technical-analysis-new-attribute-modal.component';
import { TechnicalAnalysisViewModal } from './components/technical-analysis-view-modal.component';
import { useEditAttributesSequence } from './hooks/useEditAttributesSequence';
import { useEditPlants } from './hooks/useEditPlants';
import { TechnicalAnalysisContext } from './technical-analysis.context';
import { TechnicalAnalysisStrings } from './technical-analysis.string';
import { TechnicalAnalysisTabWrapperStyled } from './technical-analysis.style';

interface TechnicalAnalysisTabProps {
  analysis: FullAnalysisData;
  hasPrice: boolean;
  plant: Plant;
  recurrentProduct?: boolean;
  editedAttributes?: string[];
  externalStatus?: string;
  internalUser: boolean;
  disableButtons: boolean;
  blockProcess: boolean;
  actionsLoading: boolean;
  setActionsLoading: (value: boolean) => void;
}

export const TechnicalAnalysisTab: React.FC<TechnicalAnalysisTabProps> = props => {
  const [modalNewAttributeOpened, setModalNewAttributeOpened] = React.useState(false);
  const [modalEditAttributeOpened, setModalEditAttributeOpened] = React.useState(false);
  const [modalAnalysisViewOpened, setModalAnalysisViewOpened] = React.useState(false);
  const [selectedAttribute, setSelectedAttribute] = React.useState<QuoteAttributes>(null);
  const dispatch = useDispatch<AppThunkDispatch>();

  const {
    analysisId,
    analysis,
    attributeLoading,
    setAnalysis,
    toolTipRequestAnalysis,
    setToolTipRequestAnalysis,
    setToolTipStatusButton,
    setLoadingEditPlant,
    setHasTokenChecked,
  } = React.useContext(TechnicalAnalysisContext);

  const PLANT_INDEX = props.analysis?.plants?.findIndex(plant => plant.key === props?.plant?.key);

  const [reviewChecked, setReviewChecked] = React.useState(props.analysis?.plants[PLANT_INDEX]?.review?.isReviewed);
  const [requestChecked, setRequestChecked] = React.useState(props.analysis?.plants[PLANT_INDEX]?.review?.isRequested);
  const [selectionChecked, setSelectionChecked] = React.useState(props.plant.selection?.isSelected);
  const [validationChecked, setValidationChecked] = React.useState(props.plant.validation?.isValidated);
  const [draggableAttributes, setDraggableAttributes] = React.useState<QuoteAttributes[]>(
    props.analysis?.plants[PLANT_INDEX]?.attributes,
  );
  const { userInfo, azureToken, token } = useSelector((state: RootState) => state.auth);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const strings = TechnicalAnalysisStrings[userInfo.language].tab;

  React.useEffect(() => {
    if (props.plant) {
      const ATTRIBUTE_INDEX = props.plant.attributes.findIndex(attribute => attribute?.key === selectedAttribute?.key);
      const index = ATTRIBUTE_INDEX >= 0 ? ATTRIBUTE_INDEX : 0;
      if (index === 0) {
        Scroll.animateScroll.scrollToTop({ containerId: 'list' });
      }

      setSelectedAttribute(props.analysis?.plants[PLANT_INDEX]?.attributes[index]);
      setReviewChecked(props.analysis?.plants[PLANT_INDEX]?.review?.isReviewed);
      setSelectionChecked(props.analysis?.plants[PLANT_INDEX]?.selection?.isSelected);
      setRequestChecked(props.analysis?.plants[PLANT_INDEX]?.review?.isRequested);
      setValidationChecked(props.analysis?.plants[PLANT_INDEX]?.validation?.isValidated);
      setDraggableAttributes(props.analysis?.plants[PLANT_INDEX]?.attributes);
    }
  }, [props.plant]);

  const handleSuccess = () => {
    show('success', strings.attributeOrder.success);
    setLoadingEditPlant(false);
  };

  const { mutate: editPlant, isLoading: loadingEditPlant } = useEditPlants(handleSuccess);

  const handleSequenceSuccess = () => {
    show('success', strings.attributeOrder.success);
  };

  const { mutate: editSequence, isLoading: loadingSequence } = useEditAttributesSequence(handleSequenceSuccess);

  const handleValidationSteps = (value: boolean, action: string) => {
    dispatch(validateTokens()).then(() => {
      switch (action) {
        case 'isReviewed':
          LogAnalytics.click({ tipo: TechnicalAnalysisTabEvents.ReviewPlant });
          setReviewChecked(value);
          editPlant({
            analysisId,
            plantKey: props.plant.key,
            review: { isReviewed: value },
            language: userInfo.language,
            authorizationToken: azureToken,
            accessToken: token,
          });
          break;
        case 'isRequested':
          LogAnalytics.click({ tipo: TechnicalAnalysisTabEvents.ReviewPlant });
          setRequestChecked(value);
          editPlant({
            analysisId,
            plantKey: props.plant.key,
            review: { isRequestable: value },
            language: userInfo.language,
            authorizationToken: azureToken,
            accessToken: token,
          });
          break;
        case 'isSelected':
          LogAnalytics.click({ tipo: TechnicalAnalysisTabEvents.SelectPLant });
          setSelectionChecked(value);
          editPlant({
            analysisId,
            plantKey: props.plant.key,
            selection: { isSelected: value },
            language: userInfo.language,
            authorizationToken: azureToken,
            accessToken: token,
          });
          break;
        case 'isValidated':
          LogAnalytics.click({ tipo: TechnicalAnalysisTabEvents.ValidatePlant });
          setValidationChecked(value);
          editPlant({
            analysisId,
            plantKey: props.plant.key,
            validation: { isValidated: value },
            language: userInfo.language,
            authorizationToken: azureToken,
            accessToken: token,
          });
          break;
      }
      setAnalysis({ ...analysis });
      setLoadingEditPlant(true);

      setHasTokenChecked(true);
    });
  };

  const handleNewAttributeClick = () => {
    LogAnalytics.pageView(TechnicalAnalysisTabEvents.NewAttribute);
    setModalNewAttributeOpened(true);
  };

  const handleAnalysisViewClick = () => {
    LogAnalytics.pageView(TechnicalAnalysisTabEvents.ConsolidatedView);
    setModalAnalysisViewOpened(true);
  };

  const reorderAttributes = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  let elements = [];
  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    elements = reorderAttributes(draggableAttributes, result.source.index, result.destination.index);

    const attributesSequence = [];
    elements.forEach((attr, index) => {
      attributesSequence.push({ key: attr.key, sequence: index });
    });
    dispatch(validateTokens()).then(() => {
      editSequence({
        analysisId,
        attributesSequence,
        language: userInfo.language,
        authorizationToken: azureToken,
        accessToken: token,
      });
    });
    setDraggableAttributes(elements);
  };

  const handleAttributeSelect = (attr: QuoteAttributes) => {
    setSelectedAttribute(attr);
  };

  const handleCloseToolTipRequestAnalysis = () => {
    setToolTipRequestAnalysis(false);
    setToolTipStatusButton(true);
  };

  const [editAttribute, setEditAttribute] = React.useState(null);
  const handleEditAttribute = attr => {
    setEditAttribute(attr);
    setModalEditAttributeOpened(true);
  };

  return (
    <>
      {props.analysis?.internalStatus?.key === 'PROCESSING' || props.analysis?.externalStatus?.key === 'PROCESSING' ? (
        <PreviewMessage kind='message' message={strings.processingMessage} />
      ) : null}
      {props.plant?.review?.isRequested &&
        (!props.plant?.selection?.isSelected || !props.plant?.review?.isReviewed) && (
          <PreviewMessage kind='success' message={strings.previewMessage} />
        )}
      {props.plant.hasResume && props.internalUser && (
        <PreviewMessage
          kind='success'
          message={strings.messageResume(QuoteFilterLabelStrings[userInfo.language][props.plant.key])}
        />
      )}
      <TechnicalAnalysisTabWrapperStyled hasPrice={props.hasPrice}>
        <Grid fluid>
          <LoadingState loading={props.disableButtons || loadingEditPlant || props.actionsLoading}>
            <Row middle='xs' mt>
              {!!props.plant.permissions.attributesAddible && !props.disableButtons && (
                <Col xs={3}>
                  <Button radius='large' onClick={handleNewAttributeClick}>
                    <Hbox>
                      <Hbox.Item vAlign='center' noGrow>
                        <FaIcon.Plus size='1x' />
                      </Hbox.Item>
                      <Hbox.Separator small />
                      <Hbox.Item>{strings.newAttribute}</Hbox.Item>
                    </Hbox>
                  </Button>
                </Col>
              )}
              {!!props.plant?.review?.permissions.isEditable && !props.disableButtons && (
                <Col xs={3}>
                  <Switch
                    id='review'
                    onChange={val => handleValidationSteps(val, 'isReviewed')}
                    checkedIcon='check'
                    uncheckedIcon='times'
                    alert
                    checked={reviewChecked}
                  >
                    {strings.validatedPlant}
                  </Switch>
                </Col>
              )}
              {!!props.plant?.review?.permissions.isRequestable && !props.disableButtons && (
                <Col xs={3}>
                  <SimpleToolTip
                    visible={toolTipRequestAnalysis}
                    description={TechnicalAnalysisStrings[userInfo.language].toolTipRequestAnalysis.description}
                    btnLabel={TechnicalAnalysisStrings[userInfo.language].toolTipRequestAnalysis.buttonText}
                    align='left'
                    customVerticalAlign={40}
                    steps={TechnicalAnalysisStrings[userInfo.language].toolTipRequestAnalysis.steps}
                    onClick={handleCloseToolTipRequestAnalysis}
                    onClose={handleCloseToolTipRequestAnalysis}
                  />
                  <Switch
                    id='request'
                    checked={requestChecked}
                    onChange={val => handleValidationSteps(val, 'isRequested')}
                    disabled={props.disableButtons || loadingEditPlant}
                  >
                    {strings.requestedPlant}
                  </Switch>
                </Col>
              )}
              {!!props.plant?.selection?.permissions.isEditable && !props.disableButtons && (
                <Col xs={3}>
                  <Switch
                    id='selection'
                    checked={selectionChecked}
                    onChange={val => handleValidationSteps(val, 'isSelected')}
                    disabled={props.disableButtons || loadingEditPlant}
                  >
                    {strings.selectedPlant}
                  </Switch>
                </Col>
              )}
              {!!props.plant?.validation?.permissions.isEditable &&
                props.analysis?.internalStatus?.key !== 'VALIDATION_RTC' &&
                props.analysis?.internalStatus?.key !== 'VALIDATION_SELLER' && (
                  <Col xs={4}>
                    <Switch
                      id='validation'
                      checked={validationChecked}
                      onChange={val => handleValidationSteps(val, 'isValidated')}
                      disabled={props.disableButtons || loadingEditPlant}
                    >
                      {strings.validatedPlant}
                    </Switch>
                  </Col>
                )}
              <Col xs>
                <Hbox>
                  <Hbox.Item hAlign='flex-end'>
                    <Button kind='secondary' onClick={handleAnalysisViewClick}>
                      <Hbox>
                        <Hbox.Item noGrow vAlign='center'>
                          <FaIcon.Eye />
                        </Hbox.Item>
                        <Hbox.Separator />
                        <Hbox.Item>{strings.plantSummary}</Hbox.Item>
                      </Hbox>
                    </Button>
                  </Hbox.Item>
                </Hbox>
              </Col>
            </Row>
          </LoadingState>
          <Row mt>
            <TechnicalAnalysisAttributeProvider>
              <Col xs={12}>
                <TechnicalAnalysisAttribute
                  analysis={props.analysis}
                  attribute={selectedAttribute}
                  plant={props.plant}
                  recurrentProduct={props.recurrentProduct}
                  editedAttributes={props.editedAttributes}
                  list={draggableAttributes}
                  onHandleAttribute={value => handleAttributeSelect(value)}
                  onDragEnd={onDragEnd}
                  selectedAttribute={selectedAttribute}
                  loading={loadingSequence || attributeLoading || props.actionsLoading || loadingEditPlant}
                  disableButtons={props.disableButtons}
                  resume={false}
                  onHandleEditAttribute={handleEditAttribute}
                  setActionsLoading={props.setActionsLoading}
                />
              </Col>
            </TechnicalAnalysisAttributeProvider>
          </Row>
        </Grid>
      </TechnicalAnalysisTabWrapperStyled>
      <TechnicalAnalysisNewAttributeModal
        opened={modalNewAttributeOpened}
        onClose={() => setModalNewAttributeOpened(false)}
        plants={props.analysis.plants}
        plantKey={props.plant.key}
      />
      <TechnicalAnalysisEditAttributeModal
        opened={modalEditAttributeOpened}
        onClose={() => setModalEditAttributeOpened(false)}
        attribute={editAttribute}
      />
      <TechnicalAnalysisViewModal
        title={strings.resumeTitle(props.plant.label)}
        opened={modalAnalysisViewOpened}
        onClose={() => setModalAnalysisViewOpened(false)}
      >
        <TechnicalAnalysisAttributeProvider>
          {props.plant.attributes.map(attribute => (
            <TechnicalAnalysisAttribute
              key={attribute.key}
              analysis={props.analysis}
              attribute={attribute}
              plant={props.plant}
              recurrentProduct={props.recurrentProduct}
              readOnly={!props.disableButtons}
              disableButtons={props.disableButtons}
              resume={true}
              setActionsLoading={props.setActionsLoading}
            />
          ))}
        </TechnicalAnalysisAttributeProvider>
      </TechnicalAnalysisViewModal>
    </>
  );
};
