export interface CrmData {
  cfId: string;
  clientName: string;
  cfDescription: string;
  cfStatus: string;
  initialDate: string;
  sendDerrogateDate: string;
  endDate: string;
  plant: string;
  clientSteel: string;
  development: string;
  finalMarket: string;
  finalClient: string;
  partApplication: string;
  classificationPRD: string;
  revisionReview: string;
  hasCache: boolean;
}

export interface CrmComplainData {
  rcId: string;
  rcDescription: string;
  clientName: string;
  colaborator: string;
  referenceInvoiceDate: string;
  referenceInvoiceExternal: string;
  saleDocument: string;
  hasCache: boolean;
  lang: string;
}

export interface CrmPaginateData {
  skip: number;
  take: number;
  limit: number;
}
export interface CrmListResponse {
  success: boolean;
  data: CrmData[];
  paginate: {
    numberOfPages: number;
    total: number;
  };
  errors: any[];
}

export interface CrmListComplainResponse {
  success: boolean;
  data: CrmComplainData[];
  paginate: {
    numberOfPages: number;
    total: number;
  };
  errors: any[];
}

export interface OpportunityDetailsResponse {
  GENERAL_DATA: {
    tb: string;
    cfId: string;
    cfDescription: string;
    cfStatus: string;
    clientName: string;
    clientAddress: string;
    colaborator: string;
  };
  DATES: {
    initial: string;
    end: string;
    sendDerrogate: string;
  };
  CYCLE_SALES: {
    cfStatus: string;
  };
  CLASSIFICATION: {
    opportunitiesGroup: string;
  };
}

export interface OpportunityDetailsResponseComplain {
  GENERAL_DATA: {
    rcId: string;
    rcDescription: string;
    clientName: string;
    clientAddress: string;
    colaborator: string;
    referenceInvoiceDate: string;
    referenceInvoiceExternal: string;
    operationInvoiceExternal: string;
    saleDocument: string;
    batch: string;
    run: string;
  };
  DATES: {
    initial: string;
    createdBy: string;
    updatedBy: string;
    type: string;
    colaborator: string;
  };
  WORKFLOW: {
    status: string;
    responsibleCurrentPending: string;
    responsibleLastApproval: string;
    situation: string;
    identifyTask: string;
    createdAt: string;
  };
  PROCESSING_DATA: {
    priority: string;
    status: string;
    netValue: string;
  };
  CATEGORIZATION: {
    department: string;
    symptom: string;
    cause: string;
    subsequentOrderReason: string;
    financialImpact: string;
    debtToCarry: string;
  };
  QUESTIONS: {
    immediateReturnWithNF: string;
    isLogisticOrQualityAnalysis: string;
    rcIsValid: string;
    scenario: string;
    symptomRemovalAction: string;
    amountCreditToBeGrantedWithoutTax: string;
  };
}
export interface CrmManufacturingConsultationResponse {
  revisionReview: string;
  clientSteel: string;
  diameter: string;
  businessType: string;
  finalMarket: string;
  finalClient: string;
  PPAP: string;
  pilotBatch: string;
  approvalDateEPP: string;
  dateSendDerogationCustomer: string;
  clientReview: string;
  partNumber: string;
  length: string;
  classificationPRD: string;
  partApplication: string;
  quantity: string;
  clientProcess: string;
  development: string;
  rejectionDateEPP: string;
  approvalDerogationDate: string;
}
export interface NotesFieldsResponse {
  title: string;
  text: string;
  date: string;
  hour: string;
  createdBy: string;
}
export interface AttachedFieldsResponse {
  partnerRole?: string;
  fileName: string;
  typeFile: string;
  createdBy: string;
  createdAt: string;
  link: string;
}
export interface PlantsResponse {
  type: string;
  clientId: string;
  plant: string;
  name: string;
  isMainPartner: string;
  email: string;
}
export interface CrmDetailsResponse {
  OPPORTUNITY_DETAILS: OpportunityDetailsResponse;
  MANUFACTURING_CONSULTATION: CrmManufacturingConsultationResponse;
  ATTACHED_FILES: AttachedFieldsResponse[];
  NOTES: NotesFieldsResponse[];
  PLANTS: PlantsResponse[];
}
export interface CrmAllCachedResponse {
  success: boolean;
  data: CrmDetailsResponse;
  errors: any[];
}

export interface CrmAttachedFilesResponse {
  success: boolean;
  data: { ATTACHED_FILES: AttachedFieldsResponse[] };
  errors: any[];
}

export interface CrmManufactoringConsultationResponse {
  success: boolean;
  data: { MANUFACTURING_CONSULTATION: CrmManufacturingConsultationResponse };
  errors: any[];
}

export interface CrmNotesResponse {
  success: boolean;
  data: { NOTES: NotesFieldsResponse[] };
  errors: any[];
}

export interface CrmOpportunityDetailsResponse {
  success: boolean;
  data: { OPPORTUNITY_DETAILS: OpportunityDetailsResponse };
  errors: any[];
}

export interface CrmOpportunityDetailsResponseComplain {
  success: boolean;
  data: { OPPORTUNITY_DETAILS: OpportunityDetailsResponseComplain };
  errors: any[];
}

export interface CrmPlantsResponse {
  success: boolean;
  data: { PLANTS: PlantsResponse[] };
  errors: any[];
}

export interface NotesResponseComplain {
  type: string;
  text: string;
  language: string;
}
export interface CrmNotesResponseComplain {
  success: boolean;
  data: { NOTES: NotesResponseComplain[] };
  errors: any[];
}

export interface ItemsResponse {
  id: number;
  idHigher: number;
  itemHigher: string;
  quantity: string;
  unit: string;
  productName: string;
  categoryName: string;
  productNetValue: string;
  coin: string;
  idCostCenter: number;
}

export interface WorkflowResponse {
  status: string;
  responsibleCurrentPending: string;
  responsibleLastApproval: string;
  situation: string;
  identifyTask: string;
  createdAt: string;
}
export interface CrmItemsResponseComplain {
  success: boolean;
  data: { ITEMS: ItemsResponse[] };
  errors: any[];
}
export interface CrmWorkflowResponse {
  success: boolean;
  data: { WORKFLOW: WorkflowResponse[] };
  errors: any[];
}

export interface HistoryResponse {
  action: string;
  level: string;
  newValue: string;
  oldValue: string;
  updateBy: string;
  updatedAt: string;
}
export interface CrmHistoryResponse {
  success: boolean;
  data: { HISTORY: HistoryResponse[] };
  errors: any[];
}

export interface TotalPricesResponse {
  grossValue: string;
  netValue: string;
  totalTaxes: string;
  netValueWithoutFreight: string;
  shippingCost: string;
  calculationSchema: string;
}

export interface CrmTotalPricesResponse {
  success: boolean;
  data: { TOTAL_PRICES: TotalPricesResponse };
  errors: any[];
}

export interface OrganizationalDataResponse {
  salesOrganizationalUnit: string;
  salesOrganization: string;
  distributionChannel: string;
  salesArea: string;
  salesOffice: string;
  salesTeam: string;
}
export interface CrmOrganizationalDataResponse {
  success: boolean;
  data: { ORGANIZATIONAL_DATA: OrganizationalDataResponse };
  errors: any[];
}

export interface ExpeditionResponse {
  shipToPart: string;
  shipToPartAddress: string;
  incoTerms1: string;
  incoTerms2: string;
  shippingConditions: string;
  shippingMode: string;
  shippingPriority: string;
  shippingStatus: string;
  supplyLockStatus: string;
  supplyComplete: string;
  groupOrders: string;
  maximumRemittances: number;
}
export interface CrmExpeditionResponse {
  success: boolean;
  data: { EXPEDITION: ExpeditionResponse };
  errors: any[];
}

export interface PeopleInvolvedResponse {
  function: string;
  id: number;
  name: string;
}
export interface CrmPeopleInvolvedResponse {
  success: boolean;
  data: { PEOPLE_INVOLVED: PeopleInvolvedResponse[] };
  errors: any[];
}

export interface CrmDetailsResponseComplain {
  OPPORTUNITY_DETAILS: OpportunityDetailsResponseComplain;
  ITEMS: ItemsResponse[];
  ATTACHED_FILES: AttachedFieldsResponse[];
  NOTES: NotesFieldsResponse[];
  HISTORY: HistoryResponse[];
  TOTAL_PRICES: TotalPricesResponse;
}

export interface GetCrmParams {
  id: string;
  enabled: boolean;
  authorizationToken: string;
  accessToken: string;
}

export interface DatesResponse {
  type: string;
  date: string;
}
export interface CrmDatesResponse {
  success: boolean;
  data: { DATES: DatesResponse[] };
  errors: any[];
}

const mapInfosData = (data, type?: string) => {
  return data.map(item => {
    return {
      label: item[0],
      value: type === 'arrayType' ? [item[1]] : item[1],
    };
  });
};

export const mapOpportunityDetailsData = data => {
  const dataInfo = Object.entries(data);
  const dataInfoItems = Object.entries(dataInfo[0][1]);

  const formatedData = {
    key: dataInfo[0][0],
    GENERAL_DATA: {
      label: dataInfoItems[0][0],
      infos: mapInfosData(Object.entries(dataInfoItems[0][1])),
    },
    DATES: {
      label: dataInfoItems[1][0],
      infos: mapInfosData(Object.entries(dataInfoItems[1][1])),
    },
    CYCLE_SALES: {
      label: dataInfoItems[2][0],
      infos: mapInfosData(Object.entries(dataInfoItems[2][1])),
    },
    CLASSIFICATION: {
      label: dataInfoItems[3][0],
      infos: mapInfosData(Object.entries(dataInfoItems[3][1])),
    },
  };

  return formatedData;
};

export const mapOpportunityDetailsDataComplain = data => {
  const dataInfo = Object.entries(data);
  const dataInfoItems = Object.entries(dataInfo[0][1]);

  const formatedData = {
    key: dataInfo[0][0],
    GENERAL_DATA: {
      label: dataInfoItems[0][0],
      infos: mapInfosData(Object.entries(dataInfoItems[0][1])),
    },
    DATES: {
      label: dataInfoItems[1][0],
      infos: mapInfosData(Object.entries(dataInfoItems[1][1])),
    },
    WORKFLOW: {
      label: dataInfoItems[2][0],
      infos: mapInfosData(Object.entries(dataInfoItems[2][1])),
    },
    PROCESSING_DATA: {
      label: dataInfoItems[3][0],
      infos: mapInfosData(Object.entries(dataInfoItems[3][1])),
    },
    CATEGORIZATION: {
      label: dataInfoItems[4][0],
      infos: mapInfosData(Object.entries(dataInfoItems[4][1])),
    },
    QUESTIONS: {
      label: dataInfoItems[5][0],
      infos: mapInfosData(Object.entries(dataInfoItems[5][1])),
    },
  };

  return formatedData;
};

export const mapManufacturingConsultationData = data => {
  const dataInfo = Object.entries(data);
  const formatedData = {
    key: dataInfo[0][0],
    infos: mapInfosData(Object.entries(dataInfo[0][1])),
  };

  return formatedData;
};

export const mapNotesData = data => {
  const dataInfo = Object.entries(data);
  const formatedData = {
    key: dataInfo[0][0],
    infos: mapInfosData(Object.entries(dataInfo[0][1]), 'arrayType'),
  };

  return formatedData;
};

export const mapAttachedFilesData = data => {
  const dataInfo = Object.entries(data);
  const formatedData = {
    key: dataInfo.length > 0 ? dataInfo[0][0] : '',
    infos: dataInfo.length > 0 ? mapInfosData(Object.entries(dataInfo[0][1]), 'arrayType') : [],
  };

  return formatedData;
};

export const mapPlantsData = data => {
  const dataInfo = Object.entries(data);

  const formatedData = {
    key: dataInfo.length > 0 ? dataInfo[0][0] : '',
    infos: dataInfo.length > 0 ? mapInfosData(Object.entries(dataInfo[0][1])) : [],
  };

  return formatedData;
};

export const mapAllCachedData = data => {
  const formatedData = {
    OPPORTUNITY_DETAILS: mapOpportunityDetailsData({ OPPORTUNITY_DETAILS: data.OPPORTUNITY_DETAILS }),
    MANUFACTURING_CONSULTATION: mapManufacturingConsultationData({
      MANUFACTURING_CONSULTATION: data.MANUFACTURING_CONSULTATION,
    }),
    NOTES: mapNotesData({ NOTES: data.NOTES }),
    ATTACHED_FILES: data.ATTACHED_FILES.length > 0 && mapAttachedFilesData({ ATTACHED_FILES: data.ATTACHED_FILES }),
    PLANTS: mapPlantsData({ PLANTS: data.PLANTS }),
  };

  return formatedData;
};

export const mapData = data => {
  const dataInfo = Object.entries(data);
  const formatedData = {
    key: dataInfo[0][0],
    infos: mapInfosData(Object.entries(dataInfo[0][1])),
  };

  return formatedData;
};
