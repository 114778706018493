import { IMultipleValidation } from '@app/data/http/order-input.dto';
import { MaterialErrorMessage } from './material.model';
import { QuoteFilterData } from './quote-filters.model';

export interface AnalysisParams {
  quoteIsInternalUser: boolean;
  filters?: QuoteFilterData;
  filtersParams?: QuoteFilterData;
  language?: string;
  authorizationToken?: string;
  accessToken?: string;
}

export interface ChatAnalysisParams {
  conversationId?: string | number;
  type?: string;
  language?: string;
  authorizationToken?: string;
  accessToken?: string;
}

export interface MakeVisibleAnalysisParams {
  ids: number[];
  clientVisible: boolean;
  language?: string;
}

export interface AnalysisResume {
  branding: string;
  client: {
    code: string;
    name: string;
    visible: boolean;
    cnpj?: string;
  };
  flow?: {
    label?: string;
    type?: string;
  };
  createdDate: Date;
  formattedId: string;
  id: number;
  hasPrice?: boolean;
  plants: string;
  priority: {
    label: string;
    date: Date;
  };
  product: {
    shape?: string;
    cornerRadius?: string;
    diameter?: string;
    side?: string;
    steelName?: string;
    thickness?: string;
    width?: string;
    dimensional?: string;
    type?: string;
    surfaceFinish?: string;
    heatTreatment?: string;
    gerdauProcess?: string;
    dimensions?: DimensionsResume[];
  };
  specifications?: string;
  status: {
    external: {
      key: string;
      label: string;
    };
    internal: {
      key: string;
      label: string;
    };
  };
  title: string;
  userCreator: {
    email: string;
    firstName: string;
    lastName: string;
    preferredLanguage: string;
    profile: string;
    type: string;
  };
  key?: string;
  priceValidityDate?: string;
}

export interface AnalysisUserStatus {
  createdDate: Date;
  internal: AnalysisStatus;
  external: AnalysisStatus;
}

export interface AnalysisStatus {
  key: string;
  label: string;
}

export interface AnalysisResponseData {
  data: AnalysisResponse;
}

export interface AnalisysResponseProduct {
  cornerRadius?: string;
  diameter?: string;
  shape?: string;
  side?: string;
  thickness?: string;
  width?: string;
  steelName?: string;
}
export interface AnalysisResponse {
  branding: string;
  client: {
    code: string;
    name: string;
    visible: boolean;
    cnpj: string;
  };
  createdDate: Date;
  formattedId: string;
  id: number;
  plants: string;
  priority: {
    label: string;
    date: Date;
  };
  product: {
    shape?: string;
    steelName?: string;
    dimentional?: AnalisysResponseProduct;
    diameter?: string;
    dimensions?: [];
    gerdauProcess?: string;
    heatTreatment?: string;
    side?: string;
    surfaceFinish?: string;
    thickness?: string;
    type?: string;
    width?: string;
  };
  specifications?: string;
  status: {
    external: {
      key: string;
      label: string;
    };
    internal: {
      key: string;
      label: string;
    };
  };
  title: string;
  userCreator: {
    email: string;
    firstName: string;
    lastName: string;
    preferredLanguage: string;
    profile: string;
    type: string;
  };

  date: string;
  user: {
    email: string;
    profile: string;
  };
  flow: {
    type: string;
    label: string;
  };
  query?: {
    CLIENTE: string;
    STEEL_NAME: string;
    PRODUTO: string;
    FORMA: string;
    TRATAMENTO_TERMICO: string[];
    ACABAMENTO: string;
    BITOLA: number;
    LADO: number;
    ESPESSURA: number;
    LARGURA: number;
    PRIORITY: string;
  };
  formatted_id: string;
  hasPrice?: boolean;
  legacyMetadata?: LegacyMetadata;
  clientVisible?: boolean;
  priorityDate?: string;
  priceValidityDate?: string;
}

export interface LegacyMetadata {
  formatted_id: string;
}

export interface SimilarMaterials {
  columns: SimilarMaterialsCol[];
  rows: SimilarMaterialsItem[];
}

export interface SimilarMaterialsItem {
  [key: string]: string;
}
export interface SimilarMaterialsCol {
  key: string;
  label: string;
  type: string;
  colSpan: number;
  rowSpan: number;
  isVisible: boolean;
  isEditable: boolean;
  isDelible: boolean;
}

export interface SimilarMaterialsResponse {
  columns: SimilarMaterialsColResponse[];
  rows: SimilarMaterialsRowResponse[];
}

export interface SimilarMaterialsColResponse {
  key: string;
  label: string;
  type: string;
  colSpan: number;
  rowSpan: number;
  isVisible: boolean;
  isEditable: boolean;
  isDelible: boolean;
}

export interface SimilarMaterialsRowResponse {
  row: SimilarMaterialsRow[];
}

export interface SimilarMaterialsRow {
  key: string;
  columnRef: string;
  value: string;
  colSpan: number;
  rowSpan: number;
}

export interface CreateOrderParams {
  language?: string;
  customerName: string;
  customerEmail: string;
  corpGroup: string;
  companyName: string;
  requestOrigin: string;
  orderID: string;
  codeMaterial: string;
  customerId: string;
  customerCnpj: string;
  implantation: any[];
  authorizationToken: string;
  accessToken: string;
}

export interface CreateOrderParamsDTO {
  customerName: string;
  customerEmail: string;
  corpGroup: string;
  companyName: string;
  requestOrigin: string;
  orderID: string;
  codeMaterial: string;
  customerId: string;
  customerCnpj: string;
  implantation: any[];
}

export interface DimensionsResume {
  dimensionId?: string;
  comprimentoMax?: number;
  comprimentoMin?: number;
  multiploComprimento?: number;
  multiploComprimentoMin?: number;
  multiploComprimentoMax?: number;
  consumo?: number;
  diametro?: number;
  lado?: number;
  espessura?: number;
  largura?: number;
}

export interface DimensionOption {
  id: string;
  value: string;
}

export interface ProductDimensionOrder {
  tempId: number;
  salesDocumentType: string;
  salesOrganization: string;
  activitySector: string;
  orderNumber: string;
  referenceOrderItem: string;
  requestDate: string;
  issuer: string;
  receiver: string;
  plantCode: string;
  material: string;
  partNumber: string;
  requestedQuantity: string;
  unitMeasure: string;
  shippingDate: string;
  steelName: string;
  steelShape: string;
  steelStandard: string;
  steelHeatTreatment: string;
  productType: string;
  steelProcess: string;
  steelFinish: string;
  referenceIsa: string;
  steelDiameter: string;
  steelMinLength: string;
  steelMaxLength: string;
  steelMultLength: string;
  steelMultLengthMin: string;
  steelMultLengthMax: string;
  steelSide: string;
  steelThickness: string;
  steelWidth: string;
  messages?: MaterialErrorMessage[];
  multipleValidation?: IMultipleValidation;
}

export interface ClientDetail {
  bp: string;
  city: string;
  corporateGroup: string;
  country: string;
  district: string;
  formatList: string;
  market: string;
  name: string;
  salesOrganization: string;
  state: string;
  type: string;
}

export enum CreateOrderPhase {
  Edit = 'EDIT',
  Review = 'REVIEW',
}
