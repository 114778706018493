import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { FullAnalysisResponse } from '@app/data/http/quote.dto';

export const postComments = async (params?: any) => {
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    language: params.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  const dataPost = params.isClient
    ? { value: params.comment }
    : { value: params.comment, client_visible: params.isClientVisible };

  const url = params.replicateComment
    ? `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
        endpoint.ANALYSIS_PLANT
      }${endpoint.ANALYSIS_ATTRIBUTE}/${params.attributeKey}${endpoint.ANALYSIS_COMMENTS}`
    : `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
        endpoint.ANALYSIS_PLANT
      }/${params.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.attributeKey}${endpoint.ANALYSIS_COMMENTS}`;

  const { data } = await axios.post(url, dataPost, { headers: defaultHeaders });

  return data;
};

export const usePostComment = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(postComments, {
    onSuccess,
    onError,
    onMutate: async () => {
      await queryClient.cancelQueries(['getTechnicalAnalysis']);
      const previousData = queryClient.getQueriesData(['getTechnicalAnalysis']);
      queryClient.setQueriesData(['getTechnicalAnalysis'], (oldQueryData: FullAnalysisResponse) => {
        return {
          ...oldQueryData,
        };
      });
      return {
        previousData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries(['getTechnicalAnalysis']);
    },
  });
};
