import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { FullAnalysisResponse } from '@app/data/http/quote.dto';

export const editComments = async (params?: any) => {
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    language: params.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  //value: params.editedComment

  const dataPost = { value: params.editedComment };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_PLANT
  }/${params.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.attributeKey}${endpoint.ANALYSIS_COMMENTS}/${
    params.commentKey
  }`;

  const { data } = await axios.patch(url, dataPost, { headers: defaultHeaders });

  return data;
};

export const useEditComment = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(editComments, {
    onSuccess,
    onError,
    onMutate: async () => {
      await queryClient.cancelQueries(['getTechnicalAnalysis']);
      const previousData = queryClient.getQueriesData(['getTechnicalAnalysis']);
      queryClient.setQueriesData(['getTechnicalAnalysis'], (oldQueryData: FullAnalysisResponse) => {
        return {
          ...oldQueryData,
        };
      });
      return {
        previousData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries(['getTechnicalAnalysis']);
    },
  });
};
