import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEST_ID_CLOSE_MODAL } from '@app/constants';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import {
  IA_READ_WARNING_IMG_RELATIVE_PATH,
  IA_READ_WARNING_KEY,
  OrderInputPhaseEnum,
} from '@app/modules/order-input/order-input.constants';
import { IOrderInputStrings, OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { putIAReadWarning } from '@app/modules/order-input/store/thunks/put-ia-read-warning.thunk';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { BrandColor, Color, FontSize, Spacing } from '@atomic/obj.constants';
import { Modal } from '@atomic/obj.modal';
import {
  IAReadWarningModalBody,
  IAReadWarningModalBodyWrapper,
  IAReadWarningModalFooter,
  IAReadWarningModalHeader,
  IAReadWarningModalImageWrapper,
  IAReadWarningModalTextWrapper,
  IAReadWarningModalTitleWrapper,
  IAReadWarningModalWrapper,
} from './ia-read-warning-modal.style';

export const IAReadWarningModal: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { phase, hasIAReadWarningAnnouncement, loadingAnnouncement } = useSelector(
    (state: RootState) => state.orderInput,
  );
  const dispatch = useDispatch<AppThunkDispatch>();
  const strings: IOrderInputStrings = OrderInputStrings[userInfo?.language];

  const handleClose = () => {
    dispatch(putIAReadWarning({ key: IA_READ_WARNING_KEY, email: userInfo?.email }));
  };

  return (
    <Modal small opened={phase === OrderInputPhaseEnum.DataAndAmout && hasIAReadWarningAnnouncement}>
      <IAReadWarningModalHeader>
        <IAReadWarningModalTitleWrapper>
          <FaIcon.Custom icon='exclamation-triangle' color={BrandColor.Blue} />
          <Body ml={Spacing.Small} bold={700} color={Color.Black} size={FontSize.Large}>
            {strings.iaReadWarning.modalTitle}
          </Body>
        </IAReadWarningModalTitleWrapper>
      </IAReadWarningModalHeader>
      <IAReadWarningModalWrapper>
        <IAReadWarningModalBody>
          <IAReadWarningModalBodyWrapper>
            <IAReadWarningModalTextWrapper>
              <Body bold={700} size={FontSize.Medium}>
                {strings.iaReadWarning.title}
              </Body>
              <Body mt={Spacing.Medium} size={FontSize.Medium}>
                {strings.iaReadWarning.messageStart}
              </Body>
              <Body mt={Spacing.Medium} size={FontSize.Medium}>
                {strings.iaReadWarning.messageEnd}
              </Body>
            </IAReadWarningModalTextWrapper>
            <IAReadWarningModalImageWrapper>
              <img width='200px' src={IA_READ_WARNING_IMG_RELATIVE_PATH + 'order-input-ai-read-review-alert.png'} />
            </IAReadWarningModalImageWrapper>
          </IAReadWarningModalBodyWrapper>
          <IAReadWarningModalFooter>
            <FlexRow hAlign='center'>
              <Button
                testid={`${TEST_ID_CLOSE_MODAL}-ia-read-warning`}
                loading={loadingAnnouncement}
                onClick={() => handleClose()}
              >
                {strings.iaReadWarning.confirmButton}
              </Button>
            </FlexRow>
          </IAReadWarningModalFooter>
        </IAReadWarningModalBody>
      </IAReadWarningModalWrapper>
    </Modal>
  );
};
