import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { AttributeComments } from '@app/models/quote.model';
import { quoteBadges } from '@app/modules/quote/quote.contants';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { Avatar } from '@atomic/atm.avatar';
import { Badge } from '@atomic/atm.badge';
import { Divisor } from '@atomic/atm.divisor';
import { FaIcon } from '@atomic/atm.fa-icon';
import { TextField } from '@atomic/atm.text-field';
import { Body, BodySecondary, H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color, Keys } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { QuoteStrings } from '../../quote.string';

interface CommentCellProps {
  comment: AttributeComments;
  showAvatar: boolean;
  onDeleteComment: () => void;
  onEditComment: (value: string) => void;
  loading: boolean;
  canAddComments?: boolean;
  resume?: boolean;
  blockCommentButtons?: boolean;
  blockButton: boolean;
}

export const CommentCell: React.FC<CommentCellProps> = props => {
  const [editing, setEditing] = React.useState(false);
  const [currentComment, setCurrentComment] = React.useState(props.comment.value);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = TechnicalAnalysisStrings[userInfo.language].comment;
  const statusStrings = QuoteStrings[userInfo.language].constants.stage;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === Keys.Enter) {
      props.onEditComment(currentComment);
      setCurrentComment(props.comment.value);
      setEditing(false);
    }
  };

  return (
    <LoadingState loading={props.loading} enableActivityIndicator={true}>
      <Hbox hAlign='flex-end'>
        {props.comment.permissions.isEditable &&
          !editing &&
          props.canAddComments &&
          !props.resume &&
          !props.blockCommentButtons &&
          !props.blockButton && (
            <>
              <Hbox.Item noGrow>
                <FaIcon.Custom
                  onClick={() => setEditing(true)}
                  clickable='false'
                  size='1x'
                  icon='pen'
                  color={Color.Primary}
                />
              </Hbox.Item>
              <Hbox.Separator />
            </>
          )}
        {props.comment.permissions.isDelible &&
          !editing &&
          props.canAddComments &&
          !props.resume &&
          !props.blockCommentButtons &&
          !props.blockButton && (
            <Hbox.Item noGrow>
              <FaIcon.Custom
                onClick={props.onDeleteComment}
                clickable='true'
                size='1x'
                icon='trash-alt'
                color={Color.Alert}
              />
            </Hbox.Item>
          )}
        {editing && (
          <Hbox.Item noGrow>
            <FaIcon.Close onClick={() => setEditing(false)} clickable='true' size='1x' color={Color.Alert} />
          </Hbox.Item>
        )}
      </Hbox>
      <Hbox>
        {props.showAvatar && props.comment.createdBy.firstName.length > 0 && (
          <>
            <Hbox.Item noGrow>
              <Avatar direction='none' type='neutral'>
                {props.comment.createdBy.firstName[0]}
                {props.comment.createdBy.lastName[0]}
              </Avatar>
            </Hbox.Item>
            <Hbox.Separator />
          </>
        )}
        <Hbox.Item>
          <H3>{props.comment.createdBy.email}</H3>
          <VSeparator small />
          <Hbox>
            <Hbox.Item vAlign='center' noGrow>
              <BodySecondary>{props.comment.createdDate}</BodySecondary>
            </Hbox.Item>
            <Hbox.Separator />
            <Hbox.Item noGrow>
              {props?.comment?.creationStatus?.key !== '' && (
                <Badge color={quoteBadges[props.comment.creationStatus.key]?.color}>
                  {statusStrings.label[props.comment.creationStatus.key]}
                </Badge>
              )}
            </Hbox.Item>
          </Hbox>
          <VSeparator small />

          {editing ? (
            <TextField
              hasButton
              initialValue={props?.comment?.value}
              onValueChange={value => setCurrentComment(`${value}`)}
              placeholder={strings.placeholder}
              onKeyDown={handleKeyDown}
            />
          ) : (
            <Body>{props.comment.value}</Body>
          )}
        </Hbox.Item>
      </Hbox>
      <VSeparator />

      <Divisor />
      <VSeparator />
    </LoadingState>
  );
};
