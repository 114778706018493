import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Divisor } from '@atomic/atm.divisor';
import { Body } from '@atomic/atm.typography';
import { FlexRow, Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { IOrderTabOpenInternationalStrings, Strings } from '../../../order-tab-open-international.string';
import {
  OrderStatusPopoverArrowStyled,
  OrderStatusPopoverBoxStyled,
  OrderStatusPopoverDotStyled,
  OrderStatusPopoverFooterStyled,
  OrderStatusPopoverRowStyled,
  OrderStatusPopoverWrapper,
} from './order-me-status-rd-popover.style';

interface OrderStatusPopoverProps {
  data: any;
  x: number;
  y: number;
}

export const OrderMEStatusRDPopover: React.FC<OrderStatusPopoverProps> = props => {
  const ref = useRef(null);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IOrderTabOpenInternationalStrings = Strings[userInfo?.language];

  if (!props.data) {
    return null;
  }

  return (
    <OrderStatusPopoverWrapper ref={ref} x={props.x} y={props.y}>
      <OrderStatusPopoverBoxStyled>
        <OrderStatusPopoverRowStyled>
          <FlexRow vAlign='center'>
            <OrderStatusPopoverDotStyled color='#47A947' />
            <Hbox.Separator small />
            <Body bold='bold'>{strings.tooltip.shipped}</Body>
          </FlexRow>
          <FlexRow>
            <Body>{`${props.data.qtyShipped?.toString().replace('.', ',')}t`}</Body>
          </FlexRow>
        </OrderStatusPopoverRowStyled>
        <OrderStatusPopoverRowStyled>
          <FlexRow vAlign='center'>
            <OrderStatusPopoverDotStyled color='#3399CC' />
            <Hbox.Separator small />
            <Body bold='bold'>{strings.tooltip.frontier}</Body>
          </FlexRow>
          <FlexRow>
            <Body>{`${props.data.qtyInFrontier?.toString().replace('.', ',')}t`}</Body>
          </FlexRow>
        </OrderStatusPopoverRowStyled>
        <OrderStatusPopoverRowStyled>
          <FlexRow vAlign='center'>
            <OrderStatusPopoverDotStyled color='#FECB2F' />
            <Hbox.Separator small />
            <Body bold='bold'>{strings.tooltip.stock}</Body>
          </FlexRow>
          <FlexRow>
            <Body>{`${props.data.qtyTotalStock?.toString().replace('.', ',')}t`}</Body>
          </FlexRow>
        </OrderStatusPopoverRowStyled>
        <OrderStatusPopoverRowStyled>
          <FlexRow vAlign='center'>
            <OrderStatusPopoverDotStyled color='#A480A3' />
            <Hbox.Separator small />
            <Body bold='bold'>{strings.tooltip.stockPend}</Body>
          </FlexRow>
          <FlexRow>
            <Body>{`${props.data.qtyStockPend?.toString().replace('.', ',')}t`}</Body>
          </FlexRow>
        </OrderStatusPopoverRowStyled>
        <VSeparator />
        <Divisor />
        <OrderStatusPopoverFooterStyled>
          <FlexRow vAlign='center'>
            <Body>{strings.tooltip.requiredTons}</Body>
            <Body bold='bold'>{`${Math.round(props.data.qtyCustomerRequiredTons)
              .toString()
              .replace('.', ',')}t`}</Body>
          </FlexRow>
          <FlexRow vAlign='center'>
            <Body>{strings.tooltip.agreededTons}</Body>
            <Body bold='bold'>{`${Math.round(props.data.qtyAgreedTons)
              .toString()
              .replace('.', ',')}t`}</Body>
          </FlexRow>
        </OrderStatusPopoverFooterStyled>
        <OrderStatusPopoverArrowStyled xAxis={100} alignBottom={true} />
      </OrderStatusPopoverBoxStyled>
    </OrderStatusPopoverWrapper>
  );
};
