import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { DescriptiveAnalysisData } from '@app/models/quote.model';
import { Badge } from '@atomic/atm.badge';
import { Body, H3 } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { QuoteStrings } from '../../quote.string';
import { TechnicalAnalysisStrings } from '../technical-analysis.string';
import { DescriptiveAnalysisTableStyled, DescriptiveAnalysisTextStyled } from './descriptiveAnalysis.component.style';

interface DescriptiveAnalysisProps {
  attrStatus: string;
  data: DescriptiveAnalysisData | DescriptiveAnalysisData[];
}

export const DescriptiveAnalysisLength: React.FC<DescriptiveAnalysisProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const statusBadge = QuoteStrings[userInfo.language].constants.statusAttributes;
  const strings = TechnicalAnalysisStrings[userInfo.language].attributes.attributeCell.descriptiveAnalysis;

  const attributes = props.data && Object.entries(props.data);

  return (
    attributes &&
    props.attrStatus !== 'accepted' && (
      <Hbox.Item>
        <H3>{strings.title}</H3>
        <VSeparator small />

        <DescriptiveAnalysisTableStyled>
          <Table collapse>
            {attributes.map(item => {
              const [key, object] = item;
              return (
                object && (
                  <TR key={key} bordered sticky>
                    <TD color={Color.White}>
                      <Body>{strings[key]}</Body>
                    </TD>
                    <TD color={Color.White}>
                      {object.accepted ? (
                        <Body>
                          <Badge color='success'>
                            <strong>{statusBadge.accepted}:</strong>
                          </Badge>
                        </Body>
                      ) : (
                        <Body>
                          <DescriptiveAnalysisTextStyled>
                            <Badge color='warning'>{statusBadge.derogate}</Badge>
                            {object.derogate.reason}. <strong>{strings.suggestion}</strong>{' '}
                            {` ${object.derogate.suggestion.join()}`}
                          </DescriptiveAnalysisTextStyled>
                        </Body>
                      )}
                    </TD>
                  </TR>
                )
              );
            })}
          </Table>
        </DescriptiveAnalysisTableStyled>

        <VSeparator />
      </Hbox.Item>
    )
  );
};
