import React from 'react';
import { useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { AssetData, FullAnalysisData } from '@app/models/quote.model';
import { quoteIsInternalUserSelector } from '@app/modules/auth/store/auth.selectores';
import { TechnicalAnalysisAssetsEvents } from '@app/modules/quote/quote.analytics';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis-copy/technical-analysis.string';
import { Button } from '@atomic/atm.button';
import { CheckboxField } from '@atomic/atm.checkbox';
import { Dropzone } from '@atomic/atm.dropzone';
import { Frame } from '@atomic/atm.frame';
import { Body, H2, H3 } from '@atomic/atm.typography';
import { DefaultListCell } from '@atomic/mol.default-list-cell';
import { Hbox } from '@atomic/obj.box';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { Modal } from '@atomic/obj.modal';
import { useDownloadAsset } from '../hooks/useDownloadAsset';
import { AssetCell } from './asset-cell';
import { TechnicalAnalysisAssetsContext } from './technical-analysis-assets.context';
import { TechnicalAnalysisAssetsShimmer } from './technical-analysis-assets.shimmer';

interface TechnicalAnalysisAssetsModalProps {
  opened: boolean;
  assets: AssetData[];
  onClose: () => void;
  analysisId: number;
  analysis: FullAnalysisData;
  accessToken: string;
  showLabel?: boolean;
}

export const TechnicalAnalysisAssetsModal: React.FC<TechnicalAnalysisAssetsModalProps> = props => {
  const { userInfo, azureToken, token } = useSelector((state: RootState) => state.auth);
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const strings = TechnicalAnalysisStrings[userInfo.language].assets.assetModal;

  const {
    loading: contextLoading,
    assets,
    selectedAssets,
    handleBatchSelection,
    setAssets,
    handlePost,
    handleChangeAssetVisibility,
    handleDeleteAssets,
  } = React.useContext(TechnicalAnalysisAssetsContext);

  const { mutate: handleDownload, isLoading: getAssetLoading } = useDownloadAsset();

  React.useEffect(() => {
    if (props.analysis) {
      setAssets(props.assets);
    }
  }, [props.analysis]);

  const hasData = !!assets && assets.length > 0 && (quoteIsInternalUser || assets.some(asset => asset.isClientVisible));

  const exhibitionAssets = quoteIsInternalUser ? assets : assets.filter(asset => asset.isClientVisible);

  const waitDownloadAll = () => {
    LogAnalytics.click({ tipo: TechnicalAnalysisAssetsEvents.BatchDownload });

    selectedAssets.forEach(asset => {
      handleDownload({
        filename: asset.filename,
        url: asset.url,
        type: asset.type,
        language: userInfo.language,
        authorizationToken: azureToken,
        accessToken: token,
      });
    });
  };

  return (
    <Modal opened={props.opened} onClose={props.onClose}>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <H2>{strings.title}</H2>
            {props.showLabel && <Body>{strings.label}</Body>}
            <VSeparator />
            <LoadingState loading={contextLoading || getAssetLoading} data={hasData}>
              <LoadingState.Shimmer>
                <TechnicalAnalysisAssetsShimmer />
              </LoadingState.Shimmer>

              <LoadingState.NoData>
                {props.analysis?.permissions.assetsEditable && <Dropzone onDropAccepted={handlePost} />}
                <VSeparator />

                <DefaultListCell bg>
                  <Body>{strings.noData}</Body>
                </DefaultListCell>
                <VSeparator />
              </LoadingState.NoData>
              {props.analysis?.permissions.assetsEditable && <Dropzone onDropAccepted={handlePost} />}
              <VSeparator />

              <Frame>
                <DefaultListCell bg>
                  <Hbox>
                    <Hbox.Item noGrow>
                      <CheckboxField
                        id='selectAll'
                        checked={selectedAssets.length === exhibitionAssets.length}
                        onValueChange={(_, checked) => handleBatchSelection(exhibitionAssets, checked)}
                      />
                    </Hbox.Item>
                    <Hbox.Separator small />
                    <Hbox.Item>
                      <H3>{strings.selectAll}</H3>
                    </Hbox.Item>
                  </Hbox>
                </DefaultListCell>
                {exhibitionAssets?.map(asset => (
                  <AssetCell
                    key={asset.key}
                    asset={asset}
                    onDeleteAssets={() =>
                      handleDeleteAssets({
                        assetKey: asset.key,
                        analysisId: props.analysisId,
                        language: userInfo.language,
                        authorizationToken: azureToken,
                        accessToken: token,
                      })
                    }
                    onSwitchValueChanged={() => handleChangeAssetVisibility(asset.key, !asset.isClientVisible)}
                  />
                ))}
              </Frame>
              <VSeparator />
              <Hbox>
                {selectedAssets.length > 0 && (
                  <Hbox.Item hAlign='flex-start'>
                    <Button kind='secondary' onClick={waitDownloadAll}>
                      {strings.downloadAll(selectedAssets.length)}
                    </Button>
                  </Hbox.Item>
                )}
                <Hbox.Item hAlign='flex-end'>
                  <Button kind='primary' onClick={props.onClose}>
                    {strings.close}
                  </Button>
                </Hbox.Item>
              </Hbox>
              <VSeparator />
            </LoadingState>
          </Col>
        </Row>
      </Grid>
    </Modal>
  );
};
