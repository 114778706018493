import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { IsaHttpRequestBuilder } from '@app/data/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export async function downloadAttachedFile(params?: any): Promise<any> {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    language: user.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  const { data, status } = await axios.get(params.url, {
    headers: defaultHeaders,
    responseType: 'arraybuffer',
  });

  if (status === 200) {
    const blob = new Blob([data], { type: params.type });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = params.filename;
    link.click();
  }
}

export const useDownloadAttachedFile = () => {
  return useMutation(downloadAttachedFile, {});
};
