import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';

export const getImageDerogateMutate = async (params?: any) => {
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    language: params.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.data.analysisId}${
    endpoint.ANALYSIS_PLANT
  }/${params.data.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.data.attributeKey}`;

  const { data: dataResponse } = await axios.get(url, { headers: defaultHeaders });

  return dataResponse.derogate.images;
};

export const useGetImageDerogateMutate = (onSuccess?, onError?) => {
  return useMutation(getImageDerogateMutate, {
    onSuccess,
    onError,
    retry: false,
  });
};
