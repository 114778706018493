import Container from 'typedi';
import Cookies from 'universal-cookie';
import { getAzureTokenThunk } from '@app/modules/auth/store/thunk/get-azure-token.thunk';
import { isTokenExpired } from '@app/modules/auth/utils/utils';
import { AuthStorageService } from '../../../../../data/storage/auth.storage';
import { IAuthState } from '../../../../auth/auth.interfaces';
import { buildUserData } from '../../../../auth/store/thunk/initialize-and-get-profile.thunk';
import { refreshTokenRequest } from '../../../../auth/store/thunk/refresh-token.thunk';

const authStorageService: AuthStorageService = Container.get(AuthStorageService);

export const validateTokensAndGetNotification = () => async (dispatch, getState) => {
  const { userInfo, ssoAccess, azureDateTimeExpiration, azureToken }: IAuthState = getState().auth;

  if (isTokenExpired(azureDateTimeExpiration) || !azureToken) {
    await dispatch(getAzureTokenThunk());
    const cookies = new Cookies();
    cookies.set('@authorization-token', azureToken, { domain: '.egerdau.com.br' });
    cookies.set('@authorization-token-expiration', azureDateTimeExpiration, { domain: '.egerdau.com.br' });

    if (ssoAccess) {
      await dispatch(refreshTokenRequest());
      const { token }: IAuthState = getState().auth;
      cookies.set('@access_token', token, { domain: '.egerdau.com.br' });
      authStorageService.login(buildUserData(token, userInfo, ssoAccess));
    }
  }

  return;
};

export const validateTokens = () => async (dispatch, getState) => {
  const { userInfo, ssoAccess, azureDateTimeExpiration, azureToken }: IAuthState = getState().auth;

  if (isTokenExpired(azureDateTimeExpiration) || !azureToken) {
    await dispatch(getAzureTokenThunk());
    const cookies = new Cookies();
    cookies.set('@authorization-token', azureToken, { domain: '.egerdau.com.br' });
    cookies.set('@authorization-token-expiration', azureDateTimeExpiration, { domain: '.egerdau.com.br' });

    if (ssoAccess) {
      await dispatch(refreshTokenRequest());
      const { token }: IAuthState = getState().auth;
      cookies.set('@access_token', token, { domain: '.egerdau.com.br' });
      authStorageService.login(buildUserData(token, userInfo, ssoAccess));
    }
  }

  return;
};
