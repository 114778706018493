import React from 'react';
import { useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { RootState } from '@app/core/redux/store';
import { QuotePlantDataSource } from '@app/data/http';
import { EditResponsibles, GetResponsibles } from '@app/data/http/quote-plant-params.dto';
import { UserData } from '@app/models/quote.model';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis-copy/technical-analysis.string';
import { FlashMessageTargetName } from '@app/providers';
import { Avatar } from '@atomic/atm.avatar';
import { Badge } from '@atomic/atm.badge';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Frame } from '@atomic/atm.frame';
import { Body, H3 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { Col, Row } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { TechnicalAnalysisEvents } from '../../quote.analytics';
import { quoteBadges } from '../../quote.contants';
import { QuoteStrings } from '../../quote.string';
import { TechnicalAnalysisContext } from '../technical-analysis.context';
import {
  AddFieldWrapper,
  RemoveFieldWrapper,
  TechnicalAnalysisGeneralInfoWrapper,
} from './technical-analysis-general-info.style';
import { ResponsibleFieldsComponent } from './technical-analysis-responsible-field.component';

export interface TechnicalAnalysisGeneralInfoProps {
  analysisId: number;
  quoteIsInternalUser: boolean;
}

export const TechnicalAnalysisGeneralInfo: React.FC<TechnicalAnalysisGeneralInfoProps> = props => {
  const { userInfo, azureToken, token } = useSelector((state: RootState) => state.auth);
  const [showNewField, setShowNewField] = React.useState<boolean>(false);
  const [responsibles, setResponsibles] = React.useState<UserData[]>([]);
  const [errorRemove, setErrorRemove] = React.useState(null);
  const { users, getUsers, newAnalysis, analysis } = React.useContext(TechnicalAnalysisContext);
  const strings = TechnicalAnalysisStrings[userInfo.language];
  const quoteStatus = QuoteStrings[userInfo.language];

  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  // React.useEffect(() => {
  //   if (props.analysis?.responsibles) {
  //     setResponsibles(props.analysis.responsibles);
  //   }
  // }, [props.analysis]);

  React.useEffect(() => {
    getUsers({ language: userInfo.language, profile: userInfo.profile.profile.toUpperCase(), bp: null });
  }, []);

  const handleGetResponsiblesSuccess = (response: UserData[]) => {
    setResponsibles(response);
    setErrorRemove(null);
  };

  const handleEditResponsiblesSuccess = () => {
    setErrorRemove(null);
  };

  const handleEditResponsiblesError = async response => {
    const errorMessage = response.response?.data?.message;
    show('alert', `${strings.components.messages.alert} ${errorMessage}`);
    LogAnalytics.error({ tipo: TechnicalAnalysisEvents.ResponsiblesChangeError });
    getResponsibles({ analysisId: props.analysisId, authorizationToken: azureToken, accessToken: token });
    if (response?.response?.statusText) {
      setErrorRemove(strings.editResponsiblesError);
    }
  };

  const { performRequest: getResponsibles, loading: loadingResponsibles } = useLazyRequest<GetResponsibles, UserData[]>(
    QuotePlantDataSource.getResponsibles,
    handleGetResponsiblesSuccess,
  );

  const { performRequest: editResponsibles, loading: loadingEditResponsibles } = useLazyRequest<
    EditResponsibles,
    UserData[]
  >(QuotePlantDataSource.editResponsibles, handleEditResponsiblesSuccess, handleEditResponsiblesError);

  const handleResponsibles = async (userRes: UserData, action: string) => {
    setShowNewField(false);
    if (action === 'remove') {
      const currentList = responsibles.filter(item => item.email !== userRes.email);
      setResponsibles(currentList);
    } else {
      setResponsibles([...responsibles, userRes]);
    }

    editResponsibles({
      analysisId: props.analysisId,
      responsible: userRes,
      action: action,
      authorizationToken: azureToken,
      accessToken: token,
    });
  };

  const handleShowNewField = () => {
    setShowNewField(false);
  };

  return (
    <Frame>
      <TechnicalAnalysisGeneralInfoWrapper>
        <Row data-testid='info-isa'>
          <Col xs={4}>
            <H3>{strings.isa}</H3>
          </Col>
          <Col xs={8}>
            <Hbox colWrap>
              <Body>{newAnalysis.formattedId}</Body>

              <Hbox.Separator />
              {/* {props.analysis?.newProductEpp && (
                <Hbox.Item noGrow>
                  <Badge color='purple'>{strings.header.newProductEpp}</Badge>
                </Hbox.Item>
              )} */}
            </Hbox>
          </Col>
        </Row>
        <Separator />
        {props.quoteIsInternalUser && (
          <>
            <Row data-testid='info-createdBy'>
              <Col xs={4}>
                <H3>{strings.creatorName}</H3>
              </Col>
              <Col xs={8}>
                <Hbox vAlign='center'>
                  <Avatar direction='none' type='neutral' small>
                    {newAnalysis.createdBy.firstName[0]}
                    {newAnalysis.createdBy.lastName[0]}
                  </Avatar>
                  <Body>{newAnalysis.createdBy.formattedName}</Body>
                </Hbox>
              </Col>
            </Row>
            <Separator />
            <Row data-testid='info-responsibles'>
              <Col xs={4}>
                <H3>{strings.currentResponsibles}</H3>
              </Col>
              <Col xs={8}>
                <LoadingState loading={loadingResponsibles || loadingEditResponsibles}>
                  <>
                    {responsibles.length === 0 &&
                    analysis.internalStatus.key.includes(userInfo.profile.profile.toUpperCase()) ? (
                      <AddFieldWrapper>
                        <Button
                          onClick={() => setShowNewField(true)}
                          title={strings.responsibles.add}
                          disabled={analysis?.internalStatus?.key === 'PROCESSING'}
                        >
                          <FaIcon.Plus size='1x' />
                        </Button>
                      </AddFieldWrapper>
                    ) : (
                      responsibles.map((resp, index) => (
                        <Hbox.Item key={resp.email}>
                          {index > 0 && <Separator small />}
                          <Hbox vAlign='center'>
                            <Avatar direction='none' type='neutral' small>
                              {resp.firstName[0]}
                              {resp.lastName[0]}
                            </Avatar>
                            <Body>{`${resp.firstName} ${resp.lastName}`}</Body>
                            {responsibles.length > 1 &&
                              (analysis.internalStatus.key.includes(userInfo.profile.profile.toUpperCase()) ||
                                (analysis.internalStatus.key === 'WAITING_CLIENT_REVIEW' &&
                                  userInfo.profile.profile.toUpperCase() === 'RTC')) && (
                                <RemoveFieldWrapper>
                                  <Button
                                    onClick={() => handleResponsibles(resp, 'remove')}
                                    title={strings.responsibles.remove}
                                  >
                                    <FaIcon.Close size='1x' />
                                  </Button>
                                </RemoveFieldWrapper>
                              )}
                            {!showNewField &&
                              index === responsibles.length - 1 &&
                              responsibles.length < 2 &&
                              (analysis.internalStatus.key.includes(userInfo.profile.profile.toUpperCase()) ||
                                (analysis.internalStatus.key === 'WAITING_CLIENT_REVIEW' &&
                                  userInfo.profile.profile.toUpperCase() === 'RTC')) && (
                                <AddFieldWrapper>
                                  <Button onClick={() => setShowNewField(true)} title={strings.responsibles.add}>
                                    <FaIcon.Plus size='1x' />
                                  </Button>
                                </AddFieldWrapper>
                              )}
                          </Hbox>
                        </Hbox.Item>
                      ))
                    )}{' '}
                    {users && showNewField && (
                      <>
                        <Separator small />
                        <ResponsibleFieldsComponent
                          key='newField'
                          initialValue={null}
                          users={users}
                          profile={null}
                          handleResponsibles={handleResponsibles}
                          handleShowNewField={handleShowNewField}
                        />
                      </>
                    )}
                    {errorRemove && (
                      <>
                        <Separator small />
                        <Body color={Color.Alert}>{errorRemove}</Body>
                      </>
                    )}
                  </>
                </LoadingState>
              </Col>
            </Row>
            <Separator />
          </>
        )}
        {/* {usersBrandPermissions.includes(userInfo.profile.profile.toUpperCase()) &&
        props.analysis.flowType !== 'CLIENT_MANUAL' &&
        props.analysis?.internalStatus?.key !== 'PROCESSING' ? (
          <TechnicalAnalysisBrands id={props.analysisId} brand={props.analysis.branding} />
        ) : (
          props.analysis.branding &&
          props.analysis.flowType !== 'CLIENT_MANUAL' && (
            <TechnicalAnalysisBrands id={props.analysisId} brand={props.analysis.branding} />
          )
        )}
        <Separator /> */}
        <Row data-testid='info-client'>
          <Col xs={4}>
            <H3>{strings.client}</H3>
          </Col>
          <Col xs={8}>
            <Body>{newAnalysis.client.formattedName}</Body>
          </Col>
        </Row>
        <Separator />
        {newAnalysis.priority && (
          <>
            <Row data-testid='info-priority'>
              <Col xs={4}>
                <H3>{strings.priority}</H3>
              </Col>
              <Col xs={8}>
                <Body>{newAnalysis.priority}</Body>
              </Col>
            </Row>
            <Separator />
          </>
        )}
        {props.quoteIsInternalUser && (
          <>
            <Row data-testid='info-internalStatus'>
              <Col xs={4}>
                <H3>{strings.gerdauStatus}</H3>
              </Col>
              <Col xs={8}>
                <Badge color={quoteBadges[analysis.internalStatus.key]?.color}>
                  {quoteStatus.constants.stage.label[analysis.internalStatus.key]}
                </Badge>
              </Col>
            </Row>
            <Separator />
          </>
        )}
        {analysis?.externalStatus?.label.length > 0 && (
          <>
            <Row data-testid='info-externalStatus'>
              <Col xs={4}>
                <H3>{strings.clientStatus}</H3>
              </Col>
              <Col xs={8}>
                <Badge color={quoteBadges[analysis.externalStatus.key]?.color}>
                  {quoteStatus.constants.stage.label[analysis.externalStatus.key]}
                </Badge>
              </Col>
              <Separator />
            </Row>
            <Separator />
          </>
        )}

        <Row data-testid='info-flow'>
          <Col xs={4}>
            <H3>{strings.flow}</H3>
          </Col>
          <Col xs={8}>
            <Body>{analysis?.flow}</Body>
          </Col>
          <Separator />
        </Row>
        <Separator />
        {props.quoteIsInternalUser && (
          <>
            <Row data-testid='info-responsibleEmail'>
              <Col xs={4}>
                <H3>{strings.email}</H3>
              </Col>
              <Col xs={8}>
                <LoadingState loading={loadingResponsibles}>
                  {users && (
                    <>
                      {responsibles.map((resp, index) => (
                        <Hbox.Item key={`email-${resp.email}`}>
                          {index > 0 && <Separator small />}
                          <Body>{resp.email}</Body>
                        </Hbox.Item>
                      ))}
                    </>
                  )}
                </LoadingState>
              </Col>
            </Row>
            <Separator />
            <Row data-testid='info-creationDate'>
              <Col xs={4}>
                <H3>{strings.date}</H3>
              </Col>
              <Col xs={8}>
                <Body>{newAnalysis.createdBy.date}</Body>
              </Col>
            </Row>
          </>
        )}
      </TechnicalAnalysisGeneralInfoWrapper>
    </Frame>
  );
};
