import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { GetAssetsParams } from '@app/data/http/quote-assets-params.dto';
import { GetLogResponse } from '@app/data/http/quote-assets.dto';
import { handleUnauthorized } from '@app/utils/http-utils';

export async function getLogAnalysis(params?: GetAssetsParams): Promise<GetLogResponse[]> {
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    'Access-Control-Allow-Origin': 'egerdau.com.br',
    language: params.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  try {
    const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
      endpoint.LOGS
    }`;

    const { data, status } = await axios.get(url, { headers: defaultHeaders });

    if (status === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    handleUnauthorized(error);
    return null;
  }
}

export function useGetLogAnalysis(params: GetAssetsParams, onSuccess?) {
  return useQuery(['getLogAnalysis', params], () => getLogAnalysis(params), {
    enabled: false,
    onSuccess,
    refetchOnWindowFocus: false,
  });
}
