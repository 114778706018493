/* eslint-disable complexity */
import * as React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import * as Scroll from 'react-scroll';
import { useStore } from '@app/core/global-store.service';
import { RootState } from '@app/core/redux/store';
import {
  CornerRadiusOptions,
  FilterType,
  FlowTypesKeys,
  FormTypesKeys,
  PlantTypesKeys,
  PlantTypesLabels,
  PriorityOptions,
  QuoteFilter,
  QuoteFilterData,
  QuoteFilters,
  RequisitionOptions,
} from '@app/models/quote-filters.model';
import { quoteIsInternalUserSelector } from '@app/modules/auth/store/auth.selectores';
import { UserStore, useUserStore } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { CheckboxField } from '@atomic/atm.checkbox';
import { FaIcon } from '@atomic/atm.fa-icon';
import { SelectField } from '@atomic/atm.select';
import { Tag } from '@atomic/atm.tag';
import { TagCheckboxGroup } from '@atomic/atm.tag-checkbox';
import { TextField } from '@atomic/atm.text-field';
import { H2, InputLabel } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Form, FormData, FormField, Validators } from '@atomic/obj.form';
import { Col, Row, VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { ClientList } from '../form/form.context';
import { useGetClientsList } from '../hooks/useGetClientsList';
import {
  FlowTypesLabels,
  FormTypesLabel,
  PriceOptions,
  ClientVisibleOptions,
  ProviderOptions,
  QuoteFilterLabelStrings,
  QuoteStrings,
} from '../quote.string';
import { QuoteClientList } from './quote-client-list.component';
import {
  ButtonsWrapper,
  HiddenFiltersWrapper,
  QuoteAdvancedFilterWrapper,
  CheckboxFieldWrapper,
  CheckboxSubFieldWrapper,
  WrapperFilterPlants,
} from './quoteAdvancedFilter.style';
import { QuoteAdvancedFilterSelect } from './quoteAdvancedFilterSelectComponent';

interface QuoteAdvancedFilterModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (data: QuoteFilterData) => void;
  handleFilterRemoval: (key: string) => void;
  initialFilters: QuoteFilterData;
  clients?: ClientList[];
}

const Option = ({ value, name, selected }) => {
  return (
    <option value={value} selected={selected}>
      {name}
    </option>
  );
};

export const QuoteAdvancedFilter: React.FC<QuoteAdvancedFilterModalProps> = props => {
  const [form, setForm] = React.useState<string>(null);
  const [plantsSelected, setPlantsSelected] = React.useState([]);
  const [currentFilter, setCurrentFilter] = React.useState<QuoteFilterData>(null);
  const { user } = useStore<UserStore>(useUserStore);
  const { userInfo, azureToken, token } = useSelector((state: RootState) => state.auth);
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const [clients, setClients] = React.useState([]);
  const [codeClients, setCodeClients] = React.useState([]);
  const [showClients, setShowClients] = React.useState(false);
  const [hideFilters, setHideFilters] = React.useState(true);
  const quoteStatus = QuoteStrings[userInfo.language].constants.stage;
  const flowTypes = FlowTypesLabels[userInfo.language];
  const [enableClientSearch, setEnableClientSearch] = React.useState<boolean>(false);

  const handleSuccessClientList = () => {
    setShowClients(true);
  };

  const { data: dataClientsList, isFetching: loadingClientList } = useGetClientsList(
    {
      enabled: enableClientSearch,
      client: currentFilter?.client,
      language: userInfo.language,
      authorizationToken: azureToken,
      accessToken: token,
    },
    handleSuccessClientList,
  );

  React.useEffect(() => {
    if (!props.opened) {
      return;
    }
    setCurrentFilter(props.initialFilters);
  }, [props.opened, props.initialFilters]);

  const handleSubmit = (formFields: FormData<QuoteFilterData>) => {
    if (Object.keys(formFields.error).length === 0) {
      props.onSubmit(formFields.data);
    }
  };

  const clearFilter = () => {
    setCurrentFilter(null);
    setClients([]);
    setCodeClients([]);
    props.handleFilterRemoval('ALL');
  };

  const handleSelectChange = (key: string, value: any, checked?: boolean) => {
    if (key === QuoteFilters.Form) {
      setForm(value);
    }

    if (key === QuoteFilters.Pinda) {
      if (checked[PlantTypesLabels.Pinda]) {
        setPlantsSelected([...plantsSelected, key]);
      } else {
        setPlantsSelected(plantsSelected.filter(el => el !== key));
      }
    } else if (key === QuoteFilters.Charqueadas) {
      if (checked[PlantTypesLabels.Charqueadas]) {
        setPlantsSelected([...plantsSelected, key]);
      } else {
        setPlantsSelected(plantsSelected.filter(el => el !== key));
      }
    } else if (key === QuoteFilters.Mogi) {
      if (checked[PlantTypesLabels.Mogi]) {
        setPlantsSelected([...plantsSelected, key]);
      } else {
        setPlantsSelected(plantsSelected.filter(el => el !== key));
      }
    }

    handleFieldChange(key, value);
  };

  const handleFieldChange = (key: string, value: any) => {
    const newFilter = { ...currentFilter };

    if (value?.length >= 3 && key === 'client') {
      setEnableClientSearch(true);
    } else {
      setEnableClientSearch(false);
      setShowClients(false);
    }

    newFilter[key] = value;
    setCurrentFilter(newFilter);
  };

  const handleChangeClientValue = (value: any) => {
    if (!clients.includes(value)) {
      const codeClient = value.split(' - ').shift();
      setCurrentFilter({ ...currentFilter, client: [...clients, value] });
      setClients([...clients, value]);
      if (codeClient) {
        setCodeClients([...codeClients, codeClient]);
      }
      setShowClients(false);
      handleEmptyClientField();
    }
  };

  const handleBlurClient = (value: any) => {
    if (value.target.value) {
      if (!clients.includes(value.target.value)) {
        setClients([...clients, value.target.value]);
      }
    }
    setShowClients(false);
  };

  const handleRemoveClient = (key: string) => {
    const codeClient = key.split(' - ').shift();
    setClients(currentClients => currentClients.filter(item => item !== key));
    setCodeClients(currentCodes => currentCodes.filter(item => item !== codeClient));
    const clientsLeft = clients.filter(item => item !== key);
    if (clientsLeft.length === 0) {
      setClients([]);
      setCurrentFilter({ ...currentFilter, client: null });
    }
  };

  const handleEmptyClientField = () => {
    setCurrentFilter({ ...currentFilter, client: null });
  };

  const hiddenFilters = quoteIsInternalUser ? 3 : 4;

  const showHideFiels = () => {
    if (hideFilters) {
      setHideFilters(false);
      Scroll.animateScroll.scrollToBottom({ containerId: 'searchBar' });
    } else {
      setHideFilters(true);
    }
  };

  const handleMultipleSelectChange = (key: string, value: any) => {
    const newFilter = { ...currentFilter };

    if (newFilter[key] && newFilter[key].length > 0) {
      if (!newFilter[key].includes(value.key)) {
        newFilter[key].push(value.key);
      }
    } else {
      newFilter[key] = [value.key];
    }

    setCurrentFilter(newFilter);
  };

  const handleRemoveMultipleSelect = (key: string, value: string) => {
    const newFilter = { ...currentFilter };

    const filterLeft = newFilter[key].filter(item => item !== value);
    if (filterLeft.length === 0) {
      newFilter[key] = [];
    } else {
      newFilter[key] = newFilter[key].filter(item => item !== value);
    }

    setCurrentFilter(newFilter);
  };

  const initialFilters = [
    {
      order: 0,
      key: QuoteFilters.IsaNumber,
      title: QuoteFilterLabelStrings[userInfo.language].id,
      kind: FilterType.Text,
    },
    {
      order: 1,
      key: QuoteFilters.Steel,
      title: QuoteFilterLabelStrings[userInfo.language].steel,
      kind: FilterType.Text,
    },
    {
      order: 3,
      key: QuoteFilters.Specification,
      title: QuoteFilterLabelStrings[userInfo.language].specification,
      kind: FilterType.Text,
    },
    {
      order: 2,
      key: QuoteFilters.User,
      title: QuoteFilterLabelStrings[userInfo.language].user,
      kind: FilterType.Text,
      hidden: !quoteIsInternalUser,
    },
    {
      order: 4,
      key: QuoteFilters.Status,
      title: QuoteFilterLabelStrings[userInfo.language].status,
      kind: FilterType.AutoComplete,
      options: quoteIsInternalUser
        ? [
            { key: 'NONE', label: '' },
            { key: quoteStatus.key.pending, label: quoteStatus.label.PENDING },
            { key: quoteStatus.key.internalQuery, label: quoteStatus.label.INTERNAL_QUERY },
            { key: quoteStatus.key.underAnalysisEpp, label: quoteStatus.label.UNDER_ANALYSIS_EPP },
            { key: quoteStatus.key.underAnalysisPed, label: quoteStatus.label.UNDER_ANALYSIS_PED },
            { key: quoteStatus.key.underAnalysisRtc, label: quoteStatus.label.UNDER_ANALYSIS_RTC },
            { key: quoteStatus.key.underAnalysisGq, label: quoteStatus.label.UNDER_ANALYSIS_GQ },
            { key: quoteStatus.key.dataValidationRtc, label: quoteStatus.label.DATA_VALIDATION_RTC },
            { key: quoteStatus.key.inValidationEpp, label: quoteStatus.label.IN_VALIDATION_EPP },
            { key: quoteStatus.key.inValidationPed, label: quoteStatus.label.IN_VALIDATION_PED },
            { key: quoteStatus.key.inValidationGq, label: quoteStatus.label.IN_VALIDATION_GQ },
            { key: quoteStatus.key.waitingClientReview, label: quoteStatus.label.WAITING_CLIENT_REVIEW },
            { key: quoteStatus.key.internalQuerySeller, label: quoteStatus.label.INTERNAL_QUERY_SELLER },
            { key: quoteStatus.key.validationRTC, label: quoteStatus.label.VALIDATION_RTC },
            { key: quoteStatus.key.validationSeller, label: quoteStatus.label.VALIDATION_SELLER },
            { key: quoteStatus.key.clientEdition, label: quoteStatus.label.CLIENT_EDITION },
            { key: quoteStatus.key.canceled, label: quoteStatus.label.CANCELED },
            { key: quoteStatus.key.rejected, label: quoteStatus.label.REJECTED },
            { key: quoteStatus.key.finalized, label: quoteStatus.label.FINALIZED },
            { key: quoteStatus.key.finalizedWithDerogation, label: quoteStatus.label.FINALIZED_WITH_DEROGATION },
            { key: quoteStatus.key.noCustomerResponse, label: quoteStatus.label.NO_CUSTOMER_RESPONSE },
          ]
        : [
            { key: 'NONE', label: '' },
            { key: quoteStatus.key.pending, label: quoteStatus.label.PENDING },
            { key: quoteStatus.key.underAnalysis, label: quoteStatus.label.UNDER_ANALYSIS },
            { key: quoteStatus.key.inValidation, label: quoteStatus.label.IN_VALIDATION },
            { key: quoteStatus.key.waitingClientReview, label: quoteStatus.label.WAITING_CLIENT_REVIEW },
            { key: quoteStatus.key.canceled, label: quoteStatus.label.CANCELED },
            { key: quoteStatus.key.rejected, label: quoteStatus.label.REJECTED },
            { key: quoteStatus.key.finalized, label: quoteStatus.label.FINALIZED },
            { key: quoteStatus.key.finalizedWithDerogation, label: quoteStatus.label.FINALIZED_WITH_DEROGATION },
          ],
    },
    {
      order: 5,
      key: QuoteFilters.Plants,
      title: QuoteFilterLabelStrings[userInfo.language].plants,
      kind: FilterType.MultiSelection,
      options: [
        {
          key: QuoteFilters.Pinda,
          label: PlantTypesLabels.Pinda,
          kind: FilterType.MultiSelection,
          subSelection: RequisitionOptions(userInfo.language),
          hidden: PlantTypesKeys.Pinda,
        },
        {
          key: QuoteFilters.Charqueadas,
          label: PlantTypesLabels.Charqueadas,
          kind: FilterType.MultiSelection,
          subSelection: RequisitionOptions(userInfo.language),
          hidden: [PlantTypesKeys.Charqueadas],
        },
        {
          key: QuoteFilters.Mogi,
          label: PlantTypesLabels.Mogi,
          kind: FilterType.MultiSelection,
          subSelection: RequisitionOptions(userInfo.language),
          hidden: [PlantTypesKeys.Mogi],
        },
      ],
      hidden: !quoteIsInternalUser,
    },
    {
      order: 6,
      key: QuoteFilters.Form,
      title: QuoteFilterLabelStrings[userInfo.language].form,
      kind: FilterType.Select,
      options: [
        { key: FormTypesKeys.None, label: FormTypesLabel[userInfo.language].None },
        { key: FormTypesKeys.Round, label: FormTypesLabel[userInfo.language].Round },
        { key: FormTypesKeys.Squared, label: FormTypesLabel[userInfo.language].Squared },
        { key: FormTypesKeys.Hexagon, label: FormTypesLabel[userInfo.language].Hexagon },
        { key: FormTypesKeys.Rectangular, label: FormTypesLabel[userInfo.language].Rectangular },
        { key: FormTypesKeys.Flat, label: FormTypesLabel[userInfo.language].Flat },
      ],
      subOptions: [
        {
          key: QuoteFilters.GaugeMin,
          title: QuoteFilterLabelStrings[userInfo.language].gaugeMin,
          kind: FilterType.Text,
          small: true,
          hidden: [FormTypesKeys.Round],
        },
        {
          key: QuoteFilters.GaugeMax,
          title: QuoteFilterLabelStrings[userInfo.language].gaugeMax,
          kind: FilterType.Text,
          small: true,
          hidden: [FormTypesKeys.Round],
        },
        {
          key: QuoteFilters.SideMin,
          title: QuoteFilterLabelStrings[userInfo.language].sideMin,
          kind: FilterType.Text,
          small: true,
          hidden: [FormTypesKeys.Squared, FormTypesKeys.Hexagon],
        },
        {
          key: QuoteFilters.SideMax,
          title: QuoteFilterLabelStrings[userInfo.language].sideMax,
          kind: FilterType.Text,
          small: true,
          hidden: [FormTypesKeys.Squared, FormTypesKeys.Hexagon],
        },
        {
          key: QuoteFilters.WidthMin,
          title: QuoteFilterLabelStrings[userInfo.language].widthMin,
          kind: FilterType.Text,
          small: true,
          hidden: [FormTypesKeys.Rectangular, FormTypesKeys.Flat],
        },
        {
          key: QuoteFilters.WidthMax,
          title: QuoteFilterLabelStrings[userInfo.language].widthMax,
          kind: FilterType.Text,
          small: true,
          hidden: [FormTypesKeys.Rectangular, FormTypesKeys.Flat],
        },
        {
          key: QuoteFilters.ThicknessMin,
          title: QuoteFilterLabelStrings[userInfo.language].thicknessMin,
          kind: FilterType.Text,
          small: true,
          hidden: [FormTypesKeys.Rectangular, FormTypesKeys.Flat],
        },
        {
          key: QuoteFilters.ThicknessMax,
          title: QuoteFilterLabelStrings[userInfo.language].thicknessMax,
          kind: FilterType.Text,
          small: true,
          hidden: [FormTypesKeys.Rectangular, FormTypesKeys.Flat],
        },
        {
          key: QuoteFilters.CornerRadius,
          title: QuoteFilterLabelStrings[userInfo.language].cornerRadius,
          kind: FilterType.Select,
          options: CornerRadiusOptions,
          hidden: [FormTypesKeys.Flat],
        },
      ],
    },
    {
      order: 7,
      key: QuoteFilters.Priority,
      title: QuoteFilterLabelStrings[userInfo.language].priority,
      kind: FilterType.AutoComplete,
      options: PriorityOptions,
      hidden: !quoteIsInternalUser,
    },
    {
      order: 8,
      key: QuoteFilters.Flow,
      title: QuoteFilterLabelStrings[userInfo.language].flow,
      kind: FilterType.AutoComplete,
      options: [
        { key: FlowTypesKeys.None, label: FlowTypesLabels[userInfo.language].NONE },
        { key: FlowTypesKeys.NewProduct, label: FlowTypesLabels[userInfo.language].NEW_PRODUCT },
        { key: FlowTypesKeys.GeneralSpecification, label: FlowTypesLabels[userInfo.language].GENERAL_SPECIFICATION },
        { key: FlowTypesKeys.SpecificSpecification, label: FlowTypesLabels[userInfo.language].SPECIFIC_SPECIFICATION },
        { key: FlowTypesKeys.ClientManual, label: FlowTypesLabels[userInfo.language].CLIENT_MANUAL },
      ],
    },
    {
      order: 9,
      key: QuoteFilters.HasPrice,
      title: QuoteFilterLabelStrings[userInfo.language].hasPrice,
      kind: FilterType.Select,
      options: PriceOptions[userInfo.language],
    },
    {
      order: 10,
      key: QuoteFilters.ClientVisible,
      title: QuoteFilterLabelStrings[userInfo.language].clientVisible,
      kind: FilterType.Select,
      options: ClientVisibleOptions[userInfo.language],
      hidden: !quoteIsInternalUser,
    },
    {
      order: 11,
      key: QuoteFilters.Provider,
      title: QuoteFilterLabelStrings[userInfo.language].provider,
      kind: FilterType.Select,
      options: ProviderOptions[userInfo.language],
      hidden: !quoteIsInternalUser,
    },
  ];

  const savedFilter = JSON.parse(
    window.localStorage.getItem(`searchOptions${user?.isaGroup?.name ? user.isaGroup.name : ''}`),
  );
  const [draggableFilters, setDraggableFilters] = React.useState(savedFilter ? savedFilter : initialFilters);

  React.useEffect(() => {
    setDraggableFilters(savedFilter ? savedFilter : initialFilters);
  }, []);

  const reorderAttributes = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  let elements = [];
  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    elements = reorderAttributes(draggableFilters, result.source.index, result.destination.index);

    window.localStorage.setItem(`searchOptions${user.isaGroup.name}`, JSON.stringify(elements));
    setDraggableFilters(elements);
  };

  return (
    <QuoteAdvancedFilterWrapper id='searchBar'>
      <Form onSubmit={handleSubmit}>
        <Hbox.Item>
          <H2>{QuoteStrings[userInfo.language].filterModal.searchFilter}</H2>

          {quoteIsInternalUser && (
            <Col xs={12}>
              <LoadingState loading={loadingClientList}>
                <FormField
                  initialValue={currentFilter?.client}
                  onValueChange={value => handleFieldChange(QuoteFilters.Client, value)}
                  label={QuoteStrings[userInfo.language].filterModal.clientField}
                  name={QuoteFilters.Client}
                >
                  <TextField
                    data-testid={QuoteFilters.Client}
                    onFocus={handleEmptyClientField}
                    onBlur={value => handleBlurClient(value)}
                  />
                </FormField>

                <FormField initialValue={codeClients} name='codeClients'>
                  <TextField id='codeClients' type='hidden' />
                </FormField>

                <QuoteClientList
                  clientList={dataClientsList}
                  query={currentFilter?.client}
                  onClientClick={value => handleChangeClientValue(value)}
                  showClients={showClients}
                />
              </LoadingState>

              {clients && (
                <FormField initialValue={clients} name={QuoteFilters.Client}>
                  {clients.length > 0 && <VSeparator />}

                  {clients.map((client, index) => (
                    <Tag
                      key={index}
                      selected
                      showCheck={false}
                      onRemove={() => handleRemoveClient(client)}
                      //onRemove={() => handleRemoveMultipleSelect('client', client)}
                    >
                      {client}
                    </Tag>
                  ))}
                  <VSeparator />
                </FormField>
              )}
            </Col>
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable'>
              {provided => (
                <Hbox.Item {...provided.droppableProps} ref={provided.innerRef}>
                  {draggableFilters.map((filter, index) => (
                    <Draggable key={`d-${filter.key}`} draggableId={filter.key} index={index}>
                      {providedChilds => (
                        <HiddenFiltersWrapper
                          ref={providedChilds.innerRef}
                          {...providedChilds.draggableProps}
                          {...providedChilds.dragHandleProps}
                          key={filter.key}
                          hide={index >= hiddenFilters && hideFilters ? true : false}
                        >
                          <React.Fragment>
                            {selectFilterType(
                              filter,
                              currentFilter,
                              handleFieldChange,
                              handleSelectChange,
                              handleMultipleSelectChange,
                              handleRemoveMultipleSelect,
                              form,
                              plantsSelected,
                              quoteStatus,
                              flowTypes,
                            )}
                          </React.Fragment>
                        </HiddenFiltersWrapper>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Hbox.Item>
              )}
            </Droppable>
          </DragDropContext>
        </Hbox.Item>
        <VSeparator />
        <ButtonsWrapper>
          <Row>
            <Hbox.Item>
              <Button kind='secondary' expanded onClick={() => showHideFiels()}>
                {!hideFilters
                  ? QuoteStrings[userInfo.language].filterModal.filterHide
                  : QuoteStrings[userInfo.language].filterModal.filterShow}
              </Button>
            </Hbox.Item>
          </Row>
          <VSeparator />

          <Hbox grow hAlign='center'>
            <Hbox.Item>
              <Button kind='link' onClick={() => clearFilter()}>
                {QuoteStrings[userInfo.language].filterModal.filterReset}
              </Button>
            </Hbox.Item>
            <Hbox.Item>
              <Button type='submit'>{QuoteStrings[userInfo.language].filterModal.research}</Button>
            </Hbox.Item>
          </Hbox>
        </ButtonsWrapper>
      </Form>
    </QuoteAdvancedFilterWrapper>
  );
};

const selectFilterType = (
  filter: QuoteFilter,
  currentFilter: QuoteFilterData,
  onFilterChange: (key: string, value: any) => void,
  onSelectChange: (key: string, value: any, checked?: boolean) => void,
  onMultipleSelectChange: (key: string, value: any) => void,
  handleRemoveMultipleSelect: (key: string, value: string) => void,
  form: string,
  plants: string[],
  quoteStatus: any,
  flowTypes: any,
) => {
  const translateStrings = (value: string, key: string) => {
    if (key === 'status') {
      return quoteStatus.label[value];
    } else if (key === 'flow') {
      return flowTypes[value];
    } else {
      return value;
    }
  };
  switch (filter.kind) {
    case FilterType.Text:
      return (
        !filter.hidden && (
          <Col xs={filter.small ? 6 : 12}>
            <FormField
              initialValue={currentFilter?.[filter.key]}
              onValueChange={value => onFilterChange(filter.key, value)}
              label={filter.title}
              name={filter.key}
              validators={
                currentFilter?.[filter.key] && currentFilter?.[filter.key] !== '' && filter.key === 'email'
                  ? [Validators.EmailRegex('Email inválido')]
                  : []
              }
            >
              <TextField data-testid={filter.key} />
            </FormField>
            <VSeparator />
          </Col>
        )
      );
    case FilterType.AutoComplete:
      return (
        !filter.hidden && (
          <Col xs={filter.small ? 6 : 12}>
            <FormField value={currentFilter?.[filter.key] ? currentFilter[filter.key] : null} name={filter.key}>
              <QuoteAdvancedFilterSelect
                options={filter.options}
                label={filter.title}
                name={filter.key}
                onValueChange={value => onMultipleSelectChange(filter.key, value)}
              />
            </FormField>
            <VSeparator />
            {currentFilter?.[filter.key] && (
              <FormField value={currentFilter[filter.key]} name={filter.key}>
                {currentFilter[filter.key].map((item, index) => (
                  <Tag
                    key={`tag-${index}`}
                    selected
                    showCheck={false}
                    onRemove={() => handleRemoveMultipleSelect(filter.key, item)}
                  >
                    {translateStrings(item, filter.key)}
                  </Tag>
                ))}
                <VSeparator />
              </FormField>
            )}
          </Col>
        )
      );
    case FilterType.Select:
      return (
        !filter.hidden && (
          <Col xs={filter.small ? 6 : 12}>
            <FormField
              initialValue={
                currentFilter?.[filter.key] && currentFilter?.[filter.key] !== undefined
                  ? currentFilter[filter.key]
                  : null
              }
              label={filter.title}
              name={filter.key}
              onValueChange={value => onSelectChange(filter.key, value)}
            >
              <SelectField data-testid={filter.key}>
                {filter.options.map(option => (
                  <Option
                    selected={option.label === currentFilter?.[filter.key]}
                    data-testid={option.key}
                    key={option.key}
                    value={option.key}
                    name={option.label}
                  />
                ))}
              </SelectField>
            </FormField>
            <VSeparator />
            {filter.key === 'form' &&
              filter.subOptions.map(
                filterSub =>
                  filterSub.hidden.includes(form) && (
                    <Col xs={6} key={filterSub.key}>
                      <FormField
                        value={currentFilter?.[filterSub.key]}
                        onValueChange={value => onFilterChange(filterSub.key, value)}
                        label={filterSub.title}
                        name={filterSub.key}
                      >
                        {filterSub.kind === FilterType.Text ? (
                          <TextField data-testid={filterSub.key} />
                        ) : (
                          filterSub.kind === FilterType.Select && (
                            <SelectField data-testid={filterSub.key}>
                              {filterSub.options.map(option => (
                                <Option
                                  data-testid={option.key}
                                  key={option.key}
                                  value={option.key}
                                  name={option.label}
                                  selected={true}
                                />
                              ))}
                            </SelectField>
                          )
                        )}
                      </FormField>
                      <VSeparator />
                    </Col>
                  ),
              )}
          </Col>
        )
      );
    case FilterType.MultiSelection:
      return (
        !filter.hidden && (
          <Col xs={filter.small ? 6 : 12}>
            <InputLabel>{filter.title}</InputLabel>
            <VSeparator small />

            <TagCheckboxGroup>
              {filter.key === 'plants' ? (
                <CheckboxFieldWrapper>
                  {filter.options.map(option => (
                    <FormField
                      key={option.key}
                      value={currentFilter?.[option.key] ? currentFilter[option.key] : []}
                      name={option.key}
                      onValueChange={(value, checked) => onSelectChange(option.key, value, checked)}
                    >
                      <WrapperFilterPlants data-testid={option.key} selected={currentFilter?.[option.key]?.length > 0}>
                        <FaIcon.ChevronRight size='1x' />
                        <CheckboxField data-testid={option.key} id={option.label}>
                          {option.label}
                        </CheckboxField>
                      </WrapperFilterPlants>

                      <CheckboxSubFieldWrapper>
                        {option.subSelection.map(
                          optionSub =>
                            plants.includes(option.key) && (
                              <CheckboxField data-testid={optionSub.key} key={optionSub.key} id={optionSub.key}>
                                {optionSub.label}
                              </CheckboxField>
                            ),
                        )}
                      </CheckboxSubFieldWrapper>
                    </FormField>
                  ))}
                </CheckboxFieldWrapper>
              ) : (
                <CheckboxFieldWrapper>
                  {filter.options.map(option => (
                    <FormField
                      key={option.key}
                      value={currentFilter?.[option.key] ? currentFilter[option.key] : []}
                      name={option.key}
                      onValueChange={(value, checked) => onSelectChange(option.key, value, checked)}
                    >
                      <CheckboxField data-testid={option.key} id={option.label}>
                        {option.label}
                      </CheckboxField>
                      <CheckboxSubFieldWrapper>
                        {option.subSelection.map(
                          optionSub =>
                            plants.includes(option.key) && (
                              <CheckboxField data-testid={optionSub.key} key={optionSub.key} id={optionSub.key}>
                                {optionSub.label}
                              </CheckboxField>
                            ),
                        )}
                      </CheckboxSubFieldWrapper>
                    </FormField>
                  ))}
                </CheckboxFieldWrapper>
              )}
            </TagCheckboxGroup>
            <VSeparator />
          </Col>
        )
      );
    default:
      return null;
  }
};
