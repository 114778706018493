import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { BaggHttpRequestBuilder, endpointRcs } from '@app/data/http';
import {
  CrmOpportunityDetailsResponseComplain,
  GetCrmParams,
  mapOpportunityDetailsDataComplain,
} from '@app/data/http/crm.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export async function getOpportunityDetailsComplain(
  params?: GetCrmParams,
): Promise<CrmOpportunityDetailsResponseComplain> {
  const user = Container.get(AuthStorageService).getUser();

  const defaultHeaders = {
    ...Container.get(BaggHttpRequestBuilder).config.headers,
    language: user.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  const url = `${Container.get(BaggHttpRequestBuilder).config.baseUrl}${endpointRcs.RCS}/${params.id}${
    endpointRcs.OPPORTUNITY_DETAILS
  }`;

  const { data, status } = await axios.get(url, {
    headers: defaultHeaders,
  });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
}

export function useGetOpportunityDetailsComplain(params: GetCrmParams, onSuccess?, onError?) {
  return useQuery(['getOpportunityDetailsComplain', params], () => getOpportunityDetailsComplain(params), {
    onSuccess,
    onError,
    enabled: params.enabled,
    select: data => {
      return mapOpportunityDetailsDataComplain(data.data);
    },
    refetchOnWindowFocus: false,
  });
}
