import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { validateTokens } from '@app/modules/components/notification-center/store/thunk/validate-and-get-notification.thunk';
import { FlashMessageTargetName } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { Frame } from '@atomic/atm.frame';
import { Body, H1, H3 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { ErrorPlaceholder } from '../components/error.placeholder';
import { useFlashMessage } from '../components/flash-message.hook';
import { QuoteCellShimmer } from '../quote/quote-cell.shimmer';
import { DocumentConfirmedModal } from './components/documentConfirmedModal';
import { DocumentContainer, FileData } from './components/documentContainer';
import { DocumentModal, EditedDocument } from './components/documentModal';
import { DocumentSearch } from './components/documentSearch';
import { useDeleteDocument, useGetDocumentList } from './hooks/document.Datasource';
import { DocumentString } from './utils/documents.string';

export interface Documents {
  expirationDate?: string;
  fileUrl: string;
  id: string;
  name: string;
  title: string;
  type: string;
  path?: string;
}
export interface DocumentsList {
  data: Documents[];
  total: number;
}

export const DocumentsPage = () => {
  const { userInfo, azureToken, token } = useSelector((state: RootState) => state.auth);
  const [searchResults, setSearchResults] = React.useState<FileData[]>([]);
  const [openModalDocument, setOpenModalDocument] = React.useState<boolean>(false);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const [editedDocument, setEditedDocument] = React.useState<EditedDocument>(null);
  const [showDeleteModalConfirmation, setShowDeleteModalConfirmation] = React.useState(false);
  const [idDelete, setIdDelete] = React.useState(null);
  const [hasTokenChecked, setHasTokenChecked] = React.useState<boolean>(false);
  const dispatch = useDispatch<AppThunkDispatch>();

  const strings = DocumentString[userInfo.language];
  const documentType = window.location.pathname.replace('/', '');

  useEffect(() => {
    dispatch(validateTokens()).then(() => {
      setHasTokenChecked(true);
    });
  }, []);

  const { data: initialDocumentList, refetch, isLoading, isFetching, error } = useGetDocumentList(
    {
      documentType,
      language: userInfo.language,
      authorizationToken: azureToken,
      accessToken: token,
      enabled: hasTokenChecked,
    },
    handleSuccess,
  );

  const { mutate: deleteDocument, isLoading: loadingDeleteDocument } = useDeleteDocument(
    onSuccessDeleteDocument,
    onErrorDeleteDocument,
  );

  function handleSuccess(response) {
    setHasTokenChecked(true);
    setSearchResults(response.data);
  }

  function onSuccessDeleteDocument() {
    setIdDelete(null);
    show('success', strings.messages.successDelete);
  }

  function onErrorDeleteDocument() {
    setIdDelete(null);
    show('alert', strings.messages.errorDelete);
  }

  const handleBack = () => {
    window.history.go(-1);
  };

  const handleEditFile = file => {
    setEditedDocument(file);
    setOpenModalDocument(true);
  };

  const handleDelete = (id: string) => {
    setIdDelete(id);
    setShowDeleteModalConfirmation(true);
  };

  const confirmDelete = () => {
    dispatch(validateTokens()).then(() => {
      deleteDocument({ id: idDelete, language: userInfo.language, authorizationToken: azureToken, accessToken: token });
    });
  };

  return (
    <>
      <Frame>
        <VSeparator />
        <Grid fluid>
          <H1>{strings[documentType].title}</H1>
          <Row>
            <Col md={6} xs={12}>
              <DocumentSearch
                strings={strings}
                documentType={documentType}
                initialDocumentList={initialDocumentList}
                setSearchResults={setSearchResults}
              />
            </Col>
            <Col md={6} xs={12}>
              {userInfo.isAdmin && (
                <Hbox hAlign='flex-end' vAlign='flex-end'>
                  <VSeparator />
                  <Button onClick={() => setOpenModalDocument(true)}>+ Adicionar novo arquivo</Button>
                </Hbox>
              )}
            </Col>
          </Row>
        </Grid>
        <VSeparator />
      </Frame>
      <Grid fluid>
        <Row mt>
          <Col xs={12}>
            <Separator />
            <H3>{strings[documentType].subTitle.title}</H3>
            <Separator small />
            <Body>{strings[documentType].subTitle.paragraph}</Body>
          </Col>
        </Row>
        <LoadingState
          loading={isLoading || isFetching || loadingDeleteDocument}
          error={!!error}
          data={searchResults?.length > 0}
        >
          <LoadingState.Shimmer>
            <QuoteCellShimmer />
          </LoadingState.Shimmer>

          <LoadingState.Error>
            <ErrorPlaceholder onRetryClick={() => refetch()} />
          </LoadingState.Error>
          <Row mt>
            {searchResults ? (
              searchResults.map(file => (
                <Col xs={3} key={file.id}>
                  <DocumentContainer
                    file={file}
                    strings={strings.box}
                    deleteDocument={handleDelete}
                    editDocument={handleEditFile}
                    isAdmin={userInfo.isAdmin}
                  />
                </Col>
              ))
            ) : (
              <></>
            )}
          </Row>
        </LoadingState>
        <Separator />
        {searchResults?.length > 0 && (
          <Hbox hAlign='center' pb>
            <Button onClick={handleBack}>{strings.btnBack}</Button>
            <Separator />
          </Hbox>
        )}
      </Grid>

      <DocumentModal
        editedDocument={editedDocument}
        setOpenModalDocument={setOpenModalDocument}
        openModalDocument={openModalDocument}
        setEditedDocument={setEditedDocument}
        initialDocumentList={initialDocumentList}
        documentType={documentType}
      />

      <DocumentConfirmedModal
        setConfirmed={confirmDelete}
        showModalConfirmation={showDeleteModalConfirmation}
        setShowModalConfirmation={setShowDeleteModalConfirmation}
        messageAlert={strings.messages.confirmDelete}
      />
    </>
  );
};
