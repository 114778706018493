import { isPast } from 'date-fns';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { OrderingFilterStyled } from '@app/modules/components/date-filter.style';
import { validateTokens } from '@app/modules/components/notification-center/store/thunk/validate-and-get-notification.thunk';
import { useDownloadAsset } from '@app/modules/quote/technical-analysis/hooks/useDownloadAsset';
import { Button } from '@atomic/atm.button';
import { Cell } from '@atomic/atm.cell';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body, H3 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { useEventListener } from '@atomic/obj.hooks/use-event-listener.hook';
import { ButtonWrapper, DocumentWrapper } from './documentContainer.style';
import { formatDate } from './documentModal';

export interface FileData {
  title: string;
  expirationDate: string;
  fileUrl: string;
  id: string;
  name: string;
  type: string;
  path?: string;
  downloadGroup: string;
}
interface DocumentContainerProps {
  file: Partial<FileData>;
  strings: any;
  isAdmin: boolean;
  deleteDocument: (id) => void;
  editDocument: (file) => void;
}

export const DocumentContainer = (props: DocumentContainerProps) => {
  const { userInfo, azureToken, token } = useSelector((state: RootState) => state.auth);
  const { mutate: handleDownload, isLoading: getAssetLoading } = useDownloadAsset();
  const dispatch = useDispatch<AppThunkDispatch>();
  const menuRef = React.useRef(null);
  const [showOptions, setShowOptions] = React.useState<boolean>(false);

  const handleDocumentClick = (label: string, file: string, url: string, type: string, downloadGroup: string) => {
    const tag = `DocumentsDownload${label.replaceAll(' ', '')}`;
    LogAnalytics.click({ tipo: tag, grupoDeDownloads: downloadGroup });

    dispatch(validateTokens()).then(() => {
      handleDownload({
        filename: file,
        url,
        type,
        language: userInfo.language,
        authorizationToken: azureToken,
        accessToken: token,
      });
    });
  };

  const handleMouseDown = (event: MouseEvent) => {
    if (showOptions && menuRef && !menuRef.current.contains(event.target)) {
      setShowOptions(!showOptions);
    }
  };
  useEventListener('mousedown', handleMouseDown);

  const verifyExpirationDate = date => {
    return isPast(new Date(formatDate(date)));
  };

  return (
    <DocumentWrapper>
      <Hbox.Item hAlign='center'>
        <H3 style={{ whiteSpace: 'pre-wrap' }}>
          {props.file.title}
          {props.isAdmin && (
            <ButtonWrapper title='Clique para ver as opções'>
              <div ref={menuRef}>
                <Button outlined={true} onClick={() => setShowOptions(!showOptions)}>
                  <FaIcon.Options size='1x' />
                </Button>

                <OrderingFilterStyled show={showOptions}>
                  <Cell hover onClick={() => props.editDocument(props.file)}>
                    <Body>
                      <FaIcon.Custom
                        clickable='false'
                        size='1x'
                        icon='pen'
                        color={Color.Primary}
                        title='Editar documento'
                      />
                      <Hbox.Separator />
                      Editar documento
                    </Body>
                  </Cell>
                  <Cell hover onClick={() => props.deleteDocument(props.file.id)}>
                    <Body>
                      <FaIcon.Custom
                        clickable='true'
                        size='1x'
                        icon='trash-alt'
                        color={Color.Alert}
                        title='Excluir documento'
                      />
                      <Hbox.Separator />
                      Excluir documento
                    </Body>
                  </Cell>
                </OrderingFilterStyled>
              </div>
            </ButtonWrapper>
          )}
        </H3>
        <Separator small />

        {props.file.expirationDate ? (
          <Body color={verifyExpirationDate(props.file.expirationDate) ? Color.Alert : Color.GrayDark}>
            Expira em {props.file.expirationDate}
          </Body>
        ) : (
          <Separator />
        )}
        <Separator small />
        <Button
          loading={getAssetLoading}
          onClick={() =>
            handleDocumentClick(
              props.file.title,
              props.file.name,
              props.file.fileUrl,
              props.file.type,
              props.file.downloadGroup,
            )
          }
        >
          {props.strings.btn}
        </Button>
      </Hbox.Item>
    </DocumentWrapper>
  );
};
