import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { BaggHttpRequestBuilder, endpointRcs } from '@app/data/http';
import { CrmAttachedFilesResponse, GetCrmParams, mapAttachedFilesData } from '@app/data/http/crm.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export async function getAttachedFiles(params?: GetCrmParams): Promise<CrmAttachedFilesResponse> {
  const user = Container.get(AuthStorageService).getUser();

  const defaultHeaders = {
    ...Container.get(BaggHttpRequestBuilder).config.headers,
    language: user.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  const url = `${Container.get(BaggHttpRequestBuilder).config.baseUrl}${endpointRcs.RCS}/${params.id}${
    endpointRcs.ATTACHED_FILES
  }`;

  const { data, status } = await axios.get(url, {
    headers: defaultHeaders,
  });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
}

export function useGetAttachedFiles(params: GetCrmParams, onError?) {
  return useQuery(['getAttachedFiles', params], () => getAttachedFiles(params), {
    onError,
    enabled: params.enabled,
    select: data => {
      return mapAttachedFilesData(data.data);
    },
    refetchOnWindowFocus: false,
  });
}
