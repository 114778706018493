import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { IsaHttpRequestBuilder } from '@app/data/http';
import { GetAssetsResponse } from '@app/data/http/quote-assets.dto';

export async function getFile(params?: any): Promise<GetAssetsResponse[]> {
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    language: params.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  const { data, status } = await axios.get(params.url, { headers: defaultHeaders, responseType: 'arraybuffer' });

  if (status === 200) {
    const blob = new Blob([data], { type: params.type });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = params.filename;
    link.click();
  }
  return null;
}

export const useDownloadAsset = () => {
  return useMutation(getFile, {});
};
