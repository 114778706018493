import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { PostAttributeParams } from '@app/data/http/quote-attributes-params.dto';

export const postNewAttribute = async (params?: PostAttributeParams) => {
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    language: params.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  const dataPost = {
    label: params.label,
    type: 'text',
    value: params.value,
  };

  const url = params.plantKey
    ? `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
        endpoint.ANALYSIS_PLANT
      }/${params.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}`
    : `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
        endpoint.ANALYSIS_PLANT
      }${endpoint.ANALYSIS_ATTRIBUTE}`;

  const { data } = await axios.post(url, dataPost, { headers: defaultHeaders });

  return data;
};

export const usePostNewAttribute = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(postNewAttribute, {
    onSuccess,
    onError,
    onMutate: async () => {
      await queryClient.cancelQueries(['getTechnicalAnalysis']);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['getTechnicalAnalysis']);
    },
  });
};
