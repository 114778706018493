/* eslint-disable complexity */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Element } from 'react-scroll';
import { LogAnalytics } from '@app/core/analytics';
import { EnhancedError } from '@app/core/hook/request.model';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { IsaLoading } from '@app/modules/components/isa-loading-component';
import { validateTokens } from '@app/modules/components/notification-center/store/thunk/validate-and-get-notification.thunk';
import { UploadNormLoading } from '@app/modules/components/uploadNormLoading';
import { Button } from '@atomic/atm.button';
import { H2 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Form } from '@atomic/obj.form';
import { LoadingState } from '@atomic/obj.loading-state';
import { FormCellShimmer } from './components/form-cell.shimmer';
import { FormSection } from './components/formSection';
import { FormResult } from './components/result/form-result.component';
import {
  DetailsWrapper,
  FormResultWrapper,
  FormScrollWrapper,
  FormWrapper,
  MainQuestion,
  QuestionWrapper,
  SummaryWrapper,
} from './components/result/form-result.component.style';
import { StepsHeader } from './components/steps-header.component';
import { FormContext, FormProvider } from './form.context';
import { useCreateAnalysis } from './hooks/useCreateAnalysis';
import { ModalAttributeContainer } from './modal/modal-attribute.container';
import { ModalConfirmationForm } from './modal/modal-confirmation-form.component';
import { ModalErrorNluForm } from './modal/modal-error-nlu-form.component';
//import { isValidCNPJ } from './utils/checkCNPJ';
//import { mapPayloadAnalysis } from './utils/form.dto';
import { getInitials } from './utils/getInitials';
import { mapPayloadAnalysisOld } from './utils/mapPayloadAnalysisOld.dto';
import { resetChat } from './utils/resetChat';

export const FormContainer = () => {
  const { azureToken, token } = useSelector((state: RootState) => state.auth);
  const [cfId, setCfId] = React.useState(null);
  const dispatch = useDispatch<AppThunkDispatch>();
  const history = useHistory();

  const [timeQuote, setTimeQuote] = React.useState(0);

  React.useEffect(() => {
    let interval;

    const addTime = () => {
      setTimeQuote(prevTempo => prevTempo + 1);
    };

    const startTime = () => {
      if (!interval) {
        interval = setInterval(addTime, 1000);
      }
    };

    const stopTime = () => {
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    };

    window.addEventListener('focus', startTime);
    window.addEventListener('blur', stopTime);

    return () => {
      window.removeEventListener('focus', startTime);
      window.removeEventListener('blur', stopTime);
      clearInterval(interval);
    };
  }, []);

  const {
    formResult,
    setFormResult,
    conversationId,
    userInfo,
    strings,
    showModalConfirmation,
    showModalErroNlu,
    setShowModalErroNlu,
    initialized,
    show,
    loadingPostFile,
    loadingFlowClient,
    dataFlowClient,
    dataFlowSteel,
    dataFlowProduct,
    dataFlowGoal,
    dataFlowClientManual,
    dataFlowRevisionNorm,
    dataFlowAdditional,
    loadingFlowSteel,
    loadingFlowClientManual,
    loadingFlowRevisionNorm,
    loadingFlowProduct,
    loadingFlowGoal,
    loadingFormFlowAdditional,
  } = React.useContext(FormContext);

  const { mutate: createAnalysis, isLoading: loadingCreateAnalysis } = useCreateAnalysis(
    handleAnalysisCreationSuccess,
    handleAnalysisCreationFailure,
  );

  function handleAnalysisCreationSuccess(data) {
    LogAnalytics.submit({
      tipo: 'CriarCotacaoForm',
      time: timeQuote,
      analysisId: data.analysisId,
    });

    setCfId(data.analysisId);
    show('success', strings.newForm.messages.analysisCreatedSuccess);
    history.push(`/quote/analysis/${data.analysisId}`);
  }

  function handleAnalysisCreationFailure(err: EnhancedError) {
    LogAnalytics.error({
      tipo: 'ErroCriarCotacaoForm',
      message: err.message,
    });
    if (conversationId) {
      setShowModalErroNlu(true);
    } else {
      show('alert', strings.alertTry);
    }
  }

  // const { mutate: createClient, isLoading: loadingCreateClient } = useCreateClient(
  //   handleClientCreationSuccess,
  //   handleClientCreationFailure,
  // );

  // function handleClientCreationSuccess() {
  //   const payload = mapPayloadAnalysis(formResult);
  //   createAnalysis({ query: payload });
  // }

  // function handleClientCreationFailure() {
  //   show('alert', 'Erro ao cliente o cliente. Tente novamente.');
  // }

  const handleSubmit = async formFields => {
    if (Object.keys(formFields.error).length <= 0) {
      dispatch(validateTokens()).then(() => {
        // if (
        //   formResult?.CLIENT?.questions?.fields?.CLIENT_CUSTOM?.value &&
        //   formResult?.CLIENT?.questions?.fields?.CNPJ?.value
        // ) {
        //   const playload = {
        //     cnpj: formResult?.CLIENT?.questions?.fields?.CNPJ?.value
        //       ? isValidCNPJ(formResult.CLIENT.questions.fields.CNPJ.value)
        //         ? formResult.CLIENT.questions.fields.CNPJ.value
        //         : null
        //       : null,
        //     nif: formResult?.CLIENT?.questions?.fields?.CNPJ?.value
        //       ? !isValidCNPJ(formResult.CLIENT.questions.fields.CNPJ.value)
        //         ? formResult.CLIENT.questions.fields.CNPJ.value
        //         : null
        //       : null,
        //   };
        //   createClient(playload);
        // } else {
        const payload = mapPayloadAnalysisOld(formResult);
        createAnalysis({ payload, authorizationToken: azureToken, accessToken: token });
      });
      //}
    } else {
      show('alert', strings.newForm.messages.analysisCreateMandatory);
    }
  };

  document.addEventListener('keydown', function(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  });

  // const blockSubmitButton =
  //   // loadingPostFile ||
  //   // loadingCreateAnalysis ||
  //   // loadingFlowClient ||
  //   // loadingFlowGoal ||
  //   // loadingFlowProduct ||
  //   // loadingFlowSteel ||
  //   // loadingFormFlowAdditional ||
  //   // loadingCallbackNorm ||
  //   // loadingCallbackChemicalComposition ||
  //   // loadingCallbackBending ||
  //   // loadingCallbackShorter ||
  //   // loadingCallback ||
  //   //loadingCreateClient ||
  //   disableSubmit;

  return (
    <LoadingState loading={loadingCreateAnalysis || loadingPostFile} enableActivityIndicator={false}>
      {loadingCreateAnalysis ? (
        <LoadingState.CustomLoading>
          <IsaLoading message={loadingCreateAnalysis ? strings.loading.initialize : strings.loading.finish} />
        </LoadingState.CustomLoading>
      ) : (
        <LoadingState.CustomLoading>
          <UploadNormLoading message={strings.loading.analyzing} />
        </LoadingState.CustomLoading>
      )}

      <Form id='formNewQuote' onSubmit={handleSubmit}>
        <FormWrapper style={{ display: 'flex', height: 'calc(100vh - 60px)' }}>
          <StepsHeader />
          <FormScrollWrapper id='scrollContainer'>
            <Element name='intro' style={{ height: 'auto', marginBottom: '40px' }}>
              <QuestionWrapper>
                <MainQuestion>
                  {strings.newForm.introdution} <b>{getInitials(userInfo.name)}</b>
                </MainQuestion>
              </QuestionWrapper>
            </Element>
            <LoadingState loading={loadingFlowClient} data={dataFlowClient || false}>
              <LoadingState.Shimmer>
                <FormCellShimmer />
                <Separator />
              </LoadingState.Shimmer>
              <FormSection step={dataFlowClient && formResult?.CLIENT} stepIndex={0} />
            </LoadingState>

            <LoadingState loading={loadingFlowClientManual} data={dataFlowClientManual || false}>
              <LoadingState.Shimmer>
                <FormCellShimmer />
                <Separator />
              </LoadingState.Shimmer>
              <FormSection step={dataFlowClientManual && formResult?.DOCUMENT} stepIndex={1} />
            </LoadingState>

            <LoadingState loading={loadingFlowRevisionNorm} data={dataFlowRevisionNorm || false}>
              <LoadingState.Shimmer>
                <FormCellShimmer />
                <Separator />
              </LoadingState.Shimmer>
              <FormSection step={dataFlowRevisionNorm && formResult?.REVISION} stepIndex={1} />
            </LoadingState>

            <LoadingState loading={loadingFlowSteel} data={dataFlowSteel || false}>
              <LoadingState.Shimmer>
                <FormCellShimmer />
                <Separator />
              </LoadingState.Shimmer>
              <FormSection step={dataFlowSteel && formResult?.STEEL} stepIndex={1} />
            </LoadingState>

            <LoadingState loading={loadingFlowProduct} data={dataFlowProduct || false}>
              <LoadingState.Shimmer>
                <FormCellShimmer />
                <Separator />
              </LoadingState.Shimmer>
              <FormSection step={dataFlowProduct && formResult?.PRODUCT} stepIndex={2} />
            </LoadingState>

            <LoadingState loading={loadingFlowGoal} data={dataFlowGoal || false}>
              <LoadingState.Shimmer>
                <FormCellShimmer />
                <Separator />
              </LoadingState.Shimmer>
              <FormSection step={dataFlowGoal && formResult?.GOAL} stepIndex={3} />
            </LoadingState>

            <LoadingState loading={loadingFormFlowAdditional} data={dataFlowAdditional || false}>
              <LoadingState.Shimmer>
                <FormCellShimmer />
                <Separator />
              </LoadingState.Shimmer>
              <FormSection step={dataFlowAdditional && formResult?.ADDITIONAL} stepIndex={4} />
            </LoadingState>

            {formResult && Object.entries(formResult).length === 1 && (
              <Element>
                <DetailsWrapper bg={false}>
                  <SummaryWrapper bg={false}>
                    <H2 bold={700}>
                      Próxima etapa: <span>Preencha os campos necessários na etapa 1 para continuar</span>
                    </H2>
                  </SummaryWrapper>
                </DetailsWrapper>
              </Element>
            )}

            <Hbox.Item grow>
              <Button expanded kind='callToAction' type='submit'>
                {strings.newForm.submitButton}
              </Button>
            </Hbox.Item>
            <Hbox.Separator small />
            <Separator />
          </FormScrollWrapper>

          <FormResultWrapper>
            <Hbox.Item noGrow>
              <FormResult initialized={initialized} resetChat={resetChat} />
            </Hbox.Item>
          </FormResultWrapper>
        </FormWrapper>
      </Form>

      <ModalAttributeContainer />

      <ModalConfirmationForm
        language={userInfo.language}
        setFormResult={setFormResult}
        opened={showModalConfirmation}
        cfId={cfId}
      />

      <ModalErrorNluForm
        language={userInfo.language}
        opened={showModalErroNlu}
        setShowModalErroNlu={setShowModalErroNlu}
        conversationId={conversationId}
      />
    </LoadingState>
  );
};

export const FormPage = () => {
  return (
    <FormProvider>
      <FormContainer />
    </FormProvider>
  );
};
