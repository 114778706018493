import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { BaggHttpRequestBuilder } from '@app/data/http';
import { ComplainClientResponse, mapComplainClients } from '@app/data/http/complain.dto';
import { handleUnauthorized } from '@app/utils/http-utils';

const endpoint = {
  SALESFORCE_ACCOUNTS: '/salesforce-accounts',
  FORM_COMPLAIN: '/form-complaints',
  FORM_COMPLAIN_FILES: '/form-attachment',
};

export async function getComplainClients(params?: any): Promise<ComplainClientResponse> {
  const defaultHeaders = {
    ...Container.get(BaggHttpRequestBuilder).config.headers,
    language: params.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  const url = `${Container.get(BaggHttpRequestBuilder).config.baseUrl}${endpoint.SALESFORCE_ACCOUNTS}?market=${
    params.market
  }`;

  try {
    const { data, status } = await axios.get(url, { headers: defaultHeaders });
    if (status === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    handleUnauthorized(error);
    return null;
  }
}

export function useComplainClients(params?: any, onError?) {
  return useQuery(['getComplainClients'], () => getComplainClients(params), {
    onError,
    select: data => {
      return mapComplainClients(data);
    },
    enabled: params.enabled,
    refetchOnWindowFocus: false,
  });
}
