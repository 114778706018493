import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { PatchEditAttributeParams } from '@app/data/http/quote-attributes-params.dto';

export const editAttributes = async (params?: PatchEditAttributeParams) => {
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    language: params.language,
    Authorization: 'Bearer ' + params.authorizationToken,
    'Access-Token': params.accessToken,
  };

  let dataValue;
  switch (params.key) {
    case 'FINAL_APPLICATION':
      dataValue = { FINAL_APPLICATION: params.value.label };
      break;
    case 'CUSTOMER':
      dataValue = { CUSTOMER: params.value.label };
      break;
    case 'MARKET':
      dataValue = { MARKET: params.value.key };
      break;
    case 'AMOUNT':
      dataValue = { AMOUNT: params.value };
      break;
    case 'SOP':
      dataValue = { SOP: params.value.label };
      break;
    default:
      dataValue = {};
  }

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_ATTRIBUTE
  }`;
  const { data } = await axios.patch(url, dataValue, { headers: defaultHeaders });

  return data;
};

export const useEditAttributeValue = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(editAttributes, {
    onSuccess,
    onError,
    onMutate: async data => {
      await queryClient.cancelQueries(['getTechnicalAnalysis']);
      const previousData = queryClient.getQueriesData(['getTechnicalAnalysis']);
      queryClient.setQueriesData(['getTechnicalAnalysis'], (oldQueryData: any) => {
        oldQueryData.plants.map(plant => {
          const { attributes } = plant;
          const attribute_index = attributes.findIndex(attr => attr.key === data.key);
          attributes[attribute_index].value = data.value;
        });

        return {
          ...oldQueryData,
        };
      });
      return {
        previousData,
      };
    },
  });
};
